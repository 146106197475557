import { ApiResponse, CmsFaqCategory, CmsFaqItem, CmsWebpageRequest } from '@monorepo/types';

import { rootCmsApi } from '../../root/root.api';

const cmsFaqApi = rootCmsApi.injectEndpoints({
	endpoints: (builder) => ({
		getFaqs: builder.query<ApiResponse<CmsFaqItem[]>, CmsWebpageRequest>({
			query({ website = 'maia.insure', path, type }) {
				return {
					url: `/knowledgebase`,
					method: 'GET',
					params: {
						...(website && { website }),
						...(path && { path }),
						...(type && { type }),
					},
				};
			},
			providesTags: (result, _, { path }) => {
				if (result) {
					return [{ type: 'cms_faqs', id: path }];
				}

				/* istanbul ignore next */
				return ['cms_faqs'];
			},
		}),
		getFaqCategories: builder.query<ApiResponse<CmsFaqCategory[]>, CmsWebpageRequest>({
			query({ website = 'maia.insure', path }) {
				return {
					url: `/knowledgebase/categories`,
					method: 'GET',
					params: {
						...(website && { website }),
						...(path && { path }),
					},
				};
			},
			providesTags: (result, _, { path }) => {
				if (result) {
					return [{ type: 'cms_faq_categories', id: path }];
				}

				/* istanbul ignore next */
				return ['cms_faq_categories'];
			},
		}),
	}),
	overrideExisting: true,
});

export const { useGetFaqsQuery, useGetFaqCategoriesQuery } = cmsFaqApi;

export const { getFaqs, getFaqCategories } = cmsFaqApi.endpoints;
