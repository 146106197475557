import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '@monorepo/types';

import { AppState, hydrate, kvkApi } from '@common/store';

export type CompanyState = {
	company: Company;
};

const initialState: CompanyState = {
	company: {} as Company,
};

export const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		setCompany(state, action: PayloadAction<Company>) {
			return {
				...state,
				company: action.payload,
			};
		},
		updateCompany(state, action: PayloadAction<Partial<Company>>) {
			state.company = { ...state.company, ...action.payload };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ company: CompanyState }>) => {
			return {
				...state,
				company: {
					...state.company,
					...action.payload.company,
				},
			};
		});
		builder.addMatcher(kvkApi.endpoints.profile.matchFulfilled, (state, action) => {
			return {
				...state,
				company: {
					...state.company,
					...action.payload,
				},
			};
		});
	},
});

export const { setCompany, updateCompany } = companySlice.actions;

export const selectCompanyState = (state: AppState) => state.company.company;
