import { zodResolver } from '@hookform/resolvers/zod';
import { motion, Variants } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useEffect, type FC } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { StatementCard } from '@monorepo/components/DataDisplay';
import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { ButtonGroup } from '@monorepo/components/Misc';
import { AcceptanceStatementSchema, acceptanceStatementSchema } from '@monorepo/shared/lib/schemas';
import { Question } from '@monorepo/types';

import { selectNavigationState } from '@common/store';

type AcceptanceStatementFormProps = {
	id: string;
	questions: Array<Question>;
	isLoading?: boolean;
	onSubmit: ({ questions }: AcceptanceStatementSchema) => void;
};

const list: Variants = {
	visible: {
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.14,
		},
	},
	hidden: {},
};

const item: Variants = {
	visible: { opacity: 1, x: 0 },
	hidden: { opacity: 0, x: -25 },
};

export const AcceptanceStatementForm: FC<AcceptanceStatementFormProps> = ({ id, questions, isLoading, onSubmit }) => {
	const { t } = useTranslation(['common']);
	const { isNavigating, navDirection } = useSelector(selectNavigationState);
	const { handleSubmit, control, setValue } = useForm<AcceptanceStatementSchema>({
		mode: 'onBlur',
		resolver: zodResolver(acceptanceStatementSchema(t)),
	});

	const { fields, update } = useFieldArray<AcceptanceStatementSchema>({
		name: 'questions',
		control,
	});

	useEffect(() => {
		setValue(
			'questions',
			[...questions].map((question) => ({
				guid: question.guid,
				checked: question.answer.value === 'J' ? 'J' : 'N',
			})),
			{ shouldValidate: true }
		);
	}, [questions, setValue]);

	return (
		<Form id={`${id}-form`} onSubmit={handleSubmit(onSubmit)}>
			{fields.length > 0 && (
				<motion.div className='space-y-24' initial='hidden' animate='visible' variants={list}>
					{fields.map((field, key) => {
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						const question = questions.find((question) => question.guid === field.guid);

						if (!question) return null;

						return (
							<motion.div key={`statement-card-${key}`} variants={item}>
								<StatementCard
									label={question.phrasing}
									tooltips={question.replacements.map((replacement) => ({
										string: replacement.replace_this,
										tooltip: replacement.replace_with,
									}))}
									isToggled={field.checked === 'J'}
									onToggleChange={() => update(key, { guid: field.guid, checked: field.checked === 'J' ? 'N' : 'J' })}
									icon={question.icon}
								/>
							</motion.div>
						);
					})}
					<motion.div variants={item}>
						<ButtonGroup>
							<StandardButton
								type='submit'
								variant='cta'
								size='lg'
								label={t('common.proceed')}
								iconRight='angle-right'
								disabled={isLoading || isNavigating}
								isLoading={isLoading || (isNavigating && navDirection === 'forward')}
								data-testid='confirm'
							/>
						</ButtonGroup>
					</motion.div>
				</motion.div>
			)}
		</Form>
	);
};
