import { z } from 'zod';

import { TranslationType } from '@monorepo/types'; // Adjust the import path as needed

export const updateEmailSchema = (t: TranslationType) =>
	z.object({
		email: z.string().min(1, t('validation.required')).email(t('validation.email')),
	});

export const updateInvoiceEmailSchema = (t: TranslationType) =>
	z.object({
		invoice: z.string().min(1, t('validation.required')).email(t('validation.email')),
	});

export const updateEmailAndInvoiceEmailSchema = (t: TranslationType) =>
	updateEmailSchema(t).merge(updateInvoiceEmailSchema(t));

export type UpdateEmailSchema = z.infer<ReturnType<typeof updateEmailSchema>>;
export type UpdateInvoiceSchema = z.infer<ReturnType<typeof updateInvoiceEmailSchema>>;
export type UpdateInvoiceEmailAndEmailSchema = z.infer<ReturnType<typeof updateEmailAndInvoiceEmailSchema>>;
