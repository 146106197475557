import { isRejectedWithValue, type Middleware } from '@reduxjs/toolkit';
import { i18n } from 'next-i18next';
import { toast } from 'react-hot-toast';

import { ToasterNotification } from '@monorepo/components/DataDisplay';

const apiIgnoreList = ['kvkApi', 'postalCodeApi', 'trustpilotApi'];
const apiEndpointIgnoreList = ['addObjectToCart', 'getConsumerContact', 'saveStakeholderForObject'];

/**
 * Log a warning and show a toast!
 */
export const toastMiddleware: Middleware = () => (next) => async (action) => {
	if (isRejectedWithValue(action)) {
		// Check if the product question put/get and project sctucture is not manually aborted by a signal
		if (action?.meta?.baseQueryMeta?.request?.signal?.aborted) {
			return;
		}

		const apiName = action.type.split('/')[0];

		// Do not toast when the apiName exists in the ignoreList
		if (apiName && apiIgnoreList.includes(apiName)) {
			return next(action);
		}

		const endpointName = action?.meta?.arg?.endpointName;

		// Do not toast when the endpoint exists in the ignoreList
		if (apiName && apiEndpointIgnoreList.includes(endpointName)) {
			return next(action);
		}

		const code = action.payload.data?.code || action.payload?.status || 500;

		// Can't use the useTranslation hook here so use the i18n object instead
		await i18n?.loadNamespaces('common');

		const tKey = `apiErrorMessages.${code}`;
		const tKeyError = 'apiErrorMessages.error';

		// Grab the translation string that corresponds with the received code, if it doesn't exist get a generic string
		let translatedTitle = i18n?.t(tKeyError),
			translatedMessage = i18n?.exists(tKey) ? i18n?.t(tKey) : i18n?.t(tKeyError);

		// TODO: Handle better, probably with backend codes
		if (action?.payload?.data?.existing_customer) {
			translatedTitle = i18n?.t('apiErrorMessages.existingCustomer.title');
			translatedMessage = i18n?.t('apiErrorMessages.existingCustomer.text');
		}

		toast.custom(
			(t) => (
				<ToasterNotification
					title={translatedTitle as string}
					visible={t.visible}
					message={translatedMessage as string}
					status='error'
					onClose={() => toast.remove(t.id)}
				/>
			),
			{
				id: `toast-api-response-error-${code}`,
				ariaProps: {
					role: 'alert',
					'aria-live': 'assertive',
				},
			}
		);
	}

	return next(action);
};
