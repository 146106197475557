import { ApiResponse, CmsCompositionRequest, CmsContainer, CmsInsuredActivityRequest } from '@monorepo/types';

import { rootCmsApi } from '../../root/root.api';

const cmsCompositionsApi = rootCmsApi.injectEndpoints({
	endpoints: ({ query }) => ({
		compositionContainer: query<ApiResponse<CmsContainer>, CmsCompositionRequest>({
			query({ compositionGuid, container }) {
				return {
					url: `/compositions/${compositionGuid}/containers/${container}`,
					method: 'GET',
				};
			},
			providesTags: (result, error, { compositionGuid }) => {
				if (result) {
					return [{ type: 'container', id: compositionGuid }];
				}

				/* istanbul ignore next */
				return ['container'];
			},
		}),
		addonContainer: query<
			ApiResponse<CmsContainer>,
			{
				addonGuid: string;
				container: string;
			}
		>({
			query({ addonGuid, container }) {
				return {
					url: `/addons/${addonGuid}/containers/${container}`,
					method: 'GET',
				};
			},
			providesTags: (result, error, { addonGuid }) => {
				if (result) {
					return [{ type: 'container', id: addonGuid }];
				}

				/* istanbul ignore next */
				return ['container'];
			},
		}),
		insuredActivityContainer: query<CmsContainer, CmsInsuredActivityRequest>({
			query({ compositionGuid, container, insuredActivityGuid }) {
				return {
					url: `/compositions/${compositionGuid}/insured-activities/${insuredActivityGuid}/containers/${container}`,
					method: 'GET',
				};
			},
			transformResponse(response: ApiResponse<Array<CmsContainer>>) {
				return {
					...response.response[0],
				};
			},
			providesTags: (result, error, { compositionGuid }) => {
				if (result) {
					return [{ type: 'container', id: compositionGuid }];
				}

				/* istanbul ignore next */
				return ['container'];
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useCompositionContainerQuery,
	useLazyCompositionContainerQuery,
	useLazyAddonContainerQuery,
	useLazyInsuredActivityContainerQuery,
} = cmsCompositionsApi;
