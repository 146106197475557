import { useMemo } from 'react';

import { useGetAcceptanceQuestionsQuery } from '@funnel/store';

export const useAcceptanceQuestions = (cartGuid: string, reference: string) => {
	const { data, isSuccess } = useGetAcceptanceQuestionsQuery({
		cart_guid: cartGuid,
	});

	const questions = useMemo(
		() =>
			data?.response
				? data.response.questions?.filter(
						(question) => question.reference === reference || question.reference === `${reference}-explanation`
					)
				: [],
		[data?.response, reference]
	);

	const closingStatements = useMemo(
		() => questions.filter((question) => question.reference === 'closing-statement'),
		[questions]
	);

	return {
		questions,
		closingStatements,
		isSuccess,
	};
};
