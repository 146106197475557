import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ToasterNotification } from '@monorepo/components/DataDisplay';

import { NavigationContext } from '@common/contexts';
import {
	selectPlatformState,
	setNavBack,
	setNavForward,
	setNavigating,
	setNavigationDirection,
	setPlatformState,
} from '@common/store';
import { paths, ROOTS } from '@common/utils';
import {
	selectCartContactType,
	selectCartIsExpired,
	selectIsCartConverted,
	selectIsExistingUser,
	selectIsHighRoller,
	useLazyGetCartQuery,
} from '@funnel/store';

let globalIsBackAction = false;
const protectedStartUrls = ['/portaal', '/superadmin'];
const privateFunnelPaths = ['/particulier'];

export const NavigationProvider: FC<PropsWithChildren> = ({ children }) => {
	const { t } = useTranslation();
	const { events, pathname: currentPathname, asPath, back, query, push } = useRouter();
	const cartGuid = query?.cartGuid as string;

	const isB2CFunnel = useMemo(() => privateFunnelPaths.some((path) => asPath?.startsWith(path)), [asPath]);

	const [getCartQuery] = useLazyGetCartQuery();

	const dispatch = useDispatch();
	const { isPortal } = useSelector(selectPlatformState);
	const isCartConverted = useSelector(selectIsCartConverted);
	const isHighRoller = useSelector(selectIsHighRoller);
	const isCartExpired = useSelector(selectCartIsExpired);
	const isExistingCustomer = useSelector(selectIsExistingUser);
	const cartContactType = useSelector(selectCartContactType);

	const goBack = () => {
		globalIsBackAction = true;
		back();
	};

	const handleConvertedCart = async () => {
		let pathname: string = '';

		// This is ugly, i know. Time pressure is a bitch
		const isApproval = currentPathname.includes(ROOTS.APPROVAL_AFTER_REVIEW);

		if (isPortal) {
			pathname = paths.portal.insurances.new.conclusion.root;
		} else if (isB2CFunnel) {
			pathname = isApproval
				? paths.consumerFunnel.conclusion.approved_after_review
				: paths.consumerFunnel.conclusion.root;
		} else {
			pathname = isApproval ? paths.funnel.conclusion.approved_after_review : paths.funnel.conclusion.root;
		}

		// Do nothing if we're already on the correct page
		if (currentPathname === pathname) {
			return;
		}

		const query = { cartGuid };

		await push({
			pathname,
			query,
		});

		toast.custom(
			({ visible }) => (
				<ToasterNotification
					title={t('toasts.convertedCart.title')}
					visible={visible}
					message={t('toasts.convertedCart.message')}
					status='success'
				/>
			),
			{
				ariaProps: {
					role: 'alert',
					'aria-live': 'assertive',
				},
				id: 'converted-cart-toast',
			}
		);
	};

	const handleHighRoller = async () => {
		let pathname: string = '';
		if (isPortal) {
			pathname = paths.portal.insurances.new.finalize.request_contact;
		} else if (isB2CFunnel) {
			pathname = paths.consumerFunnel.finalize.request_contact;
		} else {
			pathname = paths.funnel.finalize.request_contact;
		}
		// Do nothing if we're already on the correct page
		if (currentPathname === pathname) {
			return;
		}
		const query = { cartGuid };
		await push({
			pathname,
			query,
		});
	};

	useEffect(() => {
		if (isCartConverted) {
			handleConvertedCart();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCartConverted]);

	useEffect(() => {
		if (isHighRoller) {
			handleHighRoller();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isHighRoller]);

	useEffect(() => {
		if (isCartExpired) {
			push({
				pathname: paths.fourofour,
				query: { expiredCart: true },
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCartExpired]);

	useEffect(() => {
		if (
			isExistingCustomer &&
			isPortal === false &&
			(currentPathname.includes(ROOTS.FUNNEL_PRECONDITIONS) ||
				currentPathname.includes(ROOTS.FUNNEL_PROFILE) ||
				currentPathname.includes(ROOTS.FUNNEL_COMPOSE))
		) {
			let pathname: string = '';
			if (cartContactType === 'b2c') {
				pathname = paths.consumerFunnel.finalize.email_in_use;
			}
			if (cartContactType === 'b2b') {
				pathname = paths.funnel.finalize.email_in_use;
			}

			// Do nothing if we're already on the correct page
			if (currentPathname === pathname) {
				return;
			}

			if (pathname) {
				const query = { cartGuid };
				push({
					pathname,
					query,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isExistingCustomer, cartContactType, isPortal]);

	useEffect(() => {
		if (cartGuid && isPortal === false) {
			getCartQuery({ cart_guid: cartGuid });
		}
	}, [cartGuid, getCartQuery, isPortal]);

	useEffect(() => {
		/**
		 * Sets global platform state (whether the user is on a protected page or not)
		 * This is used to define if we have to use public or protected API endpoints
		 */
		if (asPath) {
			const isProtected = protectedStartUrls.some((url) => asPath.startsWith(url));
			dispatch(setPlatformState(isProtected));
		}

		const handleStart = () => {
			const direction = globalIsBackAction ? 'back' : 'forward';

			if (direction === 'back') {
				dispatch(setNavBack());
			} else {
				dispatch(setNavForward());
			}
		};

		const handleComplete = () => {
			dispatch(setNavigating(false));
			dispatch(setNavigationDirection(null));
			globalIsBackAction = false;
		};

		events.on('routeChangeStart', handleStart);
		events.on('routeChangeComplete', handleComplete);
		events.on('routeChangeError', handleComplete);

		return () => {
			events.off('routeChangeStart', handleStart);
			events.off('routeChangeComplete', handleComplete);
			events.off('routeChangeError', handleComplete);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [events, dispatch, asPath]);

	const isChildRoute = useMemo(() => Object.keys(query).length > 0, [query]);

	return (
		<NavigationContext.Provider value={{ goBack, isChildRoute, isB2CFunnel, isB2BFunnel: !isB2CFunnel }}>
			{children}
		</NavigationContext.Provider>
	);
};
