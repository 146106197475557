import { sortAlphabetically } from '@monorepo/shared/lib/utils';
import { Bundle, BundleMappedToProducts, Calculation, CartProduct, Price } from '@monorepo/types';

export const mapBundlesToContent = (bundles: Bundle[], calculations: Calculation[]): BundleMappedToProducts[] => {
	return bundles
		?.map((bundle) => ({
			...bundle,
			mappedProducts: calculations
				? calculations
						.filter(({ product }) => bundle.products.some(({ code }) => code === product.code))
						.map((calculation) => calculation?.product)
						.sort((a, b) => sortAlphabetically(a.name, b.name))
				: [],
		}))
		.filter((bundle) => bundle.products.length > 0);
};

export const getTotalProductPrice = (product: CartProduct): Price => {
	const activeAddons = product.add_ons.filter((addon) => addon.answer.value === 'J');
	const totalAddonPrices = activeAddons.reduce(
		(totals, addon) => ({
			premium_before_discount: totals.premium_before_discount + (addon.price?.premium_before_discount ?? 0),
			discount_total: totals.discount_total + (addon.price?.discount_total ?? 0),
			premium_after_discount: totals.premium_after_discount + (addon.price?.premium_after_discount ?? 0),
			tax: totals.tax + (addon.price?.tax ?? 0),
			premium_after_tax: totals.premium_after_tax + (addon.price?.premium_after_tax ?? 0),
			provision: totals.provision + (addon.price?.provision ?? 0),
		}),
		{
			premium_before_discount: 0,
			discount_total: 0,
			premium_after_discount: 0,
			tax: 0,
			premium_after_tax: 0,
			provision: 0,
		}
	);

	const totalPrice = {
		premium_before_discount: totalAddonPrices.premium_before_discount + (product?.price?.premium_before_discount ?? 0),
		discount_total: totalAddonPrices.discount_total + (product?.price?.discount_total ?? 0),
		premium_after_discount: totalAddonPrices.premium_after_discount + (product?.price?.premium_after_discount ?? 0),
		tax: totalAddonPrices.tax + (product?.price?.tax ?? 0),
		premium_after_tax: totalAddonPrices.premium_after_tax + (product?.price?.premium_after_tax ?? 0),
		provision: totalAddonPrices.provision + (product?.price?.provision ?? 0),
	};

	return {
		promotion: product.price?.promotion ?? false,
		...totalPrice,
	};
};

export const getTotalProductsPrice = (product: Array<CartProduct>): Omit<Price, 'promotion'> => {
	const totalPrice = product.reduce(
		(totals, product) => {
			const totalProductPrice = getTotalProductPrice(product);

			return {
				premium_before_discount: totals.premium_before_discount + totalProductPrice.premium_before_discount,
				discount_total: totals.discount_total + totalProductPrice.discount_total,
				premium_after_discount: totals.premium_after_discount + totalProductPrice.premium_after_discount,
				tax: totals.tax + totalProductPrice.tax,
				premium_after_tax: totals.premium_after_tax + totalProductPrice.premium_after_tax,
				provision: totals.provision + totalProductPrice.provision,
			};
		},
		{
			premium_before_discount: 0,
			discount_total: 0,
			premium_after_discount: 0,
			tax: 0,
			premium_after_tax: 0,
			provision: 0,
		}
	);

	return totalPrice;
};
