import { AcceptanceQuestion, QuestionGroup, QuestionPosition } from '@monorepo/types';

import { sortAscending } from '../sort/sort';

type AnswerObject = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getFirstNonNullAnswer(input: AnswerObject): any {
	// Filter keys starting with "_answer"
	const answerKeys = Object.keys(input).filter((key) => key.startsWith('answer_'));

	// Find the first non-null value
	for (const key of answerKeys) {
		if (input[key] !== null) {
			if (key === 'answer_checkbox') {
				if (input[key] !== 0) {
					return input[key];
				}
			} else {
				return input[key];
			}
		}
	}

	// Return null if all "_answer" fields are null
	return null;
}

export function transformOldAcceptanceQuestionsToNewQuestion(
	oldAcceptanceQuestions: Array<AcceptanceQuestion>
): Array<QuestionGroup> {
	const groups: Map<string, Array<AcceptanceQuestion>> = new Map<string, Array<AcceptanceQuestion>>();

	oldAcceptanceQuestions.forEach((question) => {
		const reference = question.reference.replace(/-explanation$/, '');

		if (groups.has(reference)) {
			const storedQuestions = groups.get(reference);

			groups.set(reference, Array.isArray(storedQuestions) ? [...storedQuestions, question] : [question]);
		} else {
			groups.set(reference, [question]);
		}
	});

	const questionGroups: Array<QuestionGroup> = [];

	groups.forEach((groupedAcceptanceQuestions) => {
		if (!groupedAcceptanceQuestions[0]) {
			return;
		}

		const lowestSequence = groupedAcceptanceQuestions.sort((a, b) => sortAscending(a.sequence, b.sequence))[0].sequence;
		const questionGroup: QuestionGroup = {
			position:
				typeof groupedAcceptanceQuestions[0].group === 'string'
					? (groupedAcceptanceQuestions[0].group as QuestionPosition)
					: 'acceptance',
			reference: groupedAcceptanceQuestions[0].reference,
			title: '',
			subtitle: '',
			replacements: [],
			sequence: lowestSequence,
			questions: groupedAcceptanceQuestions
				.sort((a, b) => sortAscending(a.sequence, b.sequence))
				.map((question) => {
					const value = getFirstNonNullAnswer(question);

					return {
						guid: question.guid,
						reference: question.reference,
						title: '',
						phrasing: question.question,
						icon: question.icon_name,
						preconditional: false,
						type: '',
						answer: {
							input_type: -1,
							readable: value,
							value: value,
						},
						options: question.options.map((option) => ({ label: option.value, value: option.key })),
						replacements: (question?.replacements ?? question?.tooltips ?? []).map((tooltip) => ({
							replace_this: tooltip.string,
							replace_with: tooltip.tooltip,
						})),
					};
				}),
		};

		questionGroups.push(questionGroup);
	});

	return questionGroups.sort((a, b) => sortAscending(a.sequence, b.sequence));
}
