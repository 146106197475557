import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CmsContainers, CmsContentTypes } from '@monorepo/types';

import { AppState, hydrate } from '@common/store';

type ModalDynamicPayload = {
	guid: string;
	parentGuid?: string;
	compositionGuid?: string;
	addonGuid?: string;
	container?: CmsContainers;
	type: CmsContentTypes;
	cartGuid?: string;
	objectGuid?: string;
};

export type ModalState = {
	modals: {
		[key: string]: {
			state: boolean;
			payload?: ModalDynamicPayload;
		};
	};
};

const initialState: ModalState = {
	modals: {},
};

export const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		showModal(state, action: PayloadAction<string>) {
			state.modals[action.payload] = { state: true };
		},
		hideModal(state, action: PayloadAction<string>) {
			state.modals[action.payload] = { state: false };
		},
		showDynamicModal(state, action: PayloadAction<{ id: string; payload: ModalDynamicPayload }>) {
			state.modals[action.payload.id] = { state: true, payload: action.payload.payload };
		},
		resetModals() {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ modal: ModalState }>) => ({
			...state,
			...action.payload.modal,
		}));
	},
});

export const { showModal, hideModal, showDynamicModal, resetModals } = modalSlice.actions;

export const selectModalState = (state: AppState) => state.modal.modals;

export const selectModalIdState = (modalId: string) =>
	createSelector(
		(state: AppState) => state.modal,
		({ modals }) => modals[modalId]
	);
