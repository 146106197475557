import cn from 'classnames';
import { FC, HTMLProps, PropsWithChildren } from 'react';

import { ThemeTypographyWeight } from '@monorepo/types';

import { IconButton } from '../../../Misc/Forms/IconButton/IconButton';
import { Text } from '../../../TextComponents';

type FormLabelProps = {
	isSmallMargin?: boolean;
	weight?: ThemeTypographyWeight;
	isOptional?: boolean;
	onMoreInformation?: () => void;
} & PropsWithChildren<Omit<HTMLProps<HTMLLabelElement>, 'as' | 'color' | 'ref'>>;

export const FormLabel: FC<FormLabelProps> = ({
	children,
	className,
	onMoreInformation,
	isOptional = false,
	isSmallMargin = false,
	weight = 'regular',
	...props
}) => {
	return (
		<Text
			as='label'
			variant='body-m'
			color='grayscale500'
			weight={weight}
			className={cn(
				'block w-full',
				isSmallMargin ? 'mb-8' : 'mb-16',
				(isOptional || onMoreInformation) && 'flex items-center',
				className
			)}
			{...props}>
			<span className='flex-1'>{children}</span>

			{(isOptional || onMoreInformation) && (
				<div className='flex flex-row items-center'>
					{isOptional && (
						<Text as='span' variant='body-s' weight='regular' color='grayscale300'>
							{'(optioneel)'}
						</Text>
					)}

					{onMoreInformation && (
						<IconButton iconName='info-circle' size='md' color='info300' onClick={onMoreInformation} />
					)}
				</div>
			)}
		</Text>
	);
};
