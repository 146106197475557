export * from './Auth/SvgFunnelLogin';
export * from './Auth/SvgLogin';

export * from './Claims/SvgAddClaim';
export * from './Claims/SvgNewClaimSuccess';
export * from './Claims/SvgOpenClaimChat';
export * from './ChattingWithSupport/ChattingWithSupport';

export * from './Funnel/SvgConclusion';
export * from './Funnel/SvgMotorCyclist';
export * from './Funnel/SvgPrecondition';
export * from './Funnel/SvgSplash';
export * from './Funnel/CamperSplash';

export * from './Onboarding/SvgClaims';
export * from './Onboarding/SvgInsurances';
export * from './Onboarding/SvgInvoices';

export * from './Rocket/SvgRocket';
export * from './SittingOnChairWithLaptop/SittingOnChairWithLaptop';

export * from './TrustpilotStar/TrustpilotStar';

export * from './WomenGivingThumbsUp/WomenGivingThumbsUp';

export * from './WomenLyingOnCouchWithPhone/WomenLyingOnCouchWithPhone';
export * from './TeacherExplaining/TeacherExplaining';

export * from './WomenTinkeringWithRocket/WomenTinkeringWithRocket';
