import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ChoiceCard } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { PropertOwnershipSchema } from '@monorepo/shared/lib/schemas';
import { slugify } from '@monorepo/shared/lib/utils';
import { PropertyOwnershipOptions, QuestionOption } from '@monorepo/types';

type PropertyOwnershipFormProps = {
	options: Array<QuestionOption>;
	isLoading?: boolean;
	onSubmit: ({ ownership }: PropertOwnershipSchema) => void;
};

export const PropertyOwnershipForm: FC<PropertyOwnershipFormProps> = ({ options, isLoading, onSubmit }) => {
	const { watch, setValue } = useFormContext<PropertOwnershipSchema>();
	const chosenOwnership = watch('ownership');

	const submit = (key: PropertyOwnershipOptions) => {
		setValue('ownership', key, { shouldValidate: true });
		onSubmit({ ownership: key });
	};

	return (
		<Form.Context<PropertOwnershipSchema>>
			{({}) => {
				return (
					<Form id='property-ownership-form'>
						<Form.Group className='space-y-24'>
							{options.map(({ value, label }) => {
								const isPicked = value === chosenOwnership;

								return (
									<ChoiceCard
										key={`choice-${slugify(value)}`}
										title={label}
										isLoading={isPicked && isLoading}
										disabled={isLoading}
										onClick={() => submit(value as PropertyOwnershipOptions)}
										checked={isPicked}
										explicitSuccess={isPicked}
										text={label}
										data-testid={`choice-form-${value}`}
									/>
								);
							})}
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};
