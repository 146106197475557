import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'ts-pattern';

import { Address, CartProduct, CmsContainer, FunnelOrigin } from '@monorepo/types';

import { useLazyCompositionContainerQuery, useLazyProductContainerQuery } from '@cms/store';
import { showDynamicModal } from '@common/store';
import {
	updateIsProductLoading,
	useGetCartProductsQuery,
	useGetCartQuery,
	usePutProductQuestionMutation,
	usePutQuestionsMutation,
	useToggleProductMutation,
} from '@funnel/store';
import {
	useGetMutationProductsQuery,
	useGetMutationQuery,
	useGetUpsellCartProductsQuery,
	useGetUpsellCartQuery,
	usePutMutationProductQuestionMutation,
	usePutMutationQuestionsMutation,
	usePutUpsellProductQuestionMutation,
	usePutUpsellQuestionsMutation,
	useToggleUpsellProductMutation,
} from '@portal/store';

interface UseProductProps {
	cartGuid?: string;
	useCompositionInfo?: boolean;
	origin?: FunnelOrigin;
}

export function useProduct({ cartGuid, useCompositionInfo = false, origin = 'funnel' }: UseProductProps) {
	const [productWithCmsData, setProductWithCmsData] = useState<Array<CartProduct & { content: Array<CmsContainer> }>>(
		[]
	);
	const dispatch = useDispatch();
	const [fetchCompositionInfo] = useLazyCompositionContainerQuery();
	const [fetchProductInfo] = useLazyProductContainerQuery();

	const toggleProductMutation = match(origin)
		.with('upsell', () => useToggleUpsellProductMutation)
		.otherwise(() => useToggleProductMutation);
	const [toggleProduct, { isLoading: toggleIsLoading }] = toggleProductMutation();

	const toggleAddonMutation = match(origin)
		.with('upsell', () => usePutUpsellQuestionsMutation)
		.with('edit-insurance', () => usePutMutationQuestionsMutation)
		.otherwise(() => usePutQuestionsMutation);
	const [toggleAddon, { isLoading: addonToggleIsLoading }] = toggleAddonMutation();

	const answerProductQuestionMutation = match(origin)
		.with('upsell', () => usePutUpsellProductQuestionMutation)
		.with('edit-insurance', () => usePutMutationProductQuestionMutation)
		.otherwise(() => usePutProductQuestionMutation);
	const [answerProductQuestion, { isLoading: productQuestionIsLoading }] = answerProductQuestionMutation();

	const getCartQuery = match(origin)
		.with('upsell', () => useGetUpsellCartQuery)
		.with('edit-insurance', () => useGetMutationQuery)
		.otherwise(() => useGetCartQuery);
	const {
		data: cart,
		isSuccess: cartIsSuccess,
		isLoading: cartIsLoading,
	} = getCartQuery(
		{
			cart_guid: cartGuid as string,
		},
		{
			skip: typeof cartGuid !== 'string',
		}
	);

	const getProductsQuery = match(origin)
		.with('upsell', () => useGetUpsellCartProductsQuery)
		.with('edit-insurance', () => useGetMutationProductsQuery)
		.otherwise(() => useGetCartProductsQuery);

	const {
		data: calculations,
		isSuccess: calculationsIsSuccess,
		isLoading: productIsLoading,
		isFetching: productIsFetching,
		refetch: refetchProducts,
	} = getProductsQuery(
		{
			cart_guid: cartGuid as string,
		},
		{
			skip: typeof cartGuid !== 'string',
		}
	);

	useEffect(() => {
		if (calculationsIsSuccess) {
			const calculationsWithCmsData = calculations.response.map((calculation) => {
				if (useCompositionInfo) {
					return fetchCompositionInfo(
						{
							compositionGuid: calculation.composition,
							container: 'product_intro',
						},
						true // preferCacheValue
					)
						.unwrap()
						.then((content) => ({
							...calculation,
							content: [content.response],
						}));
				} else {
					return fetchProductInfo(
						{
							productGuid: calculation.guid,
							container: 'product_intro',
						},
						true // preferCacheValue
					)
						.unwrap()
						.then((content) => ({
							...calculation,
							content: [content.response],
						}));
				}
			});

			Promise.all(calculationsWithCmsData).then((results) => {
				setProductWithCmsData(results);
			});
		}
	}, [calculations?.response, calculationsIsSuccess, fetchCompositionInfo, fetchProductInfo, useCompositionInfo]);

	async function handleToggleProduct(productGuid: string) {
		dispatch(updateIsProductLoading({ guid: productGuid, isLoading: true }));

		await toggleProduct({ cart_guid: cartGuid ?? '', product_guid: productGuid }).unwrap();
		dispatch(updateIsProductLoading({ guid: productGuid, isLoading: false }));
	}

	async function handleToggleAddon(addonGuid: string, isToggled: boolean) {
		dispatch(updateIsProductLoading({ guid: addonGuid, isLoading: true }));

		await toggleAddon({
			cart_guid: cartGuid ?? '',
			questions: [{ guid: addonGuid, answer: isToggled ? 'N' : 'J' }],
		}).unwrap();

		await refetchProducts();

		dispatch(updateIsProductLoading({ guid: addonGuid, isLoading: false }));
	}

	async function handleAnswerQuestions({
		productGuid,
		questions,
	}: {
		productGuid: string;
		questions: Array<{ guid: string; value: string | number | Address }>;
	}) {
		dispatch(updateIsProductLoading({ guid: productGuid, isLoading: true }));

		return answerProductQuestion({
			cart_guid: cartGuid ?? '',
			product_guid: productGuid,
			questions: questions.map(({ guid, value }) => ({ guid, answer: value })),
		})
			.unwrap()
			.then(async (response) => {
				dispatch(updateIsProductLoading({ guid: productGuid, isLoading: false }));
				return response;
			});
	}

	const handleAddonMoreInfoClick = (guid: string) => {
		dispatch(
			showDynamicModal({
				id: 'dynamic-content-modal',
				payload: {
					guid,
					cartGuid,
					type: 'addon',
					container: 'product_details',
				},
			})
		);
	};

	const handleProductMoreInfoClick = (guid: string) => {
		dispatch(
			showDynamicModal({
				id: 'dynamic-content-modal',
				payload: {
					guid,
					cartGuid,
					type: 'product',
					container: 'product_details',
				},
			})
		);
	};

	return {
		cart: cart?.response,
		products: productWithCmsData,
		isSucces: calculationsIsSuccess && cartIsSuccess,
		isLoading:
			cartIsLoading ||
			productIsLoading ||
			productIsFetching ||
			productQuestionIsLoading ||
			toggleIsLoading ||
			addonToggleIsLoading,
		isLoadingPage: cartIsLoading || productIsLoading,
		actions: {
			handleToggleProduct,
			handleAnswerQuestions,
			refetchProducts,
			handleToggleAddon,
			handleAddonMoreInfoClick,
			handleProductMoreInfoClick,
		},
	};
}
