import * as RadixForm from '@radix-ui/react-form';
import { ComponentProps, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconButton } from '@monorepo/shared/components/misc';
import { Text } from '@monorepo/shared/typography';

type FormProps = {
	children: ReactNode;
} & ComponentProps<typeof RadixForm.Root>;

export function Root(props: FormProps) {
	const { children, ...rest } = props;
	return <RadixForm.Root {...rest}>{children}</RadixForm.Root>;
}

type FormFieldProps = {
	name: string;
	label: string | null;
	description?: string;
	input: ReactNode;
	message?: {
		type: 'hint' | 'error';
		content: string | undefined;
	};
	onMoreInformationClick?: () => void;
} & Omit<ComponentProps<typeof RadixForm.Field>, 'name'>;

export function Field(props: FormFieldProps) {
	const { label, description, input, message, name, onMoreInformationClick, className, ...rest } = props;

	return (
		<RadixForm.Field name={name} {...rest} className={twMerge('flex flex-col gap-y-8', className)}>
			{(label || description || onMoreInformationClick) && (
				<RadixForm.Label className='flex justify-between'>
					{label && (
						<div className='flex flex-col'>
							<Text variant='body-s' weight='medium' color='grayscale600'>
								{label}
							</Text>
							{description && (
								<Text variant='body-s' weight='regular' color='grayscale400'>
									{description}
								</Text>
							)}
						</div>
					)}
					{onMoreInformationClick && (
						<div className='bg-grayscale0 border-info300 text-info300 hover:border-info300 hover:text-info300 s-20 flex h-16 w-16 items-center justify-center rounded-full border-2'>
							<IconButton onClick={onMoreInformationClick} size='sm' color='info300' iconName='info' />
						</div>
					)}
				</RadixForm.Label>
			)}
			<RadixForm.Control asChild>{input}</RadixForm.Control>
			{!!message?.content && (
				<RadixForm.Message asChild>
					<Text variant='body-s' weight='regular' color={message.type === 'error' ? 'error300' : 'grayscale300'}>
						{message.content}
					</Text>
				</RadixForm.Message>
			)}
		</RadixForm.Field>
	);
}

type SubmitProps = {
	label: string;
};

export function Submit(props: SubmitProps) {
	const { label } = props;
	return <RadixForm.Submit>{label}</RadixForm.Submit>;
}
