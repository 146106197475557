import { FC } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import remarkGfm from 'remark-gfm';

import { Text } from '@monorepo/components/TextComponents';

import { HandlerProps } from '@cms/componentMapper/componentMapper';

const Heading1 = ({ node: _, ...props }: ReactMarkdownProps) => (
	<Text as='h1' variant='display-6' weight='medium' color='grayscale600' {...props} />
);
const Heading2 = ({ node: _, ...props }: ReactMarkdownProps) => (
	<Text as='h2' variant='body-l' weight='medium' color='grayscale600' {...props} />
);
const Heading3 = ({ node: _, ...props }: ReactMarkdownProps) => (
	<Text as='h3' variant='body-m' weight='medium' color='grayscale600' {...props} />
);
const Heading4 = ({ node: _, ...props }: ReactMarkdownProps) => (
	<Text as='h4' variant='body-s' weight='medium' color='grayscale600' {...props} />
);

const Em = ({ node: _, ...props }: ReactMarkdownProps) => <span className='font-medium' {...props} />;

const Bold = ({ node: _, ...props }: ReactMarkdownProps) => (
	<strong {...props} className='font-medium' color='inherit' />
);

const Paragraph = ({ node: _, ...props }: ReactMarkdownProps) => (
	<Text as='p' variant='body-m' color='grayscale500' {...props} />
);

const Table = ({ node: _, ...props }: ReactMarkdownProps) => (
	<div className='border-grayscale100 rounded-18 border'>
		<table {...props} className='w-full' />
	</div>
);
const TableHead = ({ node: _, ...props }: ReactMarkdownProps) => (
	<thead {...props} className='text-grayscale600 border-b-grayscale100 border-b' />
);
const TableBody = ({ node: _, ...props }: ReactMarkdownProps) => <tbody {...props} className='' />;
const TableHeader = ({ node: _, ...props }: ReactMarkdownProps) => (
	<th {...props} className='typography-body-m text-grayscale600 px-24 py-16' />
);
const TableData = ({ node: _, ...props }: ReactMarkdownProps) => (
	<td
		{...props}
		className='typography-body-xs text-grayscale300 [&>strong]:text-body-m-mobile md:[&>strong]:text-body-m-mobile [&>strong]:text-grayscale600 px-24 py-16'
	/>
);
const TableRow = ({ node: _, ...props }: ReactMarkdownProps) => (
	<tr {...props} className='border-b-grayscale100 border-b' />
);

const Ul = ({ node: _, ...props }: ReactMarkdownProps) => (
	<ul {...props} className='mt-4 list-outside list-disc pl-24' />
);
const Ol = ({ node: _, ...props }: ReactMarkdownProps) => (
	<ol {...props} className='mt-4 list-outside list-decimal pl-24' />
);
const Li = ({ node: _, ...props }: ReactMarkdownProps) => (
	<Text as='li' variant='body-m' color='grayscale500' {...props} />
);

export const mdComponents: Components = {
	h1: Heading1,
	h2: Heading2,
	h3: Heading3,
	h4: Heading4,
	p: Paragraph,
	b: Bold,
	em: Em,
	strong: Bold,
	table: Table,
	thead: TableHead,
	tbody: TableBody,
	th: TableHeader,
	td: TableData,
	tr: TableRow,
	ul: Ul,
	li: Li,
	ol: Ol,
};

export const handleMarkdown = ({ component }: HandlerProps): FC => {
	return () => (
		<ReactMarkdown remarkPlugins={[remarkGfm]} components={mdComponents}>
			{`${component?.markdown}`}
		</ReactMarkdown>
	);
};
