import classNames from 'classnames';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { useState } from 'react';

import { TextButton } from '@monorepo/components/DataEntry';
import { Icon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';

const ulVariants: Variants = {
	collapsed: {
		height: '98px',
	},
	open: {
		height: 'auto',
		transition: { duration: 0.25 },
	},
};

const overlayVariants: Variants = {
	invisible: {
		height: 0,
	},
	visible: {
		height: '100%',
		transition: { duration: 0.25 },
	},
};

type Props = {
	onClick: () => void;
};

export function CollapsibleChecklist(props: Props) {
	const { onClick } = props;
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

	return (
		<motion.div
			className={classNames(
				'rounded-12 bg-grayscale0 relative mt-12 overflow-hidden p-20 transition-shadow duration-200 ease-out',
				!isCollapsed && 'shadow-1'
			)}>
			<Text variant='body-l' weight='medium' color='grayscale600'>
				Het onderstaande kunnen we helaas niet verzekeren
			</Text>
			<Text variant='body-m' weight='regular' color='grayscale400' className='mt-4'>
				Bekijk het volledige overzicht{' '}
				<button onClick={onClick} className='underline'>
					hier
				</button>
			</Text>
			<motion.ul
				className='relative my-16 space-y-12 overflow-hidden'
				variants={ulVariants}
				initial='collapsed'
				animate={isCollapsed ? 'collapsed' : 'open'}>
				{[
					{
						title: 'Operaties voor esthetische doeleinden of anatomische correcties.',
					},
					{
						title: 'Onmiddellijke kosten door een ongeval binnen 2 dagen na ingangsdatum.',
					},
					{
						title: 'Preventieve zorg, zoals vaccinaties en gezondheidschecks.',
					},
					{
						title: 'Katten ouder dan 10 jaar en honden ouder dan 8 jaar ',
					},
				].map(({ title }, index) => (
					<li key={`medical-costs-coverage-${index}`}>
						<div className='flex flex-row items-center gap-12'>
							<Icon name='times' color='error300' />
							<Text variant='body-m' weight='regular' color='grayscale500'>
								{title}
							</Text>
						</div>
					</li>
				))}
				<AnimatePresence>
					{isCollapsed && (
						<motion.div
							variants={overlayVariants}
							initial='invisible'
							animate='visible'
							exit='invisible'
							className='from-grayscale0 pointer-events-none absolute bottom-0 left-0 right-0 z-0 h-96 w-full bg-gradient-to-t'
						/>
					)}
				</AnimatePresence>
			</motion.ul>

			<div className='z-10 flex justify-center'>
				<TextButton
					label={isCollapsed ? 'Lees meer' : 'Lees minder'}
					iconRight={isCollapsed ? 'angle-down' : 'angle-up'}
					onClick={() => setIsCollapsed((_) => !_)}
				/>
			</div>
		</motion.div>
	);
}
