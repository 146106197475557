import { QuestionGroup } from '@monorepo/types';

import { sortQuestionGroups } from './sortQuestionGroups';

export function findNextQuestionGroup(
	questionGroups: Array<QuestionGroup>,
	currentReference: string
): QuestionGroup | null {
	const sortedQuestionGroups = sortQuestionGroups([...questionGroups]);
	const foundQuestionIndex = sortedQuestionGroups.findIndex(({ reference }) => reference === currentReference);

	if (foundQuestionIndex + 1 < questionGroups.length) {
		return sortedQuestionGroups[foundQuestionIndex + 1];
	}

	// If no question with currentReference is found, return null
	return null;
}
