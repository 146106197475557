import { match } from 'ts-pattern';

import { transformAnswer } from '@monorepo/shared/lib/utils';
import { ApiResponse, CartProduct, NonTransformedAnswer, Price } from '@monorepo/types';

import { adjustPrice } from '@common/utils/transformers/adjustPrice';

export interface GetUpsellCartProductsRequest {
	cart_guid: string;
}

export type GetUpsellCartProductsResponse = Array<CartProduct>;
export type GetRawProductsResponse = Array<CartProduct<NonTransformedAnswer>>;

export function transformProduct(
	response: ApiResponse<GetRawProductsResponse>
): ApiResponse<GetUpsellCartProductsResponse> {
	return {
		...response,
		response: response.response.map((product) => {
			const newProduct: CartProduct = {
				...product,
				add_ons: product.add_ons.map((addon) => ({
					...addon,
					price: adjustPrice<Price>(addon.price),
					answer: transformAnswer(addon.answer),
					children: addon.children.map((child) => {
						if (child.type === 'question') {
							return { ...child, answer: transformAnswer(child.answer) };
						}
						return {
							...child,
							answer: {
								input_type: -1,
								value: null,
							},
						};
					}),
				})),
				object:
					Object.keys(product.object ?? {}).length > 0
						? {
								...product.object,
								specs:
									product?.object?.specs?.flatMap((spec) => ({ ...spec, answer: transformAnswer(spec.answer) })) ?? [],
							}
						: null,
				price: adjustPrice<Price>(product.price),
				questions: product.questions.flatMap((question) =>
					match(question)
						.with({ type: 'question' }, (q) => ({
							...q,
							answer: transformAnswer(q.answer),
						}))
						.with({ type: 'text' }, (q) => ({
							...q,
							answer: {
								input_type: -1,
								value: null,
							},
						}))
						.exhaustive()
				),
			};

			return newProduct;
		}),
	};
}
