import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC, PropsWithChildren, ReactNode, SyntheticEvent } from 'react';

import { BaseAccordion } from '@monorepo/components/DataDisplay/Accordions/BaseAccordion/BaseAccordion';
import { ActionButton, Form } from '@monorepo/components/DataEntry';
import { CheckList } from '@monorepo/components/TextComponents';
import { Text } from '@monorepo/components/TextComponents/TextComponents/Text/Text';
import type { PossibleClassification } from '@monorepo/types';

import { Modal } from '@common/components';

type ActivitySubGroupAccordionProps = {
	classification: PossibleClassification;
	selectedClassificationGuids: string[];
	openActivitySubgroup?: string;
	variant?: 'main' | 'sub';
	handleToggleOpenActivitySubgroup: (guid: string, event: SyntheticEvent) => void;
};

const ActivitySubGroupAccordion: FC<PropsWithChildren<ActivitySubGroupAccordionProps>> = ({
	variant = 'sub',
	selectedClassificationGuids,
	openActivitySubgroup,
	handleToggleOpenActivitySubgroup,
	classification: { guid, icon, name, children },
	children: accordionChildren,
}) => {
	const { t } = useTranslation(['common']);
	const isOpen = guid === openActivitySubgroup;

	let subgroupTitle: string | ReactNode = name;

	const allActiveSubgroupActivities = children.filter((a) =>
		selectedClassificationGuids.some((s) => s === a.guid)
	).length;

	if (allActiveSubgroupActivities > 0) {
		subgroupTitle = (
			<span>
				{name} <span className='text-info300'>{`(${allActiveSubgroupActivities})`}</span>
			</span>
		);
	}

	if (variant === 'main') {
		return (
			<BaseAccordion
				icon={icon}
				title={subgroupTitle}
				key={guid}
				isDefaultOpen={openActivitySubgroup === guid}
				onToggle={(event) => handleToggleOpenActivitySubgroup(guid, event)}>
				{accordionChildren}
			</BaseAccordion>
		);
	}

	return (
		<div
			role='button'
			title={t(isOpen ? 'common.closeAccordion' : 'common.openAccordion', {
				name: name,
			})}
			tabIndex={0}
			onKeyDown={(event) => {
				event.stopPropagation();

				if (event.key === 'Enter' || event.key === ' ') {
					handleToggleOpenActivitySubgroup(guid, event);
				}
			}}
			onClick={(event) => {
				event?.stopPropagation();

				handleToggleOpenActivitySubgroup(guid, event);
			}}
			className={classNames(
				isOpen && 'bg-primary50',
				'hover:bg-primary50 rounded-6 group flex flex-row items-baseline space-x-24 p-12'
			)}>
			<div>
				<ActionButton
					type='button'
					title={t(isOpen ? 'common.closeAccordion' : 'common.openAccordion', { name })}
					className='group-hover:bg-grayscale0'
					isActive={isOpen}
					icon={isOpen ? 'minus' : 'plus'}
				/>
			</div>
			<div className='flex w-full flex-col space-y-12'>
				<Text as='h4' variant='body-s' weight='medium'>
					{subgroupTitle}
				</Text>
				<AnimatePresence mode='wait'>
					{isOpen && (
						<motion.div
							initial={{ opacity: 0, height: 0 }}
							animate={{ opacity: 1, height: 'auto' }}
							exit={{ opacity: 0, height: 0 }}>
							{accordionChildren}
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

type ActivitySubGroupProps = {
	variant?: 'main' | 'sub';
	classification: PossibleClassification;
	selectedClassificationGuids: string[];
	openActivitySubgroup?: string;
	isLoading?: boolean;
	handleOpenActivityModal: (guid: string) => void;
	handleToggleOpenActivitySubgroup: (guid: string, event: SyntheticEvent) => void;
	handleToggleActivity: (guid: string) => void;
};

export const ActivitySubGroup: FC<ActivitySubGroupProps> = ({
	variant = 'sub',
	openActivitySubgroup,
	classification,
	selectedClassificationGuids,
	handleToggleOpenActivitySubgroup,
	handleToggleActivity,
	handleOpenActivityModal,
	isLoading,
}) => {
	return (
		<ActivitySubGroupAccordion
			classification={classification}
			selectedClassificationGuids={selectedClassificationGuids}
			handleToggleOpenActivitySubgroup={handleToggleOpenActivitySubgroup}
			variant={variant}
			openActivitySubgroup={openActivitySubgroup}>
			{classification.children.map(({ guid, content, name }) => {
				return (
					<div className='flex flex-row py-4' key={`activity-subgroup-${guid}`} role='list-item'>
						<Form.Checkbox
							id={guid}
							name='classifications'
							value={guid}
							label={name}
							endIcon={content && content.length > 0 ? 'info-circle' : ''}
							onChange={(event) => {
								event.stopPropagation();
								handleToggleActivity(guid);
							}}
							checked={selectedClassificationGuids?.some((_guid) => _guid === guid)}
							onEndIconClick={() => handleOpenActivityModal(guid)}
							disabled={isLoading}
						/>

						{content && content.length > 0 && (
							<Modal id={guid} title={name}>
								<CheckList items={content} />
							</Modal>
						)}
					</div>
				);
			})}
		</ActivitySubGroupAccordion>
	);
};
