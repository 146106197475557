import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const cancellationSchema = (t: TranslationType) =>
	z.object({
		answer: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
	});

export type CancellationSchema = z.infer<ReturnType<typeof cancellationSchema>>;
