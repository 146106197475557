import { CartCalculationTypes } from '@monorepo/types';

export type CalculationType = 'products' | 'bundles' | 'compositions';

export function getCalculationType(types: CartCalculationTypes): CalculationType {
	if (types.products) return 'products';
	if (types.bundles) return 'bundles';

	return 'compositions';
}
