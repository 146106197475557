import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

import { Icon as IconComponent } from '@monorepo/shared/icon';
import { Text } from '@monorepo/shared/typography';

import { sizeToTextVariantMapper } from './utils';
import { button, ButtonSizeVariants, iconVariants } from './variants';

type ButtonProps =
	| {
			variant: 'action';
			icon: string;
			size?: ButtonSizeVariants;
	  }
	| {
			variant:
				| 'cta'
				| 'primary'
				| 'text'
				| 'critical'
				| 'error'
				| 'info'
				| 'succes'
				| 'warning'
				| 'secondary'
				| 'tertiary';
			label: string;
			leadingIcon?: string;
			trailingIcon?: string;
			size?: ButtonSizeVariants;
	  };

export function Button(props: ButtonProps & ComponentProps<'button'>) {
	const { size, variant, className } = props;

	if (props.variant === 'action') {
		const { icon, ...rest } = props;
		return (
			<button className={twMerge(button({ size, variant }), className)} {...rest}>
				<IconComponent name={icon} className={iconVariants({ size, variant })} color='grayscale600' />
			</button>
		);
	}

	const { label, leadingIcon, trailingIcon } = props;

	return (
		<button className={button({ size, variant })}>
			{leadingIcon && <IconComponent name={leadingIcon} className={iconVariants({ size, variant })} />}
			<Text as='span' variant={sizeToTextVariantMapper(size)} color='inherit' weight='semibold'>
				{label}
			</Text>
			{trailingIcon && <IconComponent name={trailingIcon} className={iconVariants({ size, variant })} />}
		</button>
	);
}
