import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { formatError, logger, paths } from '@common/utils';
import {
	selectClassifications,
	useLazyGetCartQuery,
	useLazyGetQuestionsQuery,
	usePutClassificationsMutation,
} from '@funnel/store';

export const useSubmitActivities = () => {
	const [updateClassifications, updateClassificationsResponse] = usePutClassificationsMutation();
	const [getCart, getCartResponse] = useLazyGetCartQuery();
	const [getQuestionsQuery, getQuestionsResponse] = useLazyGetQuestionsQuery();

	const {
		push,
		query: { cartGuid },
	} = useRouter();

	const activities = useSelector(selectClassifications);

	const submitActivities = async () => {
		try {
			const {
				response: { valid },
			} = await updateClassifications({
				cart_guid: `${cartGuid}`,
				classifications: activities.map((a) => ({ guid: a.guid })),
			}).unwrap();

			await getCart({ cart_guid: typeof cartGuid === 'string' ? cartGuid : '' }).unwrap();

			const { response } = await getQuestionsQuery({
				cart_guid: typeof cartGuid === 'string' ? cartGuid : '',
			}).unwrap();

			if (valid && response.nextReference) {
				await push({
					pathname: paths.funnel.profile.dynamic(response.nextReference),
					query: { cartGuid },
				});
			} else {
				await push({
					pathname: paths.funnel.finalize.request_contact,
					query: { cartGuid },
				});
			}
		} catch (error) {
			logger.error('Could not submit classifications', {
				error: formatError(error),
			});

			await push({
				pathname: paths.funnel.finalize.request_contact,
				query: { cartGuid },
			});
		}
	};

	return {
		submitActivities,
		isLoading: updateClassificationsResponse.isLoading || getCartResponse.isLoading || getQuestionsResponse.isLoading,
	};
};
