import classNames from 'classnames';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, Fragment, PropsWithChildren, ReactNode, useId } from 'react';

import { colors, ThemeColors } from '@monorepo/types';

import { ReplacingWords, Text } from '../../../TextComponents';
import { CircularSkeleton, CircularSkeletonProps } from '../CircularSkeleton/CircularSkeleton';
import { RectangularSkeleton, RectangularSkeletonProps } from '../RectangularSkeleton/RectangularSkeleton';
import { TextSkeleton, TextSkeletonProps } from '../TextSkeleton/TextSkeleton';

const colorMap = {} as Record<ThemeColors, string>;

colors.forEach((color) => {
	colorMap[color] = `bg-${color}`;
});

export type SkeletonProps = (
	| ({
			variant: 'text';
	  } & TextSkeletonProps)
	| {
			variant: 'custom';
			skeleton: ReactNode;
			waitTexts?: string[];
	  }
	| ({
			variant: 'circular';
	  } & CircularSkeletonProps)
	| ({
			variant: 'rectangular';
	  } & Omit<RectangularSkeletonProps, 'isRounded'>)
	| ({
			variant: 'rounded';
	  } & Omit<RectangularSkeletonProps, 'isRounded'>)
) & {
	range?: number;
	isLoading?: boolean;
	transformAnimation?: boolean;
	color?: ThemeColors;
	className?: string;
	skipAnimation?: boolean;
	containerClassName?: string;
};

export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
	range = 1,
	isLoading = true,
	transformAnimation,
	color,
	className,
	skipAnimation,
	containerClassName,
	children,
	...props
}) => {
	const id = useId();

	return (
		<AnimatePresence mode='wait'>
			{isLoading && (
				<motion.div
					key='skeleton-group'
					initial={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: transformAnimation ? 5 : 0 }}
					transition={{ duration: 0.3 }}
					className={containerClassName}>
					{isLoading && props.variant === 'custom' && props.waitTexts && (
						<Text
							key={`skeleton-waitTexts-${id}`}
							variant='body-m'
							color='grayscale400'
							className='col-span-full mb-16 text-center'>
							<ReplacingWords transitionTime={2000} words={props.waitTexts} />
						</Text>
					)}
					{Array(range)
						.fill('')
						.map((_, key) => {
							if (props.variant === 'text') {
								return (
									<TextSkeleton
										key={`skeleton-text-${id}-${key}`}
										className={classNames(className, color ? colorMap[color] : 'bg-grayscale100')}
										{...props}
									/>
								);
							}

							if (props.variant === 'circular') {
								return (
									<CircularSkeleton
										key={`skeleton-circular-${id}-${key}`}
										className={classNames(className, color ? colorMap[color] : 'bg-grayscale100')}
										{...props}
									/>
								);
							}

							if (props.variant === 'rectangular') {
								return (
									<RectangularSkeleton
										key={`skeleton-rectangular-${id}-${key}`}
										className={classNames(className, color ? colorMap[color] : 'bg-grayscale100')}
										{...props}
									/>
								);
							}

							if (props.variant === 'custom') {
								return <Fragment key={`skeleton-custom-${id}-${key}`}>{props.skeleton}</Fragment>;
							}

							return (
								<RectangularSkeleton
									key={`skeleton-rectangular-rounded-${id}-${key}`}
									className={classNames(className, color ? colorMap[color] : 'bg-grayscale100')}
									isRounded
									{...props}
								/>
							);
						})}
				</motion.div>
			)}
			<motion.div
				key='skeleton-content'
				initial={skipAnimation ? { opacity: 1, y: 0 } : { opacity: 0, y: transformAnimation ? 5 : 0 }}
				animate={{ opacity: isLoading ? 0 : 1, y: 0 }}
				transition={{ duration: skipAnimation ? 0 : 0.3 }}
				className={cn(isLoading && 'hidden', containerClassName)}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				data-testid={(props as any)?.['data-testid'] ? (props as any)['data-testid'] : undefined}>
				{children}
			</motion.div>
		</AnimatePresence>
	);
};
