import { QuestionAnswer } from '../funnel';
import { QuestionGroup } from '../v2/index.type';

export const PortalFlows = {
	edit_organization: 'edit_organization',
	checkup: 'checkup',
} as const;

// Extract the type from the const object
export type IFunnelFlows = keyof typeof PortalFlows;

export const FlowPhase = {
	'premium-questions': 'premium-questions',
	'acceptance-questions': 'acceptance-questions',
} as const;

export type IFlowPhase = keyof typeof FlowPhase;

export type IFlowQuestionAnswer = {
	guid: string;
	answer: string;
};

export type MutationQuestionGroupProps = {
	slug: string;
	customer_guid: string;
	cart_guid: string;
	questionGroup: QuestionGroup;
	isSubmitting?: boolean;
	handleSubmit: (payload: QuestionAnswer | Array<QuestionAnswer>) => Promise<void>;
};
