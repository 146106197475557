import { cva, VariantProps } from 'class-variance-authority';

export const card = cva(['flex', 'flex-col', 'gap-y-24', 'bg-grayscale0', 'rounded-18'], {
	variants: {
		hierarchy: {
			flat: ['border-grayscale200', 'border', 'p-20', 'rounded-18'],
			elevated: ['shadow-1', 'p-20', 'rounded-18'],
			ghost: ['p-12', 'rounded-6'],
		},
		interaction: {
			none: [],
			checkable: [
				'data-checked:border-success300',
				'data-checked:border-2',
				'focus-visible:border-success300',
				'outline-success300',
			],
		},
	},
	defaultVariants: {
		interaction: 'none',
		hierarchy: 'flat',
	},
});

export type CardVariantProps = VariantProps<typeof card>;
