import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { ThemeColors } from '@monorepo/types';

import { Icon } from '../../../Misc';

export type NotificationStatus = 'default' | 'success' | 'error' | 'warning' | 'info';
export type NotificationBg = `bg-${ThemeColors}`;
export type NotificationBorder = `border-${ThemeColors}`;

type Props = PropsWithChildren & {
	status?: NotificationStatus;
	className?: string;
};

export const notificationClasses: {
	bgColor: Record<NotificationStatus, NotificationBg>;
	borderColor: Record<NotificationStatus, NotificationBorder>;
	textColor: Record<NotificationStatus, ThemeColors>;
	icon: Record<NotificationStatus, string>;
	iconColor: Record<NotificationStatus, ThemeColors>;
} = {
	bgColor: {
		default: 'bg-grayscale50',
		success: 'bg-success50',
		error: 'bg-error50',
		warning: 'bg-warning50',
		info: 'bg-info50',
	},
	borderColor: {
		default: 'border-grayscale300',
		success: 'border-success300',
		error: 'border-error300',
		warning: 'border-warning300',
		info: 'border-info300',
	},
	textColor: {
		default: 'grayscale600',
		success: 'grayscale600',
		error: 'grayscale600',
		warning: 'grayscale600',
		info: 'grayscale600',
	},
	icon: {
		default: 'info-circle',
		success: 'check-circle',
		error: 'exclamation-circle',
		warning: 'exclamation-triangle',
		info: 'info-circle',
	},
	iconColor: {
		default: 'grayscale600',
		success: 'success300',
		error: 'error300',
		warning: 'warning300',
		info: 'info300',
	},
};

export const Notification: FC<Props> = ({ status = 'default', className = '', children, ...rest }) => {
	return (
		<div
			className={cn(
				className,
				notificationClasses.bgColor[status],
				notificationClasses.borderColor[status],
				'rounded-12 md:rounded-18 flex flex-row space-x-12 border'
			)}
			{...rest}>
			<div className='leading-none'>
				<Icon size='md' name={notificationClasses.icon[status]} color={notificationClasses.iconColor[status]} />
			</div>
			<div className='grow'>{children}</div>
		</div>
	);
};
