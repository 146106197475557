import { match } from 'ts-pattern';

import { getCalculationType } from '@monorepo/shared/lib/utils';
import { CartCalculationTypes, QuestionGroup } from '@monorepo/types';

import { ROOTS } from './paths';

interface IFindNextQuestion {
	questionGroups: Array<QuestionGroup>;
	currentReference: string;
	calculation_types: CartCalculationTypes;
}

export function findNextProductPage({
	questionGroups,
	currentReference,
	calculation_types,
}: IFindNextQuestion): string | null {
	// Find the Current Question Group for its position
	const questionGroup = questionGroups.find((questionGroup) => questionGroup.reference === currentReference);

	if (!questionGroup) {
		return null;
	}

	// Get calculation type from cart calculation types
	const calculationType = getCalculationType(calculation_types);

	return match([calculationType, questionGroup.position])
		.with(['products', 'precompose'], () => '/')
		.with(['bundles', 'precompose'], () => ROOTS.FUNNEL_COMPOSE_BUNDEL)
		.with(['compositions', 'precompose'], () => ROOTS.FUNNEL_COMPOSE_COMPOSITIONS)
		.with(['compositions', 'preconfigure'], () => '/')
		.otherwise(() => null);
}
