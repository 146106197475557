import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { AcceptanceStateStatus, FlowState, FunnelOrigin } from '@monorepo/types';

import { useGetCartQuery, useGetCurrentInsurancesQuery } from '@funnel/store';
import { useGetPortalCurrentInsurancesQuery, useGetUpsellCartQuery } from '@portal/store';

type Props = {
	cartGuid: string;
	isInReview?: boolean;
	origin?: FunnelOrigin;
};

export const useFlowState = ({ cartGuid, isInReview = false, origin = 'funnel' }: Props): FlowState => {
	const getCart = match(origin)
		.with('upsell', () => useGetUpsellCartQuery)
		.otherwise(() => useGetCartQuery);
	const { data: cart } = getCart({ cart_guid: cartGuid });

	const getCurrentInsurances = match(origin)
		.with('upsell', () => useGetPortalCurrentInsurancesQuery)
		.otherwise(() => useGetCurrentInsurancesQuery);

	const { data: currentInsurances } = getCurrentInsurances({ cart_guid: cartGuid });

	const acceptanceStatus = isInReview ? AcceptanceStateStatus.Accepted : cart?.response?.status;

	return useMemo(() => {
		if (isInReview) {
			if (currentInsurances?.response && currentInsurances?.response?.length > 0) {
				return 'inreview-wel-itc-sad';
			} else {
				return 'inreview-geen-itc-sad';
			}
		}

		if (currentInsurances?.response && currentInsurances?.response?.length > 0) {
			if (acceptanceStatus === AcceptanceStateStatus.Accepted) {
				return 'wel-itc-happy';
			} else {
				return 'wel-itc-sad';
			}
		} else {
			if (acceptanceStatus === AcceptanceStateStatus.Accepted) {
				return 'geen-itc-happy';
			} else {
				return 'geen-itc-sad';
			}
		}
	}, [acceptanceStatus, currentInsurances, isInReview]);
};
