import { ButtonSizeVariants } from './variants';

export function sizeToTextVariantMapper(size: ButtonSizeVariants) {
	switch (size) {
		case 'small':
			return 'body-s';
		case 'large':
			return 'body-l';
		default:
		case 'default':
			return 'body-m';
	}
}
