import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { StandardButton } from '../../../DataEntry';
import { IconButton } from '../../../Misc';
import { EmphasizedPriceFormat, Text } from '../../../TextComponents';
import { Tag } from '../../TagsAndStatus';
import { InsuranceBlurb } from '../InsuranceBlurb/InsuranceBlurb';

export type Feature = {
	guid: string;
	name: string;
	icon: string;
};

export type Props = {
	title: string;
	price: number;
	description?: string;
	className?: string;
	isDisabled?: boolean;
	isLoading?: boolean;
	isChosenMost?: boolean;
	insuredFeatures: Array<Feature>;
	uninsuredFeatures: Array<Feature>;
	onChooseProduct: () => void;
	onMoreInfoClick: () => void;
};

export const CompositionCard: FC<Props> = (props) => {
	const {
		title,
		description,
		price,
		isDisabled,
		isLoading,
		className,
		isChosenMost = false,
		insuredFeatures,
		onChooseProduct,
		onMoreInfoClick,
	} = props;
	const { t } = useTranslation(['common']);

	return (
		<div
			className={classNames('rounded-18 shadow-2 relative p-24', className)}
			data-testid={`multiple-product-card-${title}`}>
			{isChosenMost && (
				<div className='absolute right-24 top-0 -translate-y-1/2 rounded-full'>
					<Tag status='success' text='Meest gekozen' textSize='large' />
				</div>
			)}

			<div className='space-y-20 md:space-y-24'>
				<div className='space-y-16'>
					<div className='space-y-8'>
						<Text
							as='h3'
							variant='display-5'
							weight='semibold'
							color='grayscale600'
							className='flex-grow'
							data-testid={`multiple-product-card-${title}-name`}>
							{title}
						</Text>

						{description && (
							<Text as='span' variant='body-s' color='grayscale400' className='mt-4 flex-grow'>
								{description}
							</Text>
						)}

						<EmphasizedPriceFormat leadingText={t('common.from')} price={price} afterText={t('common.perMonthShort')} />
					</div>

					{onMoreInfoClick && (
						<IconButton
							onClick={() => onMoreInfoClick()}
							iconName='info-circle'
							color='info300'
							size='sm'
							suffix={t('components.MultipleProductCard.whatDoWeInsureWithItConsumer')}
						/>
					)}
				</div>

				<StandardButton
					variant='cta'
					iconRight='angle-right'
					label={t('components.MultipleProductCard.pickInsurance')}
					title={t('components.MultipleProductCard.pickInsurance')}
					className='w-full'
					onClick={onChooseProduct}
					disabled={isDisabled}
					isLoading={isLoading}
					data-testid={`multiple-product-card-${title}-cta`}
				/>
				<div className='space-y-12'>
					{insuredFeatures && insuredFeatures.length > 0 && (
						<>
							<Text variant='body-s' weight='regular' color='grayscale400'>
								{t('components.MultipleProductCard.packageContains')}
							</Text>

							{insuredFeatures.length > 0 && (
								<ul className='space-y-12' data-testid={`multiple-product-card-${title}-required-features`}>
									{insuredFeatures?.map((feature, index) => (
										<li key={`insurance-blurb-required-${title}-${index}`}>
											<InsuranceBlurb name={feature.name} guid={feature.guid} icon_name={feature.icon} />
										</li>
									))}
								</ul>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
