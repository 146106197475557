import classNames from 'classnames';
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import {
	baseClasses,
	disabledClasses,
	fieldSizeLgClasses,
	fieldSizeMdClasses,
	fieldSizeSmClasses,
	focusClasses,
	FormBaseInputDefaultProps,
	FormBaseInputProps,
	hoverClasses,
} from '../FormBaseInput/FormBaseInput';
import { FormBaseInputWrapper } from '../FormBaseInputWrapper/FormBaseInputWrapper';
import { FormHelper } from '../FormHelper/FormHelper';

export type FormValutaInputProps<TFormValues extends FieldValues> = {
	hint?: string;
	hideError?: boolean;
	decimalScale?: number;
	control: Control<TFormValues>;
	includePrefix?: boolean;
	onEndIconClick?: () => void;
} & FormBaseInputDefaultProps<TFormValues> &
	Omit<FormBaseInputProps, 'name' | 'errors' | 'type'>;

export const FormValutaInput = <TFormValues extends Record<string, unknown>>({
	name,
	errors,
	hint,
	hideError,
	decimalScale = 2,
	fieldSize = 'md',
	placeholder,
	control,
	includePrefix = true,
	onEndIconClick,
	defaultValue,
	startIcon,
	endIcon,
	className,
	...props
}: FormValutaInputProps<TFormValues>): JSX.Element => {
	return (
		<FormHelper errors={errors} hint={hint} hideError={hideError}>
			{({ isError }) => (
				<Controller
					control={control}
					defaultValue={defaultValue as PathValue<TFormValues, Path<TFormValues>>}
					name={name}
					render={({ field: { name, onChange, onBlur, value }, fieldState: { isDirty } }) => {
						return (
							<FormBaseInputWrapper
								isDisabled={props.disabled}
								isDirty={isDirty}
								isError={isError}
								isEmpty={!value}
								startIcon={startIcon}
								endIcon={endIcon}
								onEndIconClick={onEndIconClick}>
								<NumericFormat
									className={classNames(
										baseClasses,
										disabledClasses,
										hoverClasses,
										focusClasses,
										isError && 'border-error300',
										!isError && 'border-grayscale200',
										startIcon && 'pl-40',
										endIcon && 'pr-10',
										fieldSize === 'sm' && fieldSizeSmClasses,
										fieldSize === 'md' && fieldSizeMdClasses,
										fieldSize === 'lg' && fieldSizeLgClasses,
										'w-full',
										className
									)}
									decimalScale={decimalScale}
									decimalSeparator=','
									thousandSeparator='.'
									id={name}
									autoComplete='off'
									placeholder={placeholder}
									name={name}
									prefix={includePrefix ? '€ ' : undefined}
									disabled={props.disabled}
									onValueChange={({ value }) => onChange(value)}
									onBlur={onBlur}
									allowNegative={false}
									defaultValue={`${value}`}
									value={`${value}`}
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									data-testid={(props as any)?.['data-testid'] ? (props as any)['data-testid'] : undefined}
								/>
							</FormBaseInputWrapper>
						);
					}}
				/>
			)}
		</FormHelper>
	);
};
