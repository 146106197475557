import { QuestionGroup, QuestionPosition } from '@monorepo/types';

import { findNextQuestionGroup } from './findNextQuestionGroup';
import { sortQuestionGroups } from './sortQuestionGroups';

interface IFindNextQuestion {
	questionGroups: Array<QuestionGroup>;
	currentReference?: string;
	filterByPosition?: QuestionPosition;
}

export function findNextQuestion({
	questionGroups,
	currentReference,
	filterByPosition,
}: IFindNextQuestion): QuestionGroup | null {
	// Group the question groups based on position
	let _questionGroups = sortQuestionGroups(questionGroups);

	// Filter sorted question groups based on reference of filter by position
	if (filterByPosition) {
		_questionGroups = [..._questionGroups].filter((questionGroup) => questionGroup.position === filterByPosition);
	}

	if (currentReference) {
		const currentQuestion = questionGroups.find(
			(currentQuestionGroup) => currentQuestionGroup.reference === currentReference
		);

		if (currentQuestion) {
			_questionGroups = [..._questionGroups].filter(
				(questionGroup) => questionGroup.position === currentQuestion.position
			);
		}
	}

	// If no current reference is specified, the first question group is returned from the sorted questiong groups
	if (!currentReference) {
		const firstQuestionGroup = _questionGroups.length > 0 ? _questionGroups[0] : null;
		return firstQuestionGroup;
	}

	return findNextQuestionGroup(_questionGroups, currentReference);
}
