import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartProduct, ObjectKind } from '@monorepo/types';

import { AppState } from '@common/store';
import { funnelApi } from '@funnel/store';
import { portalApi } from '@portal/store';

export type ObjectsState = {
	type: ObjectKind;
	isLoading: boolean;
};

const initialState: ObjectsState = {
	type: null,
	isLoading: false,
};

export const consumersObjectsSlice = createSlice({
	name: 'objects',
	initialState,
	reducers: {
		setIsLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(funnelApi.endpoints.getCartProducts.matchPending, (state) => {
			state.isLoading = true;
		});
		// Adds the first object as active object to the state when the getObjects request is fulfilled and only one object is present
		builder.addMatcher(funnelApi.endpoints.getCartProducts.matchFulfilled, (state, action) => {
			state.type = determineType(action.payload?.response ?? []);

			state.isLoading = false;
		});

		builder.addMatcher(portalApi.endpoints.getCartProducts.matchFulfilled, (state, action) => {
			state.type = determineType(action.payload?.response ?? []);

			state.isLoading = false;
		});
		builder.addMatcher(portalApi.endpoints.getUpsellCartProducts.matchFulfilled, (state, action) => {
			state.type = determineType(action.payload?.response ?? []);

			state.isLoading = false;
		});
	},
});

function determineType(products: CartProduct[]): ObjectKind {
	const firstProduct = products.length > 0 ? products[0] : undefined;

	const isPet = firstProduct?.object?.specs.some((spec) => spec?.reference === 'species');
	if (isPet) {
		return 'pet';
	}

	const isCamper = firstProduct?.name === 'Camperverzekering';
	if (isCamper) {
		return 'camper';
	}

	const isMotor = firstProduct?.object?.specs.some((spec) => spec?.reference === 'reference');
	if (isMotor) {
		return 'motor';
	}

	return 'other';
}
export const { setIsLoading } = consumersObjectsSlice.actions;

export const selectObjectsLoading = (state: AppState) => state.objects.isLoading;
export const selectObjectType = (state: AppState) => state.objects.type;
