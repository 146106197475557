import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { AccountSchema, accountSchema } from '@monorepo/shared/lib/schemas';

import { ModalActions } from '@common/components';
import { AddressLookup } from '@common/forms/AddressLookup/AddressLookup';

type AccountInformationFormProps = {
	defaultValues?: {
		city?: string;
		street?: string;
		house_number?: number;
		house_number_appendix?: string;
		postal_code?: string;
		firstname?: string;
		lastname?: string;
		phone?: string;
	};
	isLoading?: boolean;
	onCancel: () => void;
	onSubmit: (form: AccountSchema) => void;
};

export const AccountInformationForm: FC<AccountInformationFormProps> = ({
	isLoading,
	defaultValues,
	onSubmit,
	onCancel,
}) => {
	const { t } = useTranslation(['common']);

	const form = useForm<AccountSchema>({
		mode: 'onChange',
		resolver: zodResolver(accountSchema(t)),
		defaultValues: {
			city: defaultValues?.city ?? undefined,
			street: defaultValues?.street ?? undefined,
			house_number: defaultValues?.house_number ?? undefined,
			house_number_appendix: defaultValues?.house_number_appendix ?? undefined,
			postal_code: defaultValues?.postal_code ?? undefined,
			firstname: defaultValues?.firstname ?? undefined,
			lastname: defaultValues?.lastname ?? undefined,
			phone: defaultValues?.phone ?? undefined,
			terms_agreement: true,
		},
	});

	return (
		<Form
			id='edit-account-phone-number-form'
			className='grid grid-cols-12 gap-32'
			onSubmit={form.handleSubmit(onSubmit)}>
			<Form.Group className='col-span-full md:col-span-6'>
				<Form.Label>{t('modals.accountInformationModal.fields.firstname.label')}</Form.Label>
				<Form.TextInput fieldSize='lg' name='firstname' register={form.register} disabled={true} />
			</Form.Group>

			<Form.Group className='col-span-full md:col-span-6'>
				<Form.Label>{t('modals.accountInformationModal.fields.lastname.label')}</Form.Label>
				<Form.TextInput fieldSize='lg' name='lastname' register={form.register} disabled={true} />
			</Form.Group>

			<Form.Group className='col-span-12'>
				<Form.Label>{t('modals.accountInformationModal.fields.phone.label')}</Form.Label>
				<Form.PhoneInput
					fieldSize='lg'
					name='phone'
					placeholder={t('modals.accountInformationModal.fields.phone.placeholder')}
					startIcon='mobile-android'
					register={form.register}
				/>
			</Form.Group>

			<FormProvider {...form}>
				<AddressLookup fieldSize='lg' isDisabled={true} />
			</FormProvider>

			{/* <Form.Group className='col-span-12'>
				<Form.AcceptanceStatementCheckbox
					name='terms_agreement'
					label={t('modals.accountInformationModal.fields.terms.label')}
					register={form.register}
				/>
			</Form.Group> */}
			{/* 
			<Form.Group className='col-span-12'>
				<StaticNotification status='info'>
					<Text variant='body-s' color='grayscale500' weight='regular'>
						<Trans
							i18nKey={'modals.accountInformationModal.informationField'}
							components={{
								bold: <span className='inline-block font-semibold' />,
							}}
						/>
					</Text>
				</StaticNotification>
			</Form.Group> */}

			<Form.Group className='col-span-12'>
				<ModalActions
					className='mt-0'
					cancelButton={
						<StandardButton variant='secondary' label={t('common.cancel')} disabled={isLoading} onClick={onCancel} />
					}
					submitButton={
						<StandardButton
							type='submit'
							variant='primary'
							label={defaultValues ? t('common.update') : t('common.add')}
							iconRight='check'
							isLoading={isLoading}
							disabled={isLoading || !form.formState.isValid}
						/>
					}
				/>
			</Form.Group>
		</Form>
	);
};
