import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { CtaImageListSection } from '@marketing/components';

export const handleSectionCtaImageListButton = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const checklistEle = elements.find(({ identifier }) => identifier === 'lists_checklist') as CmsElement;
	const imageEle = elements.find(({ identifier }) => identifier === 'image') as CmsElement;
	const linkEle = elements.find(({ identifier }) => identifier === 'link-optional-subtext') as CmsElement;
	const title = getProperty('title', checklistEle.custom_fields);
	const checklistItems = checklistEle.child_elements?.filter(({ identifier }) => identifier === 'content');

	const items = checklistItems?.map(({ markdown }) => ({ content: `${markdown}` }));
	const [image] = imageEle.media;
	const [link] = linkEle.links;

	return (props) => (
		<CtaImageListSection
			{...props}
			image={image}
			link={link}
			title={`${title}`}
			items={items}
			className='bg-primary50'
		/>
	);
};
