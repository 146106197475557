import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { ComponentProps, useId } from 'react';

import { Radio } from '../../atoms';

type RadioGroupProps = {
	items: Array<ComponentProps<typeof Radio>>;
	size: ComponentProps<typeof Radio>['size'];
} & ComponentProps<typeof RadixRadioGroup.RadioGroup>;

export function RadioGroup(props: RadioGroupProps) {
	const id = useId();
	const { items, size, ...rest } = props;
	return (
		<RadixRadioGroup.RadioGroup className='flex flex-col gap-y-24' {...rest}>
			{items.map((item, index) => (
				<Radio key={`radio-group-${id}-${index}-${item.value}`} {...item} size={size} />
			))}
		</RadixRadioGroup.RadioGroup>
	);
}
