/**
 * Returns true if process is running in the browser
 */
export const transformToCurrency = (
	number: number | string | undefined | null,
	format: 'en-US' | 'nl-NL' = 'nl-NL',
	currency: 'USD' | 'EUR' = 'EUR'
): string => {
	// Convert to number and validate
	const _number = Number(number);
	if (Number.isNaN(_number)) {
		return new Intl.NumberFormat(format, {
			style: 'currency',
			currency,
			minimumFractionDigits: 0,
		}).format(0);
	}

	// Format currency
	return new Intl.NumberFormat(format, {
		style: 'currency',
		currency,
		minimumFractionDigits: _number % 1 === 0 ? 0 : 2,
	}).format(_number);
};
