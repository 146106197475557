import { CartProduct, CartStatus } from '@monorepo/types';

export function mapCartToAnalyticsProps({
	cartItems,
	acceptanceState,
	workfield,
	salesChannel,
}: {
	cartItems: Array<CartProduct>;
	acceptanceState?: CartStatus;
	workfield?: string;
	salesChannel?: string;
}) {
	let transaction_id = '';
	let value = 0;
	let tax = 0;

	const items = [];

	for (const product of cartItems) {
		transaction_id = product.guid;
		value += product.price?.premium_after_discount || 0;
		tax += product.price?.tax || 0;

		items.push({
			guid: product.guid,
			name: product.name,
		});
	}

	return {
		transaction_id,
		value,
		tax,
		items,
		acceptance_state: acceptanceState,
		...(workfield && { workfield }),
		...(salesChannel && { salesChannel }),
	};
}
