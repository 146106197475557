import {
	ApiResponse,
	CmsDynamicProductResponse,
	CmsProductRequest,
	CmsProductResponse,
	CmsWebpageRequest,
	GetCmsProductRequest,
	GetCmsProductResponse,
} from '@monorepo/types';

import { rootCmsApi } from '../../root/root.api';

const cmsProductsApi = rootCmsApi.injectEndpoints({
	endpoints: (builder) => ({
		getProduct: builder.query<ApiResponse<GetCmsProductResponse>, GetCmsProductRequest>({
			query({ productGuid }) {
				return {
					url: `/products/${productGuid}`,
					method: 'GET',
				};
			},
			keepUnusedDataFor: 3000,
			providesTags: (result, error, { productGuid }) => {
				if (result) {
					return [{ type: 'product', id: productGuid }];
				}

				/* istanbul ignore next */
				return ['product_details'];
			},
		}),
		productContainer: builder.query<ApiResponse<CmsProductResponse>, CmsProductRequest>({
			query({ productGuid, container }) {
				return {
					url: `/products/${productGuid}/containers/${container}`,
					method: 'GET',
				};
			},
			transformResponse(response: ApiResponse<Array<CmsProductResponse>>) {
				return {
					...response,
					response: response.response?.[0],
				};
			},
			keepUnusedDataFor: 3000,
			providesTags: (result, error, { productGuid }) => {
				if (result) {
					return [{ type: 'container', id: productGuid }];
				}

				/* istanbul ignore next */
				return ['container'];
			},
		}),
		listCmsProducts: builder.query<ApiResponse<CmsDynamicProductResponse[]>, CmsWebpageRequest>({
			query({ website = 'maia.insure', path }) {
				return {
					url: `/products`,
					params: {
						website,
						...(path && { path }),
					},
					method: 'GET',
				};
			},
			providesTags: (result, error, { website, path }) => {
				if (result) {
					return [{ type: 'dynamic_products', id: `website=${website}-path=${path}` }];
				}

				/* istanbul ignore next */
				return ['dynamic_products'];
			},
		}),
	}),
	overrideExisting: true,
});

export const { useListCmsProductsQuery, useProductContainerQuery, useLazyProductContainerQuery, useGetProductQuery } =
	cmsProductsApi;

export const { productContainer, getProduct } = cmsProductsApi.endpoints;
