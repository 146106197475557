import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../lib/hooks/useIsMobile/useIsMobile';
import { StandardButton, TextButton } from '../../DataEntry/Buttons';

type StepProgressBoxProps = {
	canSubmit: boolean;
	handleSubmit: () => void;
	handleProceedLater: () => void;
	isLoading?: boolean;
	canContinueLater?: boolean;
	submitLabel?: string;
	className?: string;
};

export const StepProgressBox: FC<StepProgressBoxProps> = ({
	canSubmit,
	handleProceedLater,
	handleSubmit,
	canContinueLater = false,
	isLoading = false,
	submitLabel,
	className,
}) => {
	const { t } = useTranslation(['common']);

	const { isMobile } = useIsMobile();

	return (
		<div
			className={cn(
				'rounded-18 bg-grayscale0 sticky bottom-0 flex w-full flex-wrap items-center justify-between shadow-transparent transition duration-300 ease-out lg:flex-col lg:items-stretch lg:gap-y-16 lg:p-24',
				canSubmit && 'lg:shadow-5',
				className
			)}>
			<StandardButton
				type='button'
				size={isMobile ? 'sm' : 'lg'}
				variant='cta'
				className='order-2 lg:order-1'
				iconRight='angle-right'
				label={submitLabel ?? t('common.next')}
				onClick={handleSubmit}
				isLoading={isLoading}
				disabled={!canSubmit}
			/>
			<TextButton
				type='button'
				className='order-1 lg:order-2'
				size='sm'
				label={t('common.buttonProceedLater')}
				iconLeft='envelope'
				onClick={handleProceedLater}
				isLoading={isLoading}
				disabled={!canContinueLater}
			/>
		</div>
	);
};
