import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';

import { getContact } from '@common/store';
import { PutCustomerEmailRequest, PutCustomerResponse } from './types/putCustomerEmail';

export const customerApi = createApi({
	reducerPath: 'customerApi',
	baseQuery: async (args: FetchArgs, api, extraOptions) => {
		// Get the current state
		const state = api?.getState();

		// @ts-ignore Typescript issue
		const accountGuid = state?.account?.guid;

		if (!accountGuid) {
			const response = await api.dispatch(getContact.initiate()).unwrap();

			if (response.response.type === 'b2c') {
				args.body = { ...args.body, customerGuid: response.response.contact.guid };
			} else {
				args.body = { ...args.body, customerGuid: response.response.organizations?.[0].guid };
			}
		}

		return fetchBaseQuery({
			baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/customer`,
			credentials: 'include',
			prepareHeaders: (headers, api) => {
				// @ts-ignore - Typescript issue
				const token: string = api.getState().account?.idToken;

				if (token) {
					headers.set(`Authorization`, `Bearer ${token}`);
				}

				return headers;
			},
		})(args, api, extraOptions);
	},
	tagTypes: ['update-email'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		putCustomerEmail: builder.mutation<PutCustomerResponse, PutCustomerEmailRequest>({
			query(payload) {
				return {
					url: `/update-email`,
					method: 'PUT',
					body: payload,
				};
			},
		}),
	}),
});

export const { usePutCustomerEmailMutation } = customerApi;

// export const { putCustomerEmail } = updateEmailApi.endpoints;
