import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const insuranceCancellationSchema = (t: TranslationType) =>
	z.object({
		terms_agreement: z.literal<boolean>(true, { required_error: t('validation.required') }),
	});

export type InsuranceCancellationSchema = z.infer<ReturnType<typeof insuranceCancellationSchema>>;
