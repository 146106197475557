import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Question } from '@monorepo/types';

import { StandardButton, TextButton } from '../../../DataEntry';
import { Text } from '../../../TextComponents';
import { StaticNotification } from '../../Notifications';
import { StatementStackCard } from '../StatementStackCard/StatementStackCard';

export const StatementStack: FC<{
	isSubmitting?: boolean;
	questions: Array<Question>;
	onContinue: () => void;
	onChangeAnswers: () => void;
	onAnswerQuestion: (guid: string, answer: boolean) => void;
}> = ({ questions, onAnswerQuestion, onContinue, onChangeAnswers, isSubmitting = false }) => {
	const { t } = useTranslation(['common']);

	return (
		<div className='relative h-[270px] w-full'>
			<AnimatePresence>
				{questions.length > 0 ? (
					questions
						// Sort the questions so that the first question will be presented on top instead of at the bottom
						// .sort((a, b) => b.sequence - a.sequence)
						.map((question, index) => (
							<StatementStackCard
								key={`statement-stack-card-${index}`}
								index={index}
								total={questions.length}
								onAnswer={onAnswerQuestion}
								question={question}
							/>
						))
				) : (
					<div className='space-y-32'>
						<StaticNotification status='success'>
							<Text variant='body-s' weight='regular' color='grayscale500'>
								{t('page.funnel.preconditions.acceptanceStatement.successNotification')}
							</Text>
						</StaticNotification>
						<div className='flex flex-col-reverse gap-y-12'>
							<TextButton variant='standard' label={t('common.changeAnswers')} onClick={onChangeAnswers} />
							<StandardButton
								variant='cta'
								isLoading={isSubmitting}
								disabled={isSubmitting}
								label={t('common.proceed')}
								iconRight='angle-right'
								onClick={onContinue}
								data-testid='confirm'
							/>
						</div>
					</div>
				)}
			</AnimatePresence>
		</div>
	);
};
