import { CartDocument, CartProduct, ProductDocument } from '@monorepo/types';

export function getAllProductDocuments(product: CartProduct, documents: Array<ProductDocument>): Array<CartDocument> {
	const productRootDocs: Array<CartDocument> | undefined = documents.find(
		({ name }) => name === product.name
	)?.documents;
	const productOptionDocs: Array<CartDocument> | undefined = documents
		.find(({ name }) => name === product.name)
		?.options.find(({ name }) => name === product.name)?.documents;

	return [...(productOptionDocs ?? []), ...(productRootDocs ?? [])];
}

export function getProductOptionDocuments(
	product: CartProduct,
	documents: Array<ProductDocument>,
	specValue: string
): Array<CartDocument> {
	const docs: Array<CartDocument> | undefined = documents
		.find(({ name }) => name === product.name)
		?.options.find(({ name }) => name === specValue)?.documents;

	return [...(docs ?? [])];
}
