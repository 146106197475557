import { GetServerSideProps, GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { getLastPartOfUrl, pageMap } from '@monorepo/shared/lib/utils';

/**
 * Get dossierNumber props
 * @param query
 */
export const getDossierNumber: GetServerSideProps = async ({ query }) => {
	const dossierNumber: string = query?.dossierNumber as string;

	if (!dossierNumber) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			dossierNumber,
		},
	};
};

/**
 * Get Cart GUID props
 * @param query
 */
export const getCartGuid: GetServerSideProps = async ({ query }) => {
	const cartGuid: string = query?.cartGuid as string;

	if (!cartGuid) {
		return {
			redirect: {
				permanent: false,
				destination: `/funnel`,
			},
		};
	}

	return {
		props: {
			cartGuid,
		},
	};
};

/**
 * Get Cart GUID props
 * @param query
 */
export const getCancellationGuid: GetServerSideProps = async ({ query }) => {
	const cancellationGuid: string = query?.cancellationGuid as string;

	if (!cancellationGuid) {
		return {
			redirect: {
				permanent: false,
				destination: `/funnel`,
			},
		};
	}

	return {
		props: {
			cancellationGuid,
		},
	};
};

/**
 * Get Cart GUID props
 * @param query
 */
export const getGuid: GetServerSideProps = async ({ query }) => {
	const guid: string = query?.guid as string;

	if (!guid) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			guid,
		},
	};
};

/**
 * Get Cart GUID props
 * @param query
 */
export const getProductGuid: GetServerSideProps = async ({ query }) => {
	const productGuid: string = query?.productGuid as string;

	if (!productGuid) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			productGuid,
		},
	};
};

/**
 * Get Cart optional address guid props
 * @param query
 */
export const getOptionalAddressGuid: GetServerSideProps = async ({ query }) => {
	if (typeof query?.addressGuid === 'string') {
		return {
			props: {
				addressGuid: query.addressGuid,
			},
		};
	}

	return {
		props: {},
	};
};

/**
 * Get translations props
 * @param locale
 */
export const getTranslations: GetServerSideProps = async ({ locale = 'nl' }) => {
	return {
		props: {
			...(await serverSideTranslations(locale, ['common'])),
			locale,
		},
	};
};

/**
 * Get translations props
 * @param locale
 */
export const getActivityGroup: GetServerSideProps = async ({ query }) => {
	const activityGroupGuid = query?.activityGroupGuid as string;

	return {
		props: {
			activityGroupGuid: activityGroupGuid ?? null,
		},
	};
};

/**
 * Get translations props
 */
export const getPrivateProduct: GetStaticProps = async ({ params }) => {
	const product = params?.product as string;

	if (!product) {
		return {
			props: {
				notFound: true,
			},
		};
	}

	return {
		props: {
			product,
		},
	};
};

/**
 * Get premiumQuestionPage slug
 * @param params
 */
export const getQuestionSlug: GetServerSideProps = async ({ params, ...rest }) => {
	const slug = typeof params?.slug === 'string' ? params.slug : getLastPartOfUrl(rest.resolvedUrl) ?? '';

	if (!pageMap.has(slug)) {
		return {
			notFound: true,
		};
	}

	const page = pageMap.get(slug);

	return {
		props: {
			page,
		},
	};
};

/**
 * Get acceptanceQuestions slug
 * @param params
 */
export const getPreviewToken: GetServerSideProps = async ({ query }) => {
	const { token } = query;

	if (!token || token !== process.env.PREVIEW_ENVIRONMENT_SECRET) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			token,
		},
	};
};
