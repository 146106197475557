import { CmsElement } from '../cms';
import { ThemeNames } from '../theme';

export interface Address {
	street: string;
	house_number: number;
	house_number_appendix?: string;
	postal_code: string;
	city: string;
	country?: string;
	street_and_number?: string;
}

export interface Replacement {
	replace_this: string;
	replace_with: string;
}

export interface Spec {
	reference: string;
	description: string;
	answer: NonTransformedAnswer;
}

export interface NonTransformedAnswer {
	input_type: number;
	[key: string]: string | number | Address;
}

export type ProductSpec = {
	type: 'spec';
	value: string;
	children: Array<ProductSpec>;
};

export type InputType =
	| 'toggle_group'
	| 'radio'
	| 'switch'
	| 'radio_switch'
	| 'text'
	| 'number'
	| 'money'
	| 'address'
	| 'email';

export interface CartProduct<TAnswer = Answer> {
	name: string;
	guid: string;
	is_selected: boolean;
	icon: string;
	composition: string;
	object: {
		guid?: string | undefined;
		reference?: string | undefined;
		specs: Array<{
			reference: string;
			description: string;
			answer: TAnswer;
		}>;
	} | null;
	price: Price | null;
	old_price: Price | null;
	questions: Array<ProductQuestion<TAnswer>>;
	add_ons: Array<Addon<TAnswer>>;
	replacements: Array<Replacement>;
	stakeholders: Array<{
		guid: string;
		role: string;
		connection_to_prospect: string;
		contact: {
			first_letters: string;
			first_name: string;
			last_name: string;
			date_of_birth: string;
			address: Address;
		};
	}>;
	minimum_price: number;
	specs: Array<ProductSpec>;
}

export interface HistoricalClaim {
	guid: string;
	object: {
		guid: string;
		reference: string;
		properies: Array<{
			reference: string;
			answer_string: string;
			answer_multiple_choice: { key: string; value: string };
			answer_amount: string;
			answer_checkbox: 0 | 1;
			answer_date: string;
			asnwer_number: number;
			answer_percentage: string;
		}>;
	};
	product: {
		name: string;
		code: string;
		guid: string;
	};
	year: number;
	amount: number;
	explanation: string;
}

export type ProductQuestion<TAnswer = Answer> = {
	type: 'question' | 'text';
	content?: string;
	guid: string;
	reference: string;
	title: string;
	phrasing: string | null;
	description: string;
	icon: string;
	answer: TAnswer;
	options?: Array<{
		value: string;
		label: string;
		is_active: boolean;
		is_positive_option: boolean;
		is_negative_option: boolean;
	}>;
	input_type: InputType;
	position: 'card' | 'modal';
	more_information?: string;
	price?: Price;
};

export interface AcceptanceQuestion {
	guid: string;
	group: string;
	sequence: number;
	question: string;
	type: string;
	answer_text: string | null;
	answer_amount: string | null;
	answer_checkbox: 0 | 1 | null;
	answer_date: string | null;
	answer_multiple_choice: string | null;
	answer_number: string | null;
	answer_percentage: string | null;
	options: Array<{ key: string; value: string }>;
	reference: string;
	replacements?: Array<{
		string: string;
		tooltip: string;
	}>;
	tooltips?: Array<{
		string: string;
		tooltip: string;
	}>;
	icon_name: string;
}

export interface Answer {
	input_type: number;
	value: string | number | null | Address;
	readable?: string | null;
}

export interface Price {
	promotion: boolean;
	premium_before_discount: number;
	discount_total: number;
	premium_after_discount: number;
	tax: number;
	premium_after_tax: number;
	provision: number;
}

export interface PossibleClassification {
	guid: string;
	icon: string;
	name: string;
	suggested: string;
	content: Array<ClassificationContent>;
	children: Array<this>;
}

export interface ClassificationContent {
	sequence: number;
	content: string;
	included: boolean;
}

export interface Classification {
	guid: string;
	name: string;
	group: {
		guid: string;
		description: string;
		code: string;
	};
	suggested: boolean;
	content: Array<ClassificationContent>;
}

export interface QuestionGroup<T = Answer> {
	reference: string;
	title: string;
	subtitle: string;
	replacements: Array<Replacement>;
	sequence: number;
	questions: Array<Question<T>>;
	continueUrl?: string;
	position: QuestionPosition;
}

export type QuestionPosition = 'precompose' | 'postcompose' | 'preconfigure' | 'postconfigure' | 'acceptance';

export type CartCalculationTypes = {
	products: boolean;
	bundles: boolean;
	compositions: boolean;
};

export type CartStatus = 'expired' | 'accepted' | 'review_needed' | 'rejected';

export type BillingUnits = 'monthly' | 'yearly';

export type CartBundle = {
	guid: string;
	sequence: string;
	name: string;
	code: string;
	is_active: boolean;
	minimum_price: number;
	products: Array<CartBundleProduct>;
};
export type CartBundleSeparatedFeatures = {
	guid: string;
	sequence: string;
	name: string;
	code: string;
	is_active: boolean;
	minimum_price: number;
	products: Array<CartBundleProduct>;
	optional: Array<CartBundleProduct>;
	included: Array<CartBundleProduct>;
};

type CartBundleProduct = {
	sequence: number;
	name: string;
	is_optional: boolean;
	code: string;
	guid: string;
	icon: string;
};

export type Cart = {
	guid: string;
	status: CartStatus;
	is_expired: boolean;
	code: string;
	converted: boolean;
	minimum_effective_date: string;
	billing_unit: BillingUnits;
	insurance: {
		effective_date: string;
		premium_date: string;
		payment_method: string;
		iban: string;
	};
	calculation_types: CartCalculationTypes;
	workfield: string;
	contact_type: string;
	has_insurances_to_cancel: boolean;
	riders: boolean;
	'bump-offers': boolean;
	existing_customer: boolean;
	high_roller: boolean;
};

export interface Question<T = Answer> {
	guid: string;
	reference: string;
	title: string;
	phrasing: string;
	icon: string;
	preconditional: boolean;
	type: string;
	answer: T;
	options: Array<{ value: string; label: string }>;
	replacements: Array<Replacement>;
	more_information?: string | null;
}

export interface Addon<T = Answer> {
	guid: string;
	type: string;
	reference: string;
	title: string;
	phrasing: string;
	description: string;
	icon: string;
	input_type: string;
	answer: T;
	options: Array<{ value: string; label: string }>;
	position: string;
	more_information?: string | null;
	children: Array<ProductQuestion<T>>;
	price: Price | null;
}

export interface QuestionOption {
	value: string;
	label: string;
}

export interface Product<T = Answer> {
	guid: string;
	name: string;
	is_selected: boolean;
	icon: string;
	object: ProductObject<T>;
	price: unknown;
	questions: unknown;
	add_ons: unknown;
	stakeholders: unknown;
}

export interface ProductObject<T = Answer> {
	guid: string;
	reference: string;
	specs: Array<ProductObjectSpec<T>>;
}

export interface ProductObjectSpec<T = Answer> {
	reference: string;
	description: string;
	answer: T;
}

export interface Composition {
	sequence: string;
	guid: string;
	code: string;
	product_name: string;
	description: string;
	minimum_price: number;
	primary_options: Array<{
		option_name: string;
		option_code: string;
		icon: string;
	}>;
	addons: Array<{
		option_name: string;
		option_code: string;
		icon: string;
	}>;
	insured_activities: Array<{
		sequence: string;
		guid: string;
		icon: string;
		description: string;
	}>;
}

export interface ClaimStatus {
	status: 'in_review' | 'in_progress' | 'waiting_for_customer' | 'closed';
}

export interface Claim extends ClaimStatus {
	guid: string;
	reference: string;
	subject: string;
	insurance: {
		guid: string;
		reference: string;
		name: string;
		icon_name: string;
	};
	claim_data: {
		claim_description: string;
		claim_date: string;
		claim_year: string;
		claimed_amount: number;
		cause_description: string;
		amount_paid: number;
	};
	addresses: {
		street: string;
		house_number: number;
		house_number_appendix: string;
		postal_code: string;
		city: string;
		country: string;
		is_visit_address: boolean;
		is_postal_address: boolean;
	}[];
	stakeholders: Array<{
		guid: string;
		role: string;
		person: {
			first_name: string;
			initials: string;
			last_name: string;
			email: string;
			mobile_phone: string;
			direct_phone: string;
			payment_info: {
				iban: string;
				payment_method: string;
				billing_unit: BillingUnit;
			};
			role: {
				guid: string;
				name: string;
				rights: string;
			};
			birth_date: string;
		};
	}>;
	attachments: Array<
		Document & {
			guid?: string;
			message_guid?: string;
		}
	>;
}

export type BillingUnit = 'monthly' | 'yearly';

export interface Customer {
	type: 'b2c' | 'b2b';
	sales_channel: null | ThemeNames;
	contact: {
		guid: string;
		initials: string;
		first_name: string;
		last_name: string;
		date_of_birth: string;
		email: string;
		phone: string | null;
		mobile_phone: string | null;
		payment_info: {
			iban: string;
			payment_method: string;
			billing_unit: BillingUnit;
		};
		primary_address: Address;
		role?: {
			guid?: string;
			name?: string;
			rights?: string;
		};
	};
	organizations: Array<Organization>;
}

export interface Organization {
	guid: string;
	name: string;
	coc: string;
	fte: number | null;
	revenue: number | null;
	gross_wage: number | null;
	employees: number | null;
	on_call_employees: number | null;
	owners: number | null;
	primary_address: Address;
	invoicing_email: null | string;
	payment_info: {
		iban: string;
		payment_method: string;
		billing_unit: BillingUnit;
	};
	classifications: Array<Classification>;
	ubos_found: boolean;
	is_mutable: boolean;
}

export interface Clause {
	name: string;
	content: string;
	condition: string | null;
}

export interface Document {
	type: string;
	name: string;
	filename: string;
	mimetype: string;
	path: string;
}

export interface CartDocument {
	type: MediaTypes;
	name: string;
	filename: string;
	mimetype: string;
	path: string;
}

export type ProductDocument = {
	name: string;
	code: string;
	documents: Array<CartDocument>;
	clauses: Array<Clause>;
	composition?: {
		guid: string;
		name: string;
	};
	options: Array<{
		name: string;
		code: string;
		documents: Array<CartDocument>;
		clauses: Array<Clause>;
	}>;
	addons: Array<CartDocument>;
};

export enum MediaTypes {
	Condition = 'condition',
	Ipid = 'ipid',
	GeneralTerms = 'terms_and_conditions',
	ServiceGuide = 'service_guide',
	GeneralInsuranceTerms = 'generalInsuranceTerms',
}

export type NewsItem = {
	guid: string;
	publish_date: string;
	category: string;
	content: {
		title: string;
		body: string;
	};
	media: {
		header: string;
	};
};

export interface TaskStatus {
	status: 'not_started' | 'started' | 'finished' | 'postponed' | 'waiting_for_third_party';
}
export interface TaskUrgency {
	urgency: 'normal' | 'high' | 'critical';
}

export interface Task extends TaskStatus, TaskUrgency {
	date: string;
	end_date: string | null;
	title: string;
	link: string;
	icon: string;
}

export interface UpsellProductContent {
	content: Array<CmsElement>;
}

export interface UpsellProduct extends UpsellProductContent {
	code: string;
	guid: string;
	icon_name: string;
	minimum_price: number | null;
	promotion: string | boolean | null;
	object_code: null | string;
	name: string;
}

export type InsuranceStatus = 'accepted' | 'in_review' | 'rejected' | 'ended' | 'active' | 'pending_cancellation';

export interface Insurance {
	guid: string;
	status: InsuranceStatus;
	name: string;
	icon_name: string;
	reference: string;
	insurer: string;
	product_price: {
		promotion: boolean;
		premium_before_discount: number;
		discount_total: number;
		premium_after_discount: number;
		tax: number;
		premium_after_tax: number;
	};
	product_dates: {
		effective_date: string;
		premium_due_date: string;
		contract_due_date: string;
	};
	requires_classification_for_claim: boolean;
	object_reference: string;
	is_mutable: boolean;
}

export type Faq = {
	guid: string;
	sequence: number;
	content: {
		title: string;
		body: string;
	};
};
