import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import {
	findNextQuestion,
	isBrowser,
	isTest,
	transformOldAcceptanceQuestionsToNewQuestion,
	transformQuestionGroupAnswers,
} from '@monorepo/shared/lib/utils';
import { ApiResponse, GetBundleRequest } from '@monorepo/types';

import { findNextProductPage } from '@common/utils/paths/findNextProductPage';
import { DeleteCurrentInsuranceByGuidRequest } from '../root/types/deleteCurrentInsuranceByGuid';
import { DeleteCurrentInsurancesRequest } from '../root/types/deleteCurrentInsurances';
import { DeleteHistoricalClaimByGuidRequest } from '../root/types/deleteHistoricalClaimByGuid';
import {
	GetAcceptanceQuestionsRequest,
	GetAcceptanceQuestionsResponse,
	GetAcceptanceQuestionsResponseRaw,
} from '../root/types/getAcceptanceQuestions';
import { GetBempOfferRequest, GetBumpOfferResponse, transformBumpOffer } from '../root/types/getBumpOffers';
import { GetBundlesResponse } from '../root/types/getBundles';
import { GetCartRequest, GetCartResponse } from '../root/types/getCart';
import { GetCartDocumentsRequest, GetCartDocumentsResponse } from '../root/types/getCartDocuments';
import { GetClaimableProductsRequest, GetClaimableProductsResponse } from '../root/types/getClaimableProducts';
import { GetClassificationsRequest, GetClassificationsResponse } from '../root/types/getClassifications';
import { GetCompositionsRequest, GetCompositionsResponse } from '../root/types/getCompositions';
import { GetCurrentInsurancesRequest, GetCurrentInsurancesResponse } from '../root/types/getCurrentInsurances';
import { GetCustomerRequest, GetCustomerResponse } from '../root/types/getCustomer';
import { GetHistoricalClaimsRequest, GetHistoricalClaimsResponse } from '../root/types/getHistoricalClaims';
import {
	GetPossibleClassificationsRequest,
	GetPossibleClassificationsResponse,
} from '../root/types/getPossibleClassifications';
import { GetCartProductsRequest, GetCartProductsResponse, transformProduct } from '../root/types/getProducts';
import { GetQuestionsRequest, GetQuestionsResponse, GetRawQuestionsResponse } from '../root/types/getQuestions';
import { GetReviewLinkRequest, GetReviewLinkResponse } from '../root/types/getReviewLink';
import { GetRidersRequest, GetRidersResponse, transformRider } from '../root/types/getRiders';
import { GetTransferableProductsRequest, GetTransferableProductsResponse } from '../root/types/getTransferableProducts';
import { GetUbosRequest, GetUbosResponse } from '../root/types/getUbos';
import { GetUbosRetrieveRequest, GetUbosRetrieveResponse } from '../root/types/getUbosRetrieve';
import { PostApprovalRequest } from '../root/types/postApproval';
import { PostCartRequest, PostCartResponse } from '../root/types/postCart';
import { PostCheckoutRequest, PostCheckoutResponse } from '../root/types/postCheckout';
import { postCurrentInsurancesRequest, postCurrentInsurancesResponse } from '../root/types/postCurrentInsurances';
import { PostPartnerCartRequest, PostPartnerCartResponse } from '../root/types/postPartnerCart';
import {
	PutAcceptanceQuestionsRequest,
	PutAcceptanceQuestionsResponse,
	PutAcceptanceQuestionsResponseRaw,
} from '../root/types/putAcceptanceQuestions';
import { PutBundlesRequest } from '../root/types/putBundles';
import { PutClassificationsRequest, PutClassificationsResponse } from '../root/types/putClassifications';
import { PutCompositionRequest, PutCompositionResponse } from '../root/types/putComposition';
import { PutContactRequest } from '../root/types/putContact';
import { PutCurrentInsuranceByGuidRequest } from '../root/types/putCurrentInsuranceByGuid';
import { PutHistoricalClaimByGuidRequest } from '../root/types/putHistoricalClaimByGuid';
import { PutProductRequest, PutProductResponse } from '../root/types/putProduct';
import { PutProductQuestionRequest } from '../root/types/putProductQuestion';
import { PutQuestionsRequest, PutQuestionsResponse, PutRawQuestionsResponse } from '../root/types/putQuestions';

export const funnelApi = createApi({
	reducerPath: 'funnelApi',
	baseQuery: async (args: FetchArgs, api, extraOptions) => {
		// Make the actual API call
		return fetchBaseQuery({
			baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/funnel`,
			prepareHeaders: (headers, api) => {
				// @ts-ignore Typescript issue
				const token: string = api.getState().account?.idToken;

				if (token) {
					headers.set(`Userauthorization`, `Bearer ${token}`);
				}

				return headers;
			},
		})(args, api, extraOptions);
	},
	tagTypes: [
		'cart',
		'ubo',
		'risk-address',
		'contact',
		'review-link',
		'documents',
		'products',
		'customer',
		'historical-claims',
		'current-insurances',
		'claimable-products',
		'transferable-products',
		'acceptance-questions',
		'compositions',
		'possible-classifications',
		'classifications',
		'questions',
		'riders',
		'bump-offers',
	],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		/* **************************************************** */
		/* 						Cart							*/
		/* **************************************************** */
		postCart: builder.mutation<ApiResponse<PostCartResponse>, PostCartRequest>({
			query(payload) {
				return {
					url: `/carts`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['cart'],
		}),

		postPartnerCart: builder.mutation<ApiResponse<PostPartnerCartResponse>, PostPartnerCartRequest>({
			query(payload) {
				return {
					url: `/partners/compose`,
					method: 'POST',
					body: payload,
				};
			},
			invalidatesTags: ['cart'],
		}),
		getCart: builder.query<ApiResponse<GetCartResponse>, GetCartRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}`,
					method: 'GET',
				};
			},
			providesTags: ['cart'],
		}),
		postApproval: builder.mutation<ApiResponse<unknown>, PostApprovalRequest>({
			query({ cart_guid, ...body }) {
				return {
					url: `/carts/${cart_guid}/approval`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['cart'],
		}),
		/* **************************************************** */
		/* 						Contact							*/
		/* **************************************************** */
		putCartContact: builder.mutation<ApiResponse<void>, PutContactRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/contact`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['cart', 'contact'],
		}),
		/* **************************************************** */
		/* 						Documents							*/
		/* **************************************************** */
		getCartDocuments: builder.query<ApiResponse<GetCartDocumentsResponse>, GetCartDocumentsRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/documents`,
					method: 'GET',
				};
			},
			providesTags: ['products', 'documents'],
		}),
		/* **************************************************** */
		/* 						Products						*/
		/* **************************************************** */
		getBundles: builder.query<ApiResponse<GetBundlesResponse>, GetBundleRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/bundles`,
					method: 'GET',
				};
			},
			providesTags: ['products'],
		}),
		getCartProducts: builder.query<ApiResponse<GetCartProductsResponse>, GetCartProductsRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/products`,
					method: 'GET',
				};
			},
			transformResponse: transformProduct,
			providesTags: ['products'],
		}),
		toggleProduct: builder.mutation<ApiResponse<PutProductResponse>, PutProductRequest>({
			query({ cart_guid, product_guid }) {
				return {
					url: `/carts/${cart_guid}/products/${product_guid}`,
					method: 'PUT',
				};
			},
			invalidatesTags: ['products', 'riders', 'bump-offers', 'cart'],
		}),
		postCartProductRiskAddress: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, product_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/products/${product_guid}/risk-address`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['risk-address', 'cart'],
		}),
		deleteCartProductRiskAddress: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, product_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/products/${product_guid}/risk-address`,
					method: 'DELETE',
					body: rest,
				};
			},
			invalidatesTags: ['risk-address', 'cart'],
		}),
		putProductQuestion: builder.mutation<ApiResponse<PutProductResponse>, PutProductQuestionRequest>({
			query({ cart_guid, product_guid, questions }) {
				return {
					url: `/carts/${cart_guid}/products/${product_guid}/questions`,
					method: 'PUT',
					body: {
						questions,
					},
				};
			},
			invalidatesTags: ['questions'],
		}),
		getCartRiders: builder.query<ApiResponse<GetRidersResponse>, GetRidersRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/riders`,
					method: 'GET',
				};
			},
			transformResponse: transformRider,
			providesTags: ['riders', 'products'],
		}),
		getCartBumpOffers: builder.query<ApiResponse<GetBumpOfferResponse>, GetBempOfferRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/bump-offers`,
					method: 'GET',
				};
			},
			transformResponse: transformBumpOffer,
			providesTags: ['bump-offers', 'products'],
		}),
		/* **************************************************** */
		/* 					Acceptance questions				*/
		/* **************************************************** */
		getAcceptanceQuestions: builder.query<ApiResponse<GetAcceptanceQuestionsResponse>, GetAcceptanceQuestionsRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'GET',
				};
			},
			providesTags: ['cart', 'acceptance-questions'],
			transformResponse(response: ApiResponse<GetAcceptanceQuestionsResponseRaw>, _, params) {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(response.response);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage: nextQuestion.reference,
						},
					};
				}

				return {
					success: response.success,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
		}),
		putAcceptanceQuestions: builder.mutation<
			ApiResponse<PutAcceptanceQuestionsResponse>,
			PutAcceptanceQuestionsRequest
		>({
			query({ cart_guid, questions }) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'PUT',
					body: { questions },
				};
			},
			transformResponse: (response: ApiResponse<PutAcceptanceQuestionsResponseRaw>, _, params) => {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(response.response.questions);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage: nextQuestion.reference,
						},
					};
				}

				return {
					success: response.success,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			invalidatesTags: ['cart', 'acceptance-questions', 'customer'],
		}),
		postAcceptanceQuestions: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/acceptance-questions`,
					method: 'POST',
				};
			},
			invalidatesTags: ['cart', 'acceptance-questions', 'customer'],
		}),
		/* **************************************************** */
		/* 					Historical claims					*/
		/* **************************************************** */
		getHistoricalClaims: builder.query<ApiResponse<GetHistoricalClaimsResponse>, GetHistoricalClaimsRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/historical-claims`,
					method: 'GET',
				};
			},
			providesTags: ['historical-claims'],
		}),
		deleteHistoricalClaims: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/historical-claims`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['historical-claims'],
		}),
		postHistoricalClaims: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/historical-claims`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['historical-claims'],
		}),
		deleteHistoricalClaimByGuid: builder.mutation<ApiResponse<void>, DeleteHistoricalClaimByGuidRequest>({
			query({ cart_guid, historical_claim_guid }) {
				return {
					url: `/carts/${cart_guid}/historical-claims/${historical_claim_guid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['historical-claims'],
		}),

		putHistoricalClaimByGuid: builder.mutation<ApiResponse<void>, PutHistoricalClaimByGuidRequest>({
			query({ cart_guid, historical_claim_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/historical-claims/${historical_claim_guid}`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['historical-claims'],
		}),
		/* **************************************************** */
		/* 					Current insurances					*/
		/* **************************************************** */
		getCurrentInsurances: builder.query<ApiResponse<GetCurrentInsurancesResponse>, GetCurrentInsurancesRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/current-insurances`,
					method: 'GET',
				};
			},
			providesTags: ['current-insurances'],
		}),
		postCurrentInsurances: builder.mutation<ApiResponse<postCurrentInsurancesResponse>, postCurrentInsurancesRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/current-insurances`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['current-insurances'],
		}),
		deleteCurrentInsurances: builder.mutation<ApiResponse<void>, DeleteCurrentInsurancesRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/current-insurances`,
					method: 'DELETE',
					body: rest,
				};
			},
			invalidatesTags: ['current-insurances'],
		}),
		deleteCurrentInsuranceByGuid: builder.mutation<ApiResponse<void>, DeleteCurrentInsuranceByGuidRequest>({
			query({ cart_guid, guid }) {
				return {
					url: `/carts/${cart_guid}/current-insurances/${guid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['current-insurances'],
		}),
		putCurrentInsuranceByGuid: builder.mutation<ApiResponse<void>, PutCurrentInsuranceByGuidRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/current-insurances/${rest.itc_guid}`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['current-insurances'],
		}),
		/* **************************************************** */
		/* 					Claimable products					*/
		/* **************************************************** */
		getClaimableProducts: builder.query<ApiResponse<GetClaimableProductsResponse>, GetClaimableProductsRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/claimable-products`,
					method: 'GET',
				};
			},
			providesTags: ['products', 'cart', 'claimable-products'],
		}),
		/* **************************************************** */
		/* 					Transferable products				*/
		/* **************************************************** */
		getTransferableProducts: builder.query<
			ApiResponse<GetTransferableProductsResponse>,
			GetTransferableProductsRequest
		>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/transferable-products`,
					method: 'GET',
				};
			},
			providesTags: ['products', 'cart', 'transferable-products'],
		}),
		/* **************************************************** */
		/* 						Customer						*/
		/* **************************************************** */
		getCartCustomer: builder.query<ApiResponse<GetCustomerResponse>, GetCustomerRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/customer`,
					method: 'GET',
				};
			},
			providesTags: ['cart', 'customer'],
		}),
		/* **************************************************** */
		/* 						Compositions					*/
		/* **************************************************** */
		getCompositions: builder.query<ApiResponse<GetCompositionsResponse>, GetCompositionsRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/compositions`,
					method: 'GET',
				};
			},
			providesTags: ['products', 'compositions'],
		}),
		putComposition: builder.mutation<ApiResponse<PutCompositionResponse>, PutCompositionRequest>({
			query({ cart_guid, composition_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/compositions/${composition_guid}`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['cart', 'compositions'],
		}),
		/* **************************************************** */
		/* 						Questions						*/
		/* **************************************************** */
		getQuestions: builder.query<ApiResponse<GetQuestionsResponse>, GetQuestionsRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/questions`,
					method: 'GET',
				};
			},
			transformResponse: (response: ApiResponse<GetRawQuestionsResponse>, _, params) => {
				const transformedQuestionGroups = transformQuestionGroupAnswers(response.response);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
					filterByPosition: params.filterByPosition,
				});

				return {
					success: response.success,
					response: {
						nextReference: nextQuestion?.reference ?? null,
						questions: transformedQuestionGroups,
					},
				};
			},
			providesTags: ['questions'],
		}),
		putQuestions: builder.mutation<ApiResponse<PutQuestionsResponse>, PutQuestionsRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/questions`,
					method: 'PUT',
					body: {
						questions: rest.questions,
					},
				};
			},
			transformResponse: (response: ApiResponse<PutRawQuestionsResponse>, _, params) => {
				const transformedQuestionGroups = transformQuestionGroupAnswers(response.response.questions);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage: params.questionPathTransformer
								? params.questionPathTransformer(nextQuestion.reference)
								: nextQuestion.reference,
						},
					};
				}

				if (params.calculation_types && params.currentReference) {
					const nextProductPage = findNextProductPage({
						questionGroups: transformedQuestionGroups,
						calculation_types: params.calculation_types,
						currentReference: params.currentReference,
					});

					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage:
								params.productPathTransformer && nextProductPage
									? params.productPathTransformer(nextProductPage)
									: null,
						},
					};
				}

				return {
					success: response.success,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			invalidatesTags: ['questions', 'cart', 'customer'],
		}),
		putQuestionConfigureProduct: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/questions/configure/products`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['questions', 'cart', 'customer'],
		}),
		/* **************************************************** */
		/* 						Classifications					 */
		/* **************************************************** */
		getPossibleClassifications: builder.query<
			ApiResponse<GetPossibleClassificationsResponse>,
			GetPossibleClassificationsRequest
		>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/possible-classifications`,
					method: 'GET',
				};
			},
			providesTags: ['possible-classifications'],
		}),
		getClassifications: builder.query<ApiResponse<GetClassificationsResponse>, GetClassificationsRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/classifications`,
					method: 'GET',
				};
			},
			providesTags: ['classifications'],
		}),
		putClassifications: builder.mutation<ApiResponse<PutClassificationsResponse>, PutClassificationsRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/classifications`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['classifications'],
		}),
		/* **************************************************** */
		/* 						Bundles							*/
		/* **************************************************** */
		getBundle: builder.query<ApiResponse<GetBundlesResponse>, GetBundleRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/bundles`,
					method: 'GET',
				};
			},
			providesTags: ['products'],
		}),
		putCartBundle: builder.mutation<ApiResponse<never>, PutBundlesRequest>({
			query({ cart_guid, bundle_guid }) {
				return {
					url: `/carts/${cart_guid}/bundles/${bundle_guid}`,
					method: 'PUT',
				};
			},
			invalidatesTags: ['cart', 'products'],
		}),
		/* **************************************************** */
		/* 						Review link						*/
		/* **************************************************** */
		getCartReviewLink: builder.query<ApiResponse<unknown>, unknown>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/review-link`,
					method: 'GET',
				};
			},
			providesTags: ['cart', 'review-link'],
		}),
		/* **************************************************** */
		/* 						UBO								*/
		/* **************************************************** */
		postUbos: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/ubos`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['ubo', 'cart', 'customer'],
		}),
		getUbos: builder.query<ApiResponse<GetUbosResponse>, GetUbosRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/ubos`,
					method: 'GET',
				};
			},
			providesTags: ['ubo'],
		}),
		postUbosRetrieve: builder.mutation<ApiResponse<GetUbosRetrieveResponse>, GetUbosRetrieveRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/ubos/retrieve`,
					method: 'POST',
				};
			},
			invalidatesTags: ['ubo'],
		}),
		/* **************************************************** */
		/* 					Unfinished Cart		   				 */
		/* **************************************************** */
		postCartToEmail: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/carts/${cart_guid}/email-unfinished-cart`,
					method: 'POST',
					body: rest,
				};
			},
		}),
		/* **************************************************** */
		/* 						Checkout						*/
		/* **************************************************** */
		checkout: builder.mutation<ApiResponse<PostCheckoutResponse>, PostCheckoutRequest>({
			query({ shopping_cart, ...rest }) {
				return {
					url: `/carts/${shopping_cart}/checkout`,
					method: 'POST',
					body: rest,
				};
			},
			invalidatesTags: ['cart', 'review-link', 'customer'],
		}),
		/* **************************************************** */
		/* 						Review link						*/
		/* **************************************************** */
		getReviewLink: builder.query<ApiResponse<GetReviewLinkResponse>, GetReviewLinkRequest>({
			query({ cart_guid }) {
				return {
					url: `/carts/${cart_guid}/review-link`,
					method: 'GET',
				};
			},
			providesTags: ['review-link'],
		}),
	}),
});

export const {
	useCheckoutMutation,
	useDeleteCartProductRiskAddressMutation,
	useDeleteCurrentInsurancesMutation,
	useDeleteHistoricalClaimsMutation,
	useGetAcceptanceQuestionsQuery,
	useLazyGetAcceptanceQuestionsQuery,
	useGetCartCustomerQuery,
	useGetCartDocumentsQuery,
	useGetCartProductsQuery,
	useGetCartRidersQuery,
	useGetCartBumpOffersQuery,
	useGetCartQuery,
	useGetCartReviewLinkQuery,
	useGetClaimableProductsQuery,
	useGetClassificationsQuery,
	useGetCompositionsQuery,
	useGetBundleQuery,
	useGetCurrentInsurancesQuery,
	useGetHistoricalClaimsQuery,
	usePostCartProductRiskAddressMutation,
	useGetPossibleClassificationsQuery,
	useGetQuestionsQuery,
	useGetTransferableProductsQuery,
	usePostUbosRetrieveMutation,
	usePostAcceptanceQuestionsMutation,
	usePostCartMutation,
	usePostCartToEmailMutation,
	usePostCurrentInsurancesMutation,
	usePostHistoricalClaimsMutation,
	usePostUbosMutation,
	usePutAcceptanceQuestionsMutation,
	usePutCartBundleMutation,
	usePutCompositionMutation,
	usePutCartContactMutation,
	usePutClassificationsMutation,
	usePutQuestionConfigureProductMutation,
	usePutQuestionsMutation,
	useToggleProductMutation,
	usePutProductQuestionMutation,
	useDeleteCurrentInsuranceByGuidMutation,
	usePutCurrentInsuranceByGuidMutation,
	useDeleteHistoricalClaimByGuidMutation,
	usePutHistoricalClaimByGuidMutation,
	useGetUbosQuery,
	useGetBundlesQuery,
	useLazyGetCartQuery,
	useLazyGetQuestionsQuery,
	usePostPartnerCartMutation,
	useLazyGetCartProductsQuery,
	useGetReviewLinkQuery,
	usePostApprovalMutation,
} = funnelApi;

export const {
	checkout,
	deleteCartProductRiskAddress,
	deleteCurrentInsurances,
	deleteHistoricalClaims,
	getAcceptanceQuestions,
	getCart,
	getCartCustomer,
	getCartDocuments,
	getCartProducts,
	getCartReviewLink,
	getClaimableProducts,
	getClassifications,
	getCompositions,
	getCurrentInsurances,
	getHistoricalClaims,
	getPossibleClassifications,
	getQuestions,
	getTransferableProducts,
	postUbosRetrieve,
	postAcceptanceQuestions,
	postCart,
	postCartProductRiskAddress,
	postCartToEmail,
	postCurrentInsurances,
	postHistoricalClaims,
	postUbos,
	putAcceptanceQuestions,
	putCartBundle,
	putComposition,
	putCartContact,
	putClassifications,
	putQuestionConfigureProduct,
	putQuestions,
} = funnelApi.endpoints;
