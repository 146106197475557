import {
	ApiResponse,
	CmsBundleRequest,
	CmsContainer,
	CmsDynamicBundleResponse,
	CmsWebpageRequest,
} from '@monorepo/types';

import { rootCmsApi } from '../../root/root.api';

const cmsBundlesApi = rootCmsApi.injectEndpoints({
	endpoints: (builder) => ({
		bundleContainer: builder.query<ApiResponse<CmsContainer>, CmsBundleRequest>({
			query({ bundleGuid, cartGuid, container }) {
				return {
					url: `/bundles/${bundleGuid}/containers/${container}`,
					params: {
						cartGuid,
					},
					method: 'GET',
				};
			},
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			transformResponse(response: any) {
				return {
					...response,
					response: response.response?.[0],
				};
			},
			providesTags: (result, error, { bundleGuid }) => {
				if (result) {
					return [{ type: 'container', id: bundleGuid }];
				}

				/* istanbul ignore next */
				return ['container'];
			},
		}),
		listCmsBundles: builder.query<ApiResponse<CmsDynamicBundleResponse[]>, CmsWebpageRequest>({
			query({ website = 'maia.insure', path }) {
				return {
					url: `/bundles`,
					params: {
						...(website && { website }),
						...(path && { path }),
					},
					method: 'GET',
				};
			},
			providesTags: (result, error, { website, path }) => {
				if (result) {
					return [{ type: 'dynamic_bundles', id: `website=${website}-path=${path}` }];
				}

				/* istanbul ignore next */
				return ['dynamic_bundles'];
			},
		}),
	}),
	overrideExisting: true,
});

export const { useListCmsBundlesQuery, useLazyBundleContainerQuery } = cmsBundlesApi;

export const { bundleContainer } = cmsBundlesApi.endpoints;
