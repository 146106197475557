import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'ts-pattern';

import { ClaimSchema } from '@monorepo/shared/lib/schemas';
import { CreateHistoricalClaimRequest, FunnelOrigin } from '@monorepo/types';

import { hideModal, showModal } from '@common/store';
import {
	useDeleteHistoricalClaimByGuidMutation,
	useGetClaimableProductsQuery,
	useGetHistoricalClaimsQuery,
	usePostHistoricalClaimsMutation,
	usePutHistoricalClaimByGuidMutation,
} from '@funnel/store';
import {
	useDeleteUpsellHistoricalClaimByGuidMutation,
	useGetUpsellClaimableProductsQuery,
	useGetUpsellHistoricalClaimsQuery,
	usePostUpsellHistoricalClaimsMutation,
	usePutUpsellHistoricalClaimByGuidMutation,
} from '@portal/store';

export const useClaims = (cart_guid: string, origin: FunnelOrigin = 'funnel') => {
	const [selectedClaim, setSelectedClaim] = useState<ClaimSchema>();
	const dispatch = useDispatch();

	const postHistoricalClaims = match(origin)
		.with('upsell', () => usePostUpsellHistoricalClaimsMutation)
		.otherwise(() => usePostHistoricalClaimsMutation);
	const [saveHistoricalClaimRequest, saveClaimResponse] = postHistoricalClaims();

	const putHistoricalClaimByGuid = match(origin)
		.with('upsell', () => usePutUpsellHistoricalClaimByGuidMutation)
		.otherwise(() => usePutHistoricalClaimByGuidMutation);
	const [updateHistoricalClaimRequest, updateClaimResponse] = putHistoricalClaimByGuid();

	const deleteHistoricalClaimByGuid = match(origin)
		.with('upsell', () => useDeleteUpsellHistoricalClaimByGuidMutation)
		.otherwise(() => useDeleteHistoricalClaimByGuidMutation);
	const [deleteHistoricalClaimRequest, deleteClaimResponse] = deleteHistoricalClaimByGuid();

	const getHistoricalClaims = match(origin)
		.with('upsell', () => useGetUpsellHistoricalClaimsQuery)
		.otherwise(() => useGetHistoricalClaimsQuery);
	const { data: claims } = getHistoricalClaims({ cart_guid: cart_guid });

	const getClaimableProducts = match(origin)
		.with('upsell', () => useGetUpsellClaimableProductsQuery)
		.otherwise(() => useGetClaimableProductsQuery);
	const { data: claimableProducts } = getClaimableProducts({ cart_guid: cart_guid });

	const isLoading = saveClaimResponse.isLoading || updateClaimResponse.isLoading || deleteClaimResponse.isLoading;

	/**
	 * Open the modal to add a new claim
	 */
	const handleAdd = () => {
		setSelectedClaim(undefined);
		dispatch(showModal('add-or-edit-claim-modal'));
	};

	/**
	 * Open the edit modal that is prefilled with the clicked claim fields
	 */
	const handleEdit = (claim: ClaimSchema) => {
		setSelectedClaim(claim);
		dispatch(showModal('add-or-edit-claim-modal'));
	};

	/**
	 * Cancels the changes made to a claim
	 */
	const handleCancelEdit = () => {
		setSelectedClaim(undefined);
		dispatch(hideModal('add-or-edit-claim-modal'));
	};

	/**
	 * Open the prompt to delete the claim
	 */
	const handleDeleteRequest = (claim: ClaimSchema) => {
		setSelectedClaim(claim);
		dispatch(showModal('delete-claim-prompt'));
	};

	/**
	 * Close the prompt to delete the claim
	 */
	const handleCancelDelete = () => {
		setSelectedClaim(undefined);
		dispatch(hideModal('delete-claim-prompt'));
	};

	/**
	 * Deletes a claim
	 */
	const handleDelete = async () => {
		await deleteHistoricalClaimRequest({ cart_guid, historical_claim_guid: selectedClaim?.guid ?? '' });
		dispatch(hideModal('delete-claim-prompt'));
	};

	/**
	 * Saves a new claim or updates an existing claim
	 */
	const handleSubmit = async (claim: ClaimSchema) => {
		if (claim.guid) {
			await updateHistoricalClaimRequest({
				cart_guid: cart_guid,
				historical_claim_guid: claim.guid,
				explanation: claim.explanation,
				year: claim.year,
				amount: claim.amount,
				product_guid:
					claimableProducts?.response?.find((transferableProduct) => transferableProduct.guid === claim.product_guid)
						?.guid ?? '',
			});
			dispatch(hideModal('add-or-edit-claim-modal'));
		} else {
			const historicalClaims: CreateHistoricalClaimRequest = {
				cart_guid,
				historical_claims: [
					{ product_guid: claim.product_guid, year: claim.year, amount: claim.amount, explanation: claim.explanation },
				],
			};

			await saveHistoricalClaimRequest(historicalClaims).unwrap();

			dispatch(hideModal('add-or-edit-claim-modal'));
		}
	};

	return {
		claims: claims?.response ?? [],
		isLoading,
		selectedClaim,
		handleAdd,
		handleEdit,
		handleCancelEdit,
		handleDelete,
		handleDeleteRequest,
		handleCancelDelete,
		handleSubmit,
	};
};
