import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@common/store';
import { funnelApi } from '@funnel/store/api/funnel.api';
import { portalApi } from '@portal/store';

export type SelectedClassification = {
	name: string;
	guid: string;
};

export type ClassificationsSliceState = {
	selectedClassifications: SelectedClassification[];
};

// Initial state
const initialState: ClassificationsSliceState = {
	selectedClassifications: [],
};

// Actual Slice
export const classificationsSlice = createSlice({
	name: 'classifications',
	initialState,
	reducers: {
		// Action to set the cart
		setClassifications(state, action: PayloadAction<SelectedClassification[]>) {
			state.selectedClassifications = action.payload;
		},
		addClassification(state, action: PayloadAction<SelectedClassification>) {
			state.selectedClassifications = [...state.selectedClassifications, action.payload];
		},
		removeClassification(state, action: PayloadAction<string>) {
			state.selectedClassifications = [...state.selectedClassifications].filter((c) => c.guid !== action.payload);
		},
	},
	extraReducers: (builder) => {
		// B2B Cart Query
		builder.addMatcher(funnelApi.endpoints.getClassifications.matchFulfilled, (state, action) => {
			const copy: SelectedClassification[] = [...action.payload.response].map((classification) => ({
				guid: classification.guid,
				name: classification.name,
			}));
			state.selectedClassifications = [...copy];
		});
		builder.addMatcher(portalApi.endpoints.getMutationClassifications.matchFulfilled, (state, action) => {
			const copy: SelectedClassification[] = [...action.payload.response].map((classification) => ({
				guid: classification.guid,
				name: classification.description,
			}));
			state.selectedClassifications = [...copy];
		});
	},
});

export const { setClassifications, addClassification, removeClassification } = classificationsSlice.actions;

export const selectClassifications = (state: AppState) => state.classifications.selectedClassifications;
