import { createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import {
	findNextQuestion,
	isBrowser,
	isTest,
	transformOldAcceptanceQuestionsToNewQuestion,
	transformQuestionGroupAnswers,
} from '@monorepo/shared/lib/utils';
import { ApiResponse, CartFromQueryParams, ISaveUboRequest, ISaveUboResponse, IUboResponse } from '@monorepo/types';

import { getContact } from '@common/store';
import { findNextProductPage } from '@common/utils/paths/findNextProductPage';
import { DeleteHistoricalClaimByGuidRequest } from '@funnel/store/root/types/deleteHistoricalClaimByGuid';
import {
	GetAcceptanceQuestionsRequest,
	GetAcceptanceQuestionsResponse,
	GetAcceptanceQuestionsResponseRaw,
} from '@funnel/store/root/types/getAcceptanceQuestions';
import {
	GetClaimableProductsRequest,
	GetClaimableProductsResponse,
} from '@funnel/store/root/types/getClaimableProducts';
import { GetCompositionsRequest, GetCompositionsResponse } from '@funnel/store/root/types/getCompositions';
import {
	GetCurrentInsurancesRequest,
	GetCurrentInsurancesResponse,
} from '@funnel/store/root/types/getCurrentInsurances';
import { GetHistoricalClaimsRequest, GetHistoricalClaimsResponse } from '@funnel/store/root/types/getHistoricalClaims';
import { GetCartProductsRequest, GetCartProductsResponse } from '@funnel/store/root/types/getProducts';
import {
	GetTransferableProductsRequest,
	GetTransferableProductsResponse,
} from '@funnel/store/root/types/getTransferableProducts';
import { PostCheckoutRequest, PostCheckoutResponse } from '@funnel/store/root/types/postCheckout';
import {
	PutAcceptanceQuestionsRequest,
	PutAcceptanceQuestionsResponse,
	PutAcceptanceQuestionsResponseRaw,
} from '@funnel/store/root/types/putAcceptanceQuestions';
import { PutCompositionRequest, PutCompositionResponse } from '@funnel/store/root/types/putComposition';
import { PutHistoricalClaimByGuidRequest } from '@funnel/store/root/types/putHistoricalClaimByGuid';
import { PutProductRequest, PutProductResponse } from '@funnel/store/root/types/putProduct';
import { PutProductQuestionRequest } from '@funnel/store/root/types/putProductQuestion';
import { DeleteClaimHandlingMessageAttachmentRequest } from './types/deleteClaimHandlingMessageAttachment';
import { GetCartDocumentsRequest, GetCartDocumentsResponse } from './types/getCartDocuments';
import { GetClaimRequest, GetClaimResponse } from './types/getClaim';
import { GetClaimAttachmentsRequest, GetClaimAttachmentsResponse } from './types/getClaimAttachments';
import { GetClaimHandlingMessagesRequest, GetClaimHandlingMessagesResponse } from './types/getClaimHandlingMessages';
import { GetClaimIntakeClaimProductsResponse } from './types/getClaimIntakeClaimProducts';
import { GetClaimIntakeProductsRequest, GetClaimIntakeProductsResponse } from './types/getClaimIntakeProducts';
import { GetClaimIntakeQuestionsRequest, GetClaimIntakeQuestionsResponse } from './types/getClaimIntakeQuestions';
import { GetClaimsResponse } from './types/getClaims';
import { GetClaimUpdatesRequest, GetClaimUpdatesResponse } from './types/getClaimUpdates';
import { GetClassificationsResponse } from './types/getClassifications';
import { GetCustomerResponse } from './types/getCustomer';
import { GetFaqsResponse } from './types/getFaqs';
import { GetInsuranceRequest, GetInsuranceResponse } from './types/getInsurance';
import {
	GetInsuranceCancellationRequest,
	GetInsuranceCancellationResponse,
	GetRawInsuranceCancellationResponse,
} from './types/getInsuranceCancellation';
import {
	GetInsuranceCancellationQuestionsRequest,
	GetInsuranceCancellationQuestionsResponse,
	GetRawInsuranceCancellationQuestionsResponse,
} from './types/getInsuranceCancellationQuestions';
import { GetInsurancesResponse } from './types/getInsurances';
import { GetInvoicesResponse } from './types/getInvoices';
import { GetMutationRequest, GetMutationResponse } from './types/getMutation';
import { GetMutationAddressesRequest, GetMutationAddressesResponse } from './types/getMutationAddresses';
import {
	GetMutationClassificationsRequest,
	GetMutationClassificationsResponse,
} from './types/getMutationClassifications';
import { GetMutationCompositionsRequest, GetMutationCompositionsResponse } from './types/getMutationCompositions';
import { GetMutationDocumentsRequest, GetMutationDocumentsResponse } from './types/getMutationDocuments';
import {
	GetMutationNestedClassificationsRequest,
	GetMutationNestedClassificationsResponse,
} from './types/getMutationNestedClassifications';
import { GetMutationProductsRequest, GetMutationProductsResponse } from './types/getMutationProducts';
import {
	GetMutationQuestionsRequest,
	GetMutationQuestionsResponse,
	GetRawMutationQuestionsResponse,
} from './types/getMutationQuestions';
import { GetMutationRulesRequest, GetMutationRulesResponse } from './types/getMutationRules';
import { getNewsItemRequest, getNewsItemResponse } from './types/getNewsItem';
import { GetNewsItemsResponse } from './types/getNewsItems';
import { GetProductContainerRequest } from './types/getProductContainer';
import { GetProductsResponse } from './types/getProducts';
import { GetTasksResponse } from './types/getTasks';
import { GetUbosResponse } from './types/getUbos';
import { GetUpsellCartRequest, GetUpsellCartResponse } from './types/getUpsellCart';
import {
	GetUpsellCartProductsRequest,
	GetUpsellCartProductsResponse,
	transformProduct,
} from './types/getUpsellCartProducts';
import { GetUpsellProductsResponse } from './types/getUpsellProducts';
import {
	GetRawUpsellQuestionsResponse,
	GetUpsellQuestionsRequest,
	GetUpsellQuestionsResponse,
} from './types/getUpsellQuestions';
import {
	PostClaimHandlingMessageAttachmentRequest,
	PostClaimHandlingMessageAttachmentResponse,
} from './types/postClaimHandlingMessageAttachment';
import { PostClaimHandlingMessagesRequest } from './types/postClaimHandlingMessages';
import { PostClaimIntakeRequest, PostClaimIntakeResponse } from './types/postClaimIntake';
import { PostClaimIntakeEmailUnfinishedRequest } from './types/postClaimIntakeEmailUnfinished';
import { PostInsuranceCancellationRequest, PostInsuranceCancellationResponse } from './types/postInsuranceCancellation';
import { PostInsuranceCertificateRequest, PostInsuranceCertificateResponse } from './types/postInsuranceCertificate';
import { PostInsuranceDocumentRequest, PostInsuranceDocumentResponse } from './types/postInsuranceDocument';
import { PostInsuranceMutationRequest, PostInsuranceMutationResponse } from './types/postInsuranceMutation';
import { PostInsuranceOverviewResponse } from './types/postInsuranceOverview';
import { PostInvoicesRequest, PostInvoicesResponse } from './types/postInvoices';
import { PostMutationResponse } from './types/postMutation';
import { PostUpsellCartRequest, PostUpsellCartResponse } from './types/postUpsellCart';
import { PutClaimIntakeRequest } from './types/putClaimIntake';
import { PutClaimIntakeQuestionsRequest, PutClaimIntakeQuestionsResponse } from './types/PutClaimIntakeQuestions';
import { PutCustomerBillingRequest } from './types/putCustomerBilling';
import { PutCustomerIbanRequest } from './types/putCustomerIban';
import { PutCustomerInvoiceEmailRequest } from './types/putCustomerInvoiceEmail';
import { PutCustomerPhoneRequest } from './types/putCustomerPhone';
import { PutInsuranceCancellationRequest } from './types/putInsuranceCancellation';
import {
	PutInsuranceCancellationQuestionsRequest,
	PutInsuranceCancellationQuestionsResponse,
	PutRawInsuranceCancellationQuestionsResponse,
} from './types/putInsuranceCancellationQuestions';
import {
	PutMutationAcceptanceQuestionsRequest,
	PutMutationAcceptanceQuestionsResponse,
	PutMutationAcceptanceQuestionsResponseRaw,
} from './types/putMutationAcceptanceQuestions';
import { PutMutationAddressesRequest } from './types/putMutationAddresses';
import { PutMutationCompositionsRequest } from './types/putMutationCompositions';
import { PutMutationFinalizeRequest, PutMutationFinalizeResponse } from './types/putMutationFinalize';
import { PutMutationIndustrialClassificationsRequest } from './types/putMutationIndustrialClassifications';
import {
	PutMutationQuestionsRequest,
	PutMutationQuestionsResponse,
	PutMutationRawQuestionsResponse,
} from './types/putMutationQuestions';
import {
	PutRawUpsellQuestionsResponse,
	PutUpsellQuestionsRequest,
	PutUpsellQuestionsResponse,
} from './types/putUpsellQuestions';

export const portalApi = createApi({
	reducerPath: 'portalApi',
	baseQuery: async (args: FetchArgs, api, extraOptions) => {
		// Get the current state
		const state = api?.getState();
		// @ts-ignore Typescript issue
		const accountGuid = state?.account?.guid;

		if (!accountGuid) {
			const response = await api.dispatch(getContact.initiate()).unwrap();

			if (response.response.type === 'b2c') {
				args.url = args.url.replace('%%customerGuid%%', response.response.contact.guid);
			} else {
				args.url = args.url.replace('%%customerGuid%%', response.response.organizations?.[0].guid);
			}
		}

		// Make the actual API call
		return fetchBaseQuery({
			baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/portal`,
			prepareHeaders: async (headers, api) => {
				// @ts-ignore Typescript issue
				const token: string = await api.getState().account?.idToken;

				if (token) {
					await headers.set(`Userauthorization`, `Bearer ${token}`);
				}

				return headers;
			},
		})(args, api, extraOptions);
	},
	tagTypes: [
		'customer',
		'cart-documents',
		'product-container',
		'upsell-products',
		'claim',
		'ubo',
		'claim-intake',
		'task',
		'faq',
		'classification',
		'product',
		'news-item',
		'invoice',
		'insurance',
		'ubo',
		'mutation',
		'organization',
		'question',
		'upsell',
		'cancellation',
	],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		// /* **************************************************** */
		// /* 						Customer					   */
		// /* **************************************************** */
		getCustomer: builder.query<ApiResponse<GetCustomerResponse>, void>({
			query() {
				return {
					url: `/customer`,
					method: 'GET',
				};
			},
			providesTags: ['customer'],
		}),
		putCustomerIban: builder.mutation<ApiResponse<void>, PutCustomerIbanRequest>({
			query(body) {
				return {
					url: `/customers/%%customerGuid%%/iban`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: ['customer'],
		}),
		putCustomerBilling: builder.mutation<ApiResponse<void>, PutCustomerBillingRequest>({
			query({ billing_unit_code }) {
				return {
					url: `/customers/%%customerGuid%%/billing`,
					method: 'PUT',
					body: {
						billing_unit_code,
					},
				};
			},
			invalidatesTags: ['customer'],
		}),
		putCustomerInfo: builder.mutation<ApiResponse<void>, PutCustomerIbanRequest>({
			query(body) {
				return {
					url: `/customers/%%customerGuid%%/account/contact-info`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: ['customer'],
		}),
		putCustomerPhone: builder.mutation<ApiResponse<void>, PutCustomerPhoneRequest>({
			query(body) {
				return {
					url: `/customers/%%customerGuid%%/account/phone`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: ['customer'],
		}),
		putCustomerInvoiceEmail: builder.mutation<ApiResponse<void>, PutCustomerInvoiceEmailRequest>({
			query(body) {
				return {
					url: `/customers/%%customerGuid%%/invoice-email`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: ['customer'],
		}),
		// /* **************************************************** */
		// /* 						Cart						   */
		// /* **************************************************** */
		getCartDocuments: builder.query<ApiResponse<GetCartDocumentsResponse>, GetCartDocumentsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/documents`,
					method: 'GET',
				};
			},
			providesTags: ['cart-documents'],
		}),
		// /* **************************************************** */
		// /* 						Products					   */
		// /* **************************************************** */
		getProductContainers: builder.query<ApiResponse<void>, GetProductContainerRequest>({
			query({ recommendationline_guid }) {
				return {
					url: `/cms/products/${recommendationline_guid}/containers`,
					method: 'GET',
				};
			},
			providesTags: ['product-container'],
		}),
		// /* **************************************************** */
		// /* 						Claims						   */
		// /* **************************************************** */
		getClaims: builder.query<ApiResponse<GetClaimsResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/claims`,
					method: 'GET',
				};
			},
			providesTags: ['claim'],
		}),
		getClaim: builder.query<ApiResponse<GetClaimResponse>, GetClaimRequest>({
			query({ claim_guid }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}`,
					method: 'GET',
				};
			},
			providesTags: ['claim'],
		}),
		getClaimAttachments: builder.query<ApiResponse<GetClaimAttachmentsResponse>, GetClaimAttachmentsRequest>({
			query({ claim_guid }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/attachments`,
					method: 'GET',
				};
			},
			providesTags: ['claim'],
		}),
		getClaimUpdates: builder.query<ApiResponse<GetClaimUpdatesResponse>, GetClaimUpdatesRequest>({
			query({ claim_guid }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/handling/updates`,
					method: 'GET',
				};
			},
			providesTags: ['claim'],
		}),
		// /* **************************************************** */
		// /* 						Claim Intake				   */
		// /* **************************************************** */
		getClaimIntakeClaimProducts: builder.query<ApiResponse<GetClaimIntakeClaimProductsResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/claims/intake/claim-products`,
					method: 'GET',
				};
			},
			providesTags: ['claim-intake'],
		}),
		getClaimIntakeQuestions: builder.query<
			ApiResponse<GetClaimIntakeQuestionsResponse>,
			GetClaimIntakeQuestionsRequest
		>({
			query({ claim_guid }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/intake/questions`,
					method: 'GET',
				};
			},
			providesTags: ['claim-intake'],
		}),
		putClaimIntakeQuestions: builder.mutation<
			ApiResponse<PutClaimIntakeQuestionsResponse>,
			PutClaimIntakeQuestionsRequest
		>({
			query({ claim_guid, answers }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/intake/questions`,
					method: 'PUT',
					body: { questions: answers },
				};
			},
			invalidatesTags: ['claim-intake'],
		}),
		getClaimHandlingMessages: builder.query<
			ApiResponse<GetClaimHandlingMessagesResponse>,
			GetClaimHandlingMessagesRequest
		>({
			query({ claim_guid }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/handling/messages`,
					method: 'GET',
				};
			},
			providesTags: ['claim-intake'],
		}),
		postClaimHandlingMessages: builder.mutation<ApiResponse<void>, PostClaimHandlingMessagesRequest>({
			query({ claim_guid, ...body }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/handling/messages`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['claim-intake'],
		}),
		deleteClaimHandlingMessageAttachment: builder.mutation<
			ApiResponse<void>,
			DeleteClaimHandlingMessageAttachmentRequest
		>({
			query({ claim_guid, message_guid, attachment_guid }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/handling/messages/${message_guid}/attachments/${attachment_guid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['claim-intake'],
		}),
		postClaimHandlingMessageAttachment: builder.mutation<
			ApiResponse<PostClaimHandlingMessageAttachmentResponse>,
			PostClaimHandlingMessageAttachmentRequest
		>({
			query({ claim_guid, message_guid, attachment_guid }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/handling/messages/${message_guid}/attachments/${attachment_guid}`,
					method: 'POST',
				};
			},
			invalidatesTags: ['claim-intake'],
		}),

		postClaimIntakeEmailUnfinished: builder.mutation<ApiResponse<void>, PostClaimIntakeEmailUnfinishedRequest>({
			query({ claim_guid, ...body }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/intake/email-unfinished-claim`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['claim-intake'],
		}),
		getClaimIntakeProducts: builder.query<ApiResponse<GetClaimIntakeProductsResponse>, GetClaimIntakeProductsRequest>({
			query(params) {
				return {
					url: `/customers/%%customerGuid%%/claims/intake/claim-products`,
					method: 'GET',
					params,
				};
			},
		}),
		postClaimIntake: builder.mutation<ApiResponse<PostClaimIntakeResponse>, PostClaimIntakeRequest>({
			query(body) {
				return {
					url: `/customers/%%customerGuid%%/claims/intake`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['claim-intake'],
		}),
		putClaimIntake: builder.mutation<ApiResponse<void>, PutClaimIntakeRequest>({
			query({ claim_guid }) {
				return {
					url: `/customers/%%customerGuid%%/claims/${claim_guid}/intake`,
					method: 'PUT',
				};
			},
		}),
		// /* **************************************************** */
		// /* 					Upsell products 				   */
		// /* **************************************************** */
		getUpsellProducts: builder.query<ApiResponse<GetUpsellProductsResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/upsellproducts`,
					method: 'GET',
				};
			},
			providesTags: ['upsell-products'],
		}),
		// /* **************************************************** */
		// /* 					Products		 				   */
		// /* **************************************************** */
		getProducts: builder.query<ApiResponse<GetProductsResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/products`,
					method: 'GET',
				};
			},
			providesTags: ['product'],
		}),
		// /* **************************************************** */
		// /* 						Tasks		 				   */
		// /* **************************************************** */
		getTasks: builder.query<ApiResponse<GetTasksResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/tasks`,
					method: 'GET',
				};
			},
			providesTags: ['task'],
		}),
		// /* **************************************************** */
		// /* 					Classifications	 				    */
		// /* **************************************************** */
		getClassifications: builder.query<ApiResponse<GetClassificationsResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/classifications`,
					method: 'GET',
				};
			},
			providesTags: ['classification'],
		}),
		// /* **************************************************** */
		// /* 							Faq		 				   */
		// /* **************************************************** */
		getFaq: builder.query<ApiResponse<GetFaqsResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/faqs`,
					method: 'GET',
				};
			},
			providesTags: ['faq'],
		}),
		// /* **************************************************** */
		// /* 						News item			 		   */
		// /* **************************************************** */
		getNewsItems: builder.query<ApiResponse<GetNewsItemsResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/newsitems`,
					method: 'GET',
				};
			},
			providesTags: ['news-item'],
		}),
		getNewsItem: builder.query<ApiResponse<getNewsItemResponse>, getNewsItemRequest>({
			query({ newsitem_guid }) {
				return {
					url: `/customers/%%customerGuid%%/newsitems/${newsitem_guid}`,
					method: 'GET',
				};
			},
			providesTags: ['news-item'],
		}),
		// /* **************************************************** */
		// /* 						Invoices				 	   */
		// /* **************************************************** */
		getInvoices: builder.query<ApiResponse<GetInvoicesResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/invoices`,
					method: 'GET',
				};
			},
			providesTags: ['invoice'],
		}),
		postInvoices: builder.mutation<ApiResponse<PostInvoicesResponse>, PostInvoicesRequest>({
			query(body) {
				return {
					url: `/customers/%%customerGuid%%/invoices`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['invoice'],
		}),
		// /* **************************************************** */
		// /* 						Insurance				 	   */
		// /* **************************************************** */
		getInsurances: builder.query<ApiResponse<GetInsurancesResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/insurances`,
					method: 'GET',
				};
			},
			providesTags: ['insurance'],
		}),
		getInsurance: builder.query<ApiResponse<GetInsuranceResponse>, GetInsuranceRequest>({
			query({ insurance_guid }) {
				return {
					url: `/customers/%%customerGuid%%/insurances/${insurance_guid}`,
					method: 'GET',
				};
			},
			providesTags: ['insurance'],
		}),
		postInsuranceOverview: builder.mutation<ApiResponse<PostInsuranceOverviewResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/insurances/insurance-overview`,
					method: 'POST',
				};
			},
			invalidatesTags: ['insurance'],
		}),
		postInsuranceCertificate: builder.mutation<
			ApiResponse<PostInsuranceCertificateResponse>,
			PostInsuranceCertificateRequest
		>({
			query({ insurance_guid }) {
				return {
					url: `/customers/%%customerGuid%%/insurances/${insurance_guid}/insurance-certificate`,
					method: 'POST',
				};
			},
			invalidatesTags: ['insurance'],
		}),
		postInsuranceDocument: builder.mutation<ApiResponse<PostInsuranceDocumentResponse>, PostInsuranceDocumentRequest>({
			query({ insurance_guid, document_guid }) {
				return {
					url: `/customers/%%customerGuid%%/insurances/${insurance_guid}/documents/${document_guid}`,
					method: 'POST',
				};
			},
			invalidatesTags: ['insurance'],
		}),
		// /* **************************************************** */
		// /* 							UBO					 	   */
		// /* **************************************************** */
		getUbos: builder.query<ApiResponse<GetUbosResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/ubo`,
					method: 'GET',
				};
			},
			providesTags: ['ubo'],
		}),
		// /* **************************************************** */
		// /* 						Mutations				 	   */
		// /* **************************************************** */
		getMutation: builder.query<ApiResponse<GetMutationResponse>, GetMutationRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}`,
					method: 'GET',
				};
			},
			providesTags: ['mutation'],
		}),
		getMutationAddresses: builder.query<ApiResponse<GetMutationAddressesResponse>, GetMutationAddressesRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/addresses`,
					method: 'GET',
				};
			},
			providesTags: ['mutation'],
		}),
		putMutationAddresses: builder.mutation<ApiResponse<void>, PutMutationAddressesRequest>({
			query({ cart_guid, ...body }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/addresses`,
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: ['mutation'],
		}),
		getMutationClassifications: builder.query<
			ApiResponse<GetMutationClassificationsResponse>,
			GetMutationClassificationsRequest
		>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/classifications`,
					method: 'GET',
				};
			},
			providesTags: ['mutation'],
		}),
		getMutationAcceptanceQuestions: builder.query<
			ApiResponse<GetAcceptanceQuestionsResponse>,
			GetAcceptanceQuestionsRequest
		>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/acceptance-questions`,
					method: 'GET',
				};
			},
			transformResponse(response: ApiResponse<GetAcceptanceQuestionsResponseRaw>, _, params) {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(response.response);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage: nextQuestion.reference,
						},
					};
				}

				return {
					success: response.success,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			providesTags: ['mutation'],
		}),
		putMutationAcceptanceQuestions: builder.mutation<
			ApiResponse<PutMutationAcceptanceQuestionsResponse>,
			PutMutationAcceptanceQuestionsRequest
		>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/acceptance-questions`,
					method: 'PUT',
					body: {
						questions: rest.questions,
					},
				};
			},
			transformResponse: (response: ApiResponse<PutMutationAcceptanceQuestionsResponseRaw>, _, params) => {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(response.response.questions);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						...response,
						response: {
							questions: transformedQuestionGroups,
							nextPage: params.questionPathTransformer
								? params.questionPathTransformer(nextQuestion.reference)
								: nextQuestion.reference,
						},
					};
				}

				return {
					...response,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			invalidatesTags: ['mutation'],
		}),
		postMutationAcceptanceQuestions: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/acceptance-questions`,
					method: 'POST',
				};
			},
			invalidatesTags: ['mutation'],
		}),
		getMutationRules: builder.query<ApiResponse<GetMutationRulesResponse>, GetMutationRulesRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/rules`,
					method: 'GET',
				};
			},
			providesTags: ['mutation'],
		}),
		getMutationDocuments: builder.query<ApiResponse<GetMutationDocumentsResponse>, GetMutationDocumentsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/documents`,
					method: 'GET',
				};
			},
			providesTags: ['mutation'],
		}),
		getMutationNestedClassifications: builder.query<
			ApiResponse<GetMutationNestedClassificationsResponse>,
			GetMutationNestedClassificationsRequest
		>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/classifications/nested`,
					method: 'GET',
				};
			},
			providesTags: ['mutation'],
		}),
		getMutationCompositions: builder.query<
			ApiResponse<GetMutationCompositionsResponse>,
			GetMutationCompositionsRequest
		>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/compositions`,
					method: 'GET',
				};
			},
			providesTags: ['mutation'],
		}),
		putMutationCompositions: builder.mutation<ApiResponse<void>, PutMutationCompositionsRequest>({
			query({ cart_guid, composition_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/compositions/${composition_guid}`,
					method: 'PUT',
				};
			},
			invalidatesTags: ['mutation'],
		}),
		getMutationQuestions: builder.query<ApiResponse<GetMutationQuestionsResponse>, GetMutationQuestionsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/questions`,
					method: 'GET',
				};
			},
			transformResponse: (response: ApiResponse<GetRawMutationQuestionsResponse>, _, params) => {
				const transformedQuestionGroups = transformQuestionGroupAnswers(response.response);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
					filterByPosition: params.filterByPosition,
				});

				return {
					...response,
					response: {
						nextReference: nextQuestion?.reference ?? null,
						questions: transformedQuestionGroups,
					},
				};
			},
			providesTags: ['mutation', 'question'],
		}),
		putMutationQuestions: builder.mutation<ApiResponse<PutMutationQuestionsResponse>, PutMutationQuestionsRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/questions`,
					method: 'PUT',
					body: {
						questions: rest.questions,
					},
				};
			},
			transformResponse: (response: ApiResponse<PutMutationRawQuestionsResponse>, _, params) => {
				const transformedQuestionGroups = transformQuestionGroupAnswers(response.response.questions);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						...response,
						response: {
							questions: transformedQuestionGroups,
							nextPage: params.questionPathTransformer
								? params.questionPathTransformer(nextQuestion.reference)
								: nextQuestion.reference,
						},
					};
				}

				if (params.calculation_types && params.currentReference) {
					const nextProductPage = findNextProductPage({
						questionGroups: transformedQuestionGroups,
						calculation_types: params.calculation_types,
						currentReference: params.currentReference,
					});

					return {
						...response,
						response: {
							questions: transformedQuestionGroups,
							nextPage:
								params.productPathTransformer && nextProductPage
									? params.productPathTransformer(nextProductPage)
									: null,
						},
					};
				}

				return {
					...response,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			invalidatesTags: ['mutation', 'question'],
		}),
		getMutationProducts: builder.query<ApiResponse<GetMutationProductsResponse>, GetMutationProductsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/products`,
					method: 'GET',
				};
			},
			providesTags: ['mutation', 'product'],
		}),
		putMutationProductQuestion: builder.mutation<ApiResponse<PutProductResponse>, PutProductQuestionRequest>({
			query({ cart_guid, product_guid, questions }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/products/${product_guid}/questions`,
					method: 'PUT',
					body: {
						questions,
					},
				};
			},
			invalidatesTags: ['mutation', 'product'],
		}),
		postInsuranceMutation: builder.mutation<ApiResponse<PostInsuranceMutationResponse>, PostInsuranceMutationRequest>({
			query({ insurance_guid }) {
				return {
					url: `/customers/%%customerGuid%%/insurances/${insurance_guid}/mutations`,
					method: 'POST',
				};
			},
			invalidatesTags: ['mutation'],
		}),
		getInsuranceMutationQuestions: builder.query<ApiResponse<unknown>, unknown>({
			query({ insurance_guid }) {
				return {
					url: `/customers/%%customerGuid%%/insurances/${insurance_guid}/questions`,
					method: 'GET',
				};
			},
			providesTags: ['mutation'],
		}),
		putMutationIndustrialClassifications: builder.mutation<
			ApiResponse<unknown>,
			PutMutationIndustrialClassificationsRequest
		>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/industrial-classifications`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['mutation'],
		}),
		putMutationInsuranceRiskAddress: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, insurance_guid }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/insurances/${insurance_guid}/risk-address`,
					method: 'PUT',
				};
			},
			invalidatesTags: ['mutation'],
		}),
		putMutationFinalize: builder.mutation<ApiResponse<PutMutationFinalizeResponse>, PutMutationFinalizeRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/customers/%%customerGuid%%/mutations/${cart_guid}/finalize`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['mutation'],
		}),
		postMutation: builder.mutation<ApiResponse<PostMutationResponse>, void>({
			query() {
				return {
					url: `/customers/%%customerGuid%%/mutations`,
					method: 'POST',
				};
			},
			invalidatesTags: ['mutation'],
		}),
		cartFromQueryParams: builder.mutation<
			{ cart_guid: string; premiums_calculated: boolean; object_guid: string },
			{ payload: CartFromQueryParams }
		>({
			query({ payload }) {
				return {
					url: '/partners/compose',
					method: 'POST',
					body: payload,
				};
			},
		}),
		// /* **************************************************** */
		// /* 						Upsell					 	   */
		// /* **************************************************** */
		postUpsellCart: builder.mutation<ApiResponse<PostUpsellCartResponse>, PostUpsellCartRequest>({
			query(body) {
				return {
					url: `/customers/%%customerGuid%%/carts`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['upsell'],
		}),
		postUpsellCheckout: builder.mutation<ApiResponse<PostCheckoutResponse>, PostCheckoutRequest>({
			query({ shopping_cart, ...body }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${shopping_cart}/checkout`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['upsell'],
		}),
		getUpsellCart: builder.query<ApiResponse<GetUpsellCartResponse>, GetUpsellCartRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}`,
					method: 'GET',
				};
			},
			providesTags: ['upsell'],
		}),
		getCartProducts: builder.query<ApiResponse<GetCartProductsResponse>, GetCartProductsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/products`,
					method: 'GET',
				};
			},
			transformResponse: transformProduct,
			providesTags: ['product'],
		}),
		getUpsellCompositions: builder.query<ApiResponse<GetCompositionsResponse>, GetCompositionsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/compositions`,
					method: 'GET',
				};
			},
			providesTags: ['upsell'],
		}),
		putUpsellComposition: builder.mutation<ApiResponse<PutCompositionResponse>, PutCompositionRequest>({
			query({ cart_guid, composition_guid, ...rest }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/compositions/${composition_guid}`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['upsell'],
		}),
		getUpsellQuestions: builder.query<ApiResponse<GetUpsellQuestionsResponse>, GetUpsellQuestionsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/questions`,
					method: 'GET',
				};
			},
			transformResponse: (response: ApiResponse<GetRawUpsellQuestionsResponse>, _, params) => {
				const transformedQuestionGroups = transformQuestionGroupAnswers(response.response);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
					filterByPosition: params.filterByPosition,
				});

				return {
					success: response.success,
					response: {
						nextReference: nextQuestion?.reference ?? null,
						questions: transformedQuestionGroups,
					},
				};
			},
			providesTags: ['upsell'],
		}),
		putUpsellProductQuestion: builder.mutation<ApiResponse<PutProductResponse>, PutProductQuestionRequest>({
			query({ cart_guid, product_guid, questions }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/products/${product_guid}/questions`,
					method: 'PUT',
					body: {
						questions,
					},
				};
			},
			invalidatesTags: ['upsell'],
		}),
		getUpsellCartProducts: builder.query<ApiResponse<GetUpsellCartProductsResponse>, GetUpsellCartProductsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/products`,
					method: 'GET',
				};
			},
			transformResponse: transformProduct,
			providesTags: ['upsell'],
		}),
		toggleUpsellProduct: builder.mutation<ApiResponse<PutProductResponse>, PutProductRequest>({
			query({ cart_guid, product_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/products/${product_guid}`,
					method: 'PUT',
				};
			},
			invalidatesTags: ['upsell'],
		}),
		putUpsellQuestions: builder.mutation<ApiResponse<PutUpsellQuestionsResponse>, PutUpsellQuestionsRequest>({
			query({ cart_guid, ...rest }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/questions`,
					method: 'PUT',
					body: {
						questions: rest.questions,
					},
				};
			},
			transformResponse: (response: ApiResponse<PutRawUpsellQuestionsResponse>, _, params) => {
				const transformedQuestionGroups = transformQuestionGroupAnswers(response.response.questions);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage: params.questionPathTransformer
								? params.questionPathTransformer(nextQuestion.reference)
								: nextQuestion.reference,
						},
					};
				}

				if (params.calculation_types && params.currentReference) {
					const nextProductPage = findNextProductPage({
						questionGroups: transformedQuestionGroups,
						calculation_types: params.calculation_types,
						currentReference: params.currentReference,
					});

					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage:
								params.productPathTransformer && nextProductPage
									? params.productPathTransformer(nextProductPage)
									: null,
						},
					};
				}

				return {
					success: response.success,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			invalidatesTags: ['upsell'],
		}),

		getUpsellTransferableProducts: builder.query<
			ApiResponse<GetTransferableProductsResponse>,
			GetTransferableProductsRequest
		>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/transferable-products`,
					method: 'GET',
				};
			},
			providesTags: ['upsell'],
		}),
		// Historical Claims
		getUpsellHistoricalClaims: builder.query<ApiResponse<GetHistoricalClaimsResponse>, GetHistoricalClaimsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/historical-claims`,
					method: 'GET',
				};
			},
			providesTags: ['upsell'],
		}),
		postUpsellHistoricalClaims: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, ...body }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/historical-claims`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['upsell'],
		}),
		deleteUpsellHistoricalClaims: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/historical-claims`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['upsell'],
		}),
		deleteUpsellHistoricalClaimByGuid: builder.mutation<ApiResponse<void>, DeleteHistoricalClaimByGuidRequest>({
			query({ cart_guid, historical_claim_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/historical-claims/${historical_claim_guid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['upsell'],
		}),
		putUpsellHistoricalClaimByGuid: builder.mutation<ApiResponse<void>, PutHistoricalClaimByGuidRequest>({
			query({ cart_guid, historical_claim_guid, ...rest }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/historical-claims/${historical_claim_guid}`,
					method: 'PUT',
					body: rest,
				};
			},
			invalidatesTags: ['upsell'],
		}),
		getUpsellAcceptanceQuestions: builder.query<
			ApiResponse<GetAcceptanceQuestionsResponse>,
			GetAcceptanceQuestionsRequest
		>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/acceptance-questions`,
					method: 'GET',
				};
			},
			transformResponse(response: ApiResponse<GetAcceptanceQuestionsResponseRaw>, _, params) {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(response.response);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage: nextQuestion.reference,
						},
					};
				}

				return {
					success: response.success,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			providesTags: ['upsell'],
		}),
		getPortalCurrentInsurances: builder.query<ApiResponse<GetCurrentInsurancesResponse>, GetCurrentInsurancesRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/current-insurances`,
					method: 'GET',
				};
			},
			providesTags: ['upsell'],
		}),
		postPortalCurrentInsurances: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, ...body }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/current-insurances`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['upsell'],
		}),
		deletePortalCurrentInsurances: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/current-insurances`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['upsell'],
		}),
		putPortalCurrentInsurancesByGuid: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, itc_guid, ...body }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/current-insurances/${itc_guid}`,
					method: 'PUT',
					body: { ...body, itc_guid },
				};
			},
			invalidatesTags: ['upsell'],
		}),
		deletePortalCurrentInsurancesByGuid: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid, guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/current-insurances/${guid}`,
					method: 'DELETE',
				};
			},
			invalidatesTags: ['upsell'],
		}),
		postUpsellAcceptanceQuestions: builder.mutation<ApiResponse<unknown>, unknown>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/acceptance-questions`,
					method: 'POST',
				};
			},
			invalidatesTags: ['upsell'],
		}),
		putUpsellAcceptanceQuestions: builder.mutation<
			ApiResponse<PutAcceptanceQuestionsResponse>,
			PutAcceptanceQuestionsRequest
		>({
			query({ cart_guid, questions }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/acceptance-questions`,
					method: 'PUT',
					body: { questions },
				};
			},
			transformResponse: (response: ApiResponse<PutAcceptanceQuestionsResponseRaw>, _, params) => {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(response.response.questions);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage: nextQuestion.reference,
						},
					};
				}

				return {
					success: response.success,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			invalidatesTags: ['upsell'],
		}),
		// /* **************************************************** */
		// /* 					Cancellations				 	   */
		// /* **************************************************** */
		postInsuranceCancellation: builder.mutation<
			ApiResponse<PostInsuranceCancellationResponse>,
			PostInsuranceCancellationRequest
		>({
			query({ insurance_guid }) {
				return {
					url: `/customers/%%customerGuid%%/insurances/${insurance_guid}/cancellations`,
					method: 'POST',
				};
			},
			invalidatesTags: ['cancellation'],
		}),
		getInsuranceCancellation: builder.query<
			ApiResponse<GetInsuranceCancellationResponse>,
			GetInsuranceCancellationRequest
		>({
			query({ insurance_guid, cancellation_guid }) {
				return {
					url: `/customers/%%customerGuid%%/insurances/${insurance_guid}/cancellations/${cancellation_guid}`,
					method: 'GET',
				};
			},
			transformResponse(response: ApiResponse<GetRawInsuranceCancellationResponse>) {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(response.response.questions);

				return {
					success: response.success,
					response: {
						...response.response,
						questions: transformedQuestionGroups,
					},
				};
			},
			providesTags: ['cancellation'],
		}),
		getInsuranceCancellationQuestions: builder.query<
			ApiResponse<GetInsuranceCancellationQuestionsResponse>,
			GetInsuranceCancellationQuestionsRequest
		>({
			query({ insurance_guid, cancellation_guid }) {
				return {
					url: `/customers/%%customerGuid%%/insurances/${insurance_guid}/cancellations/${cancellation_guid}/questions`,
					method: 'GET',
				};
			},
			transformResponse(
				{ response, success }: ApiResponse<GetRawInsuranceCancellationQuestionsResponse>,
				meta,
				params
			) {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(response);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
					filterByPosition: params.filterByPosition,
				});

				return {
					success: success,
					response: {
						nextReference: nextQuestion?.reference ?? null,
						questions: transformedQuestionGroups,
					},
				};
			},
			providesTags: ['cancellation'],
		}),
		putInsuranceCancellationQuestions: builder.mutation<
			ApiResponse<PutInsuranceCancellationQuestionsResponse>,
			PutInsuranceCancellationQuestionsRequest
		>({
			query({ cancellation_guid, ...rest }) {
				return {
					url: `/customers/%%customerGuid%%/cancellations/${cancellation_guid}/questions`,
					method: 'PUT',
					body: {
						questions: rest.questions,
					},
				};
			},
			transformResponse: (response: ApiResponse<PutRawInsuranceCancellationQuestionsResponse>, _, params) => {
				const transformedQuestionGroups = transformOldAcceptanceQuestionsToNewQuestion(
					response.response?.questions ?? []
				);

				const nextQuestion = findNextQuestion({
					questionGroups: transformedQuestionGroups,
					currentReference: params.currentReference,
				});

				if (nextQuestion) {
					return {
						success: response.success,
						response: {
							questions: transformedQuestionGroups,
							nextPage: params.questionPathTransformer
								? params.questionPathTransformer(nextQuestion.reference)
								: nextQuestion.reference,
						},
					};
				}

				return {
					success: response.success,
					response: {
						questions: transformedQuestionGroups,
						nextPage: null,
					},
				};
			},
			invalidatesTags: ['cancellation'],
		}),
		putInsuranceCancellation: builder.mutation<ApiResponse<void>, PutInsuranceCancellationRequest>({
			query({ cancellation_guid }) {
				return {
					url: `/customers/%%customerGuid%%/cancellations/${cancellation_guid}/finalize`,
					method: 'PUT',
				};
			},
			invalidatesTags: ['cancellation'],
		}),

		getUpsellClaimableProducts: builder.query<ApiResponse<GetClaimableProductsResponse>, GetClaimableProductsRequest>({
			query({ cart_guid }) {
				return {
					url: `/customers/%%customerGuid%%/carts/${cart_guid}/claimable-products`,
					method: 'GET',
				};
			},
			providesTags: ['upsell'],
		}),

		getUbo: builder.query<ApiResponse<IUboResponse>, string>({
			query(guid) {
				return {
					url: `/customers/%%customerGuid%%/ubo`,
					method: 'GET',
				};
			},
			providesTags: (_, _err, guid) => {
				return [{ type: 'ubo', id: guid }];
			},
		}),
		saveUbo: builder.mutation<ApiResponse<ISaveUboResponse>, ISaveUboRequest>({
			query({ guid, ubo_contacts }) {
				return {
					url: `/ubo/${guid}`,
					method: 'POST',
					body: {
						ubo_contacts,
					},
				};
			},
			invalidatesTags: ['ubo'],
		}),
	}),
});

export const {
	useGetCustomerQuery,
	useLazyGetCustomerQuery,
	useGetCartDocumentsQuery,
	useGetClaimAttachmentsQuery,
	useGetClaimIntakeClaimProductsQuery,
	useGetClaimIntakeQuestionsQuery,
	useGetClaimQuery,
	useGetClaimUpdatesQuery,
	useGetClaimsQuery,
	useGetClassificationsQuery,
	useGetFaqQuery,
	useGetInsuranceQuery,
	useGetInsurancesQuery,
	useGetInvoicesQuery,
	useGetNewsItemQuery,
	useGetNewsItemsQuery,
	useGetProductContainersQuery,
	useGetProductsQuery,
	useGetTasksQuery,
	useGetUbosQuery,
	useGetUpsellProductsQuery,
	usePutCustomerIbanMutation,
	useGetMutationAcceptanceQuestionsQuery,
	useGetMutationAddressesQuery,
	useGetMutationClassificationsQuery,
	useGetMutationCompositionsQuery,
	useGetMutationNestedClassificationsQuery,
	useGetMutationQuery,
	useGetMutationQuestionsQuery,
	useLazyGetMutationQuestionsQuery,
	useGetMutationRulesQuery,
	usePostInsuranceMutationMutation,
	usePostMutationAcceptanceQuestionsMutation,
	usePostMutationMutation,
	usePutMutationAcceptanceQuestionsMutation,
	usePutMutationIndustrialClassificationsMutation,
	usePutMutationAddressesMutation,
	usePutMutationFinalizeMutation,
	usePutMutationInsuranceRiskAddressMutation,
	usePutMutationQuestionsMutation,
	useGetMutationProductsQuery,
	useGetMutationDocumentsQuery,
	usePostUpsellCartMutation,
	usePutCustomerInfoMutation,
	usePostUpsellHistoricalClaimsMutation,
	useCartFromQueryParamsMutation,
	useDeleteUpsellHistoricalClaimsMutation,
	useGetUpsellHistoricalClaimsQuery,
	useGetUpsellTransferableProductsQuery,
	usePostInsuranceCancellationMutation,
	useGetInsuranceCancellationQuestionsQuery,
	useLazyGetInsuranceCancellationQuestionsQuery,
	usePutCustomerBillingMutation,
	useGetUpsellCartQuery,
	useGetUpsellQuestionsQuery,
	usePutUpsellQuestionsMutation,
	usePutInsuranceCancellationQuestionsMutation,
	useGetInsuranceCancellationQuery,
	usePutInsuranceCancellationMutation,
	usePostInvoicesMutation,
	usePostInsuranceOverviewMutation,
	usePostInsuranceCertificateMutation,
	usePostInsuranceDocumentMutation,
	useGetClaimHandlingMessagesQuery,
	usePostClaimHandlingMessagesMutation,
	useDeleteClaimHandlingMessageAttachmentMutation,
	usePostClaimIntakeEmailUnfinishedMutation,
	useGetClaimIntakeProductsQuery,
	usePostClaimIntakeMutation,
	usePutClaimIntakeQuestionsMutation,
	usePutClaimIntakeMutation,
	usePostClaimHandlingMessageAttachmentMutation,
	usePutCustomerPhoneMutation,
	usePutCustomerInvoiceEmailMutation,
	useGetInsuranceMutationQuestionsQuery,
	useGetUpsellCartProductsQuery,
	useGetUpsellCompositionsQuery,
	useGetCartProductsQuery,
	usePutUpsellCompositionMutation,
	useLazyGetUpsellQuestionsQuery,
	useLazyGetCartProductsQuery,
	useToggleUpsellProductMutation,
	usePutUpsellProductQuestionMutation,
	usePostUpsellAcceptanceQuestionsMutation,
	useGetUpsellAcceptanceQuestionsQuery,
	useLazyGetUpsellAcceptanceQuestionsQuery,
	usePutUpsellAcceptanceQuestionsMutation,
	usePutMutationCompositionsMutation,
	usePutMutationProductQuestionMutation,
	useLazyGetMutationAcceptanceQuestionsQuery,
	useGetPortalCurrentInsurancesQuery,
	usePostPortalCurrentInsurancesMutation,
	useDeletePortalCurrentInsurancesMutation,
	usePutPortalCurrentInsurancesByGuidMutation,
	useDeletePortalCurrentInsurancesByGuidMutation,
	useGetUpsellClaimableProductsQuery,
	useDeleteUpsellHistoricalClaimByGuidMutation,
	usePutUpsellHistoricalClaimByGuidMutation,
	usePostUpsellCheckoutMutation,
} = portalApi;

export const {
	getCustomer,
	getCartDocuments,
	getClaim,
	getClaimAttachments,
	getClaimIntakeClaimProducts,
	getClaimIntakeQuestions,
	getClaimUpdates,
	getClaims,
	getClassifications,
	getFaq,
	getInsurance,
	getInsurances,
	getInvoices,
	getNewsItem,
	getNewsItems,
	getProductContainers,
	getProducts,
	getTasks,
	getUbos,
	getUpsellProducts,
	putCustomerIban,
	getMutation,
	getMutationAcceptanceQuestions,
	getMutationAddresses,
	getMutationClassifications,
	getMutationCompositions,
	getMutationNestedClassifications,
	getMutationProducts,
	getMutationQuestions,
	getMutationRules,
	postInsuranceMutation,
	postMutation,
	postMutationAcceptanceQuestions,
	putMutationAcceptanceQuestions,
	putMutationAddresses,
	putMutationFinalize,
	putMutationIndustrialClassifications,
	putMutationInsuranceRiskAddress,
	putMutationQuestions,
} = portalApi.endpoints;
