import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { isBrowser, isTest } from '@monorepo/shared/lib/utils';
import { ApiResponse, GetCountriesResponse, Insurer } from '@monorepo/types';

export const dataApi = createApi({
	reducerPath: 'dataApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${isTest || !isBrowser ? process.env.NEXT_PUBLIC_WEBAPP_URL : ''}/api/data`,
	}),
	tagTypes: ['Insurer', 'Countries'],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getCountries: builder.query<ApiResponse<GetCountriesResponse>, void>({
			query() {
				return {
					url: `/countries`,
					method: 'GET',
				};
			},
			providesTags: ['Countries'],
		}),
		getInsurers: builder.query<ApiResponse<Insurer[]>, void>({
			query() {
				return {
					url: '/insurers',
					method: 'GET',
				};
			},
			// providesTags: (result) => {
			// 	if (result) {
			// 		return result.map(({ code }) => ({ type: 'Insurer', id: code }));
			// 	}

			// 	/* istanbul ignore next */
			// 	return ['Insurer'];
			// },
		}),
	}),
});

export const { useGetCountriesQuery, useGetInsurersQuery, useLazyGetInsurersQuery } = dataApi;

export const { getCountries, getInsurers } = dataApi.endpoints;
