import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Text } from '@monorepo/components/TextComponents';
import { PensionPlanSchema } from '@monorepo/shared/lib/schemas';
import { transformToCurrency } from '@monorepo/shared/lib/utils';
import { Slider } from '@monorepo/ui/atoms';

type PensionSpecificationsBoxProps = {
	oneTimeDepositRange: Array<number>;
	depositPerMonthRange: Array<number>;
	isLoading?: boolean;
};

export const PensionSpecificationsBox: FC<PensionSpecificationsBoxProps> = ({
	oneTimeDepositRange,
	depositPerMonthRange,
	isLoading = false,
}) => {
	const { t } = useTranslation(['common']);

	const formContext = useFormContext<PensionPlanSchema>();

	const ageField = formContext.watch('age');
	const retirementAgeField = formContext.watch('retirementAge');
	const oneTimeDepositField = formContext.watch('oneTimeDeposit');
	const depositPerMonthField = formContext.watch('depositPerMonth');

	const getDynamicOneTimeDepositStep = (range: Array<number>, value?: number) => {
		if (typeof value === 'undefined' || value > range.length) return range[0];

		return range[value];
	};

	const getDefaultValue = (range: Array<number>, value?: number) => {
		const foundIndex = range.findIndex((val) => val === value);

		if (foundIndex === -1) return 0;

		return foundIndex;
	};

	return (
		<div className='bg-info50 rounded-18 p-24'>
			<div>
				<Text variant='body-s' weight='medium' color='grayscale600' className='bg-primary400/30'>
					{t('components.pensionSpecificationsBox.specifications')}
				</Text>
				<div className='mt-16 flex flex-col gap-8'>
					<div className='flex flex-col gap-y-8'>
						<div className='flex items-center justify-between'>
							<Text variant='body-xs' weight='regular' color='grayscale600' className='flex grow'>
								{t('components.pensionSpecificationsBox.age')}
							</Text>

							<Text
								variant='body-xs'
								weight='medium'
								color='grayscale300'
								className='border-grayscale200 rounded-6 bg-grayscale50 flex items-center border px-12 py-8'>
								{ageField}
							</Text>
						</div>

						<Slider
							onValueChange={(e) => formContext.setValue('age', e[0])}
							min={18}
							max={60}
							value={[ageField]}
							defaultValue={[ageField]}
							disabled={isLoading}
						/>
					</div>

					<div className='flex flex-col gap-y-8'>
						<div className='flex items-center justify-between'>
							<Text variant='body-xs' weight='regular' color='grayscale600' className='flex grow'>
								{t('components.pensionSpecificationsBox.pensionAge')}
							</Text>

							<Text
								variant='body-xs'
								weight='medium'
								color='grayscale300'
								className='border-grayscale200 rounded-6 bg-grayscale50 flex items-center border px-12 py-8'>
								{retirementAgeField}
							</Text>
						</div>
						<Slider
							onValueChange={(e) => formContext.setValue('retirementAge', e[0])}
							min={55}
							max={75}
							value={[retirementAgeField]}
							defaultValue={[retirementAgeField]}
							disabled={isLoading}
						/>
					</div>

					<div className='flex flex-col gap-y-8'>
						<div className='flex items-center justify-between'>
							<Text variant='body-xs' weight='regular' color='grayscale600' className='flex grow'>
								{t('components.pensionSpecificationsBox.oneTimeInlay')}
							</Text>

							<Text
								variant='body-xs'
								weight='medium'
								color='grayscale300'
								className='border-grayscale200 rounded-6 bg-grayscale50 flex items-center border px-12 py-8'>
								{transformToCurrency(typeof oneTimeDepositField === 'number' ? oneTimeDepositField : 0)}
							</Text>
						</div>
						<Slider
							onValueChange={(e) =>
								formContext.setValue('oneTimeDeposit', getDynamicOneTimeDepositStep(oneTimeDepositRange, e[0]))
							}
							defaultValue={[getDefaultValue(oneTimeDepositRange, oneTimeDepositField)]}
							value={[getDefaultValue(oneTimeDepositRange, oneTimeDepositField)]}
							min={0}
							max={oneTimeDepositRange.length - 1}
							step={1}
							disabled={isLoading}
						/>
					</div>

					<div className='flex flex-col gap-y-8'>
						<div className='flex items-center justify-between'>
							<Text variant='body-xs' weight='regular' color='grayscale600' className='flex grow'>
								{t('components.pensionSpecificationsBox.inlayPerMonth')}
							</Text>

							<Text
								variant='body-xs'
								weight='medium'
								color='grayscale300'
								className='border-grayscale200 rounded-6 bg-grayscale50 flex items-center border px-12 py-8'>
								{transformToCurrency(typeof depositPerMonthField === 'number' ? depositPerMonthField : 0)}
							</Text>
						</div>
						<Slider
							onValueChange={(e) => {
								formContext.setValue('depositPerMonth', getDynamicOneTimeDepositStep(depositPerMonthRange, e[0]));
							}}
							defaultValue={[getDefaultValue(depositPerMonthRange, depositPerMonthField)]}
							value={[getDefaultValue(depositPerMonthRange, depositPerMonthField)]}
							min={0}
							max={depositPerMonthRange.length - 1}
							step={1}
							disabled={isLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
