import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

import { InsuredBoxProps } from '@monorepo/components/DataDisplay';
import { SelectToggleButton } from '@monorepo/components/DataEntry';
import { findElementByIdentifier } from '@monorepo/shared/lib/cms';
import {
	BillingUnits,
	CalculationPrecondition,
	CalculationProductRenderType,
	CmsContainers,
	CmsElement,
} from '@monorepo/types';

import { mapContentToComponent } from '@cms/componentMapper/componentMapper';
import { PreconditionBox } from '@common/components/PreconditionBox/PreconditionBox';
import { ProductCardHeader } from '../ProductCardHeader/ProductCardHeader';

type LargeSelectableProductCardProps = {
	isActive: boolean;
	name: string;
	icon: string;
	isProductActive: boolean;
	guid: string;
	render_type: CalculationProductRenderType;
	preconditions?: CalculationPrecondition[];
	price: number;
	deductible: number | undefined;
	runningPromotion: string | boolean | null | undefined;
	content?: CmsElement[];
	isQuote?: boolean;
	form?: ReactNode;
	isLoading?: boolean;
	isCalculating?: boolean;
	billingUnit?: BillingUnits;
	onCtaClick?: () => void;
	onMoreInfoClick: (guid: string, container: CmsContainers) => void;
	onEditPrecondition?: () => void;
};

/**
 * This is the large version of the SelectableProductCard.
 * Note: don't use it as an isolated component
 */
export const LargeSelectableProductCard: FC<LargeSelectableProductCardProps> = ({
	isActive,
	name,
	icon,
	isProductActive,
	guid,
	render_type,
	preconditions = [],
	price,
	deductible,
	runningPromotion,
	content,
	form,
	isLoading,
	isCalculating,
	billingUnit = 'monthly',
	onCtaClick,
	onMoreInfoClick,
	onEditPrecondition,
}) => {
	const { t } = useTranslation(['common']);

	if (content === null) return <></>;

	const insuredBoxContent = findElementByIdentifier(content || [], 'lists_insured-box');
	const InsuredBox = mapContentToComponent<InsuredBoxProps>(insuredBoxContent as CmsElement);
	const ctaTitle = `${name} ${t(isActive ? 'common.selected' : 'common.select')}`;

	return (
		<div className='w-full'>
			<ProductCardHeader
				productName={name}
				productIcon={icon}
				productIsActive={isProductActive}
				billingUnit={billingUnit}
				content={content}
				runningPromotion={runningPromotion}
				price={price}
				deductible={deductible ? +deductible : undefined}
			/>

			<InsuredBox onMoreInfoClick={() => onMoreInfoClick(guid, 'product_details')} className='mt-20 md:mt-24' />

			{form && (
				<AnimatePresence initial={false}>
					{isActive && form && (
						<motion.div
							style={{ overflow: 'hidden' }}
							initial={{ height: 0 }}
							animate={{ height: 'auto' }}
							transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
							exit={{ height: 0 }}
							key={`form-animate-presence-${name}`}
							className='flex flex-col gap-y-32'>
							<div className='mt-24'>{form}</div>
							{render_type === 'default' &&
								onEditPrecondition &&
								preconditions.length > 0 &&
								preconditions.every((answer) => answer) && (
									<PreconditionBox
										preconditions={preconditions}
										onEdit={onEditPrecondition}
										isLoading={isLoading || isCalculating}
									/>
								)}
						</motion.div>
					)}
				</AnimatePresence>
			)}

			{onCtaClick && (
				<SelectToggleButton
					label={isActive ? t('common.selected') : t('common.select')}
					title={ctaTitle}
					selected={isActive}
					className='mt-24 w-full'
					isLoading={isLoading}
					onClick={onCtaClick}
					iconLeft={isActive ? 'check' : '-'}
					data-testid={`insurance-toggle-card-${name}`}
				/>
			)}
		</div>
	);
};
