import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { PHONE_REGEX } from '../utils';
import { addressSchema } from './address.schema';

export const accountSchema = (t: TranslationType) =>
	z
		.object({
			firstname: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			lastname: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
			phone: z.string({ required_error: t('validation.required') }).regex(PHONE_REGEX, t('validation.phone')),
			terms_agreement: z.literal(true, { required_error: t('validation.required') }),
		})
		.merge(addressSchema(t));

export const accountSchemaB2B = (t: TranslationType) =>
	z.object({
		firstname: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		lastname: z.string({ required_error: t('validation.required') }).min(1, t('validation.required')),
		phone: z.string({ required_error: t('validation.required') }).regex(PHONE_REGEX, t('validation.phone')),
	});

export type AccountSchema = z.infer<ReturnType<typeof accountSchema>>;
export type AccountSchemaB2B = z.infer<ReturnType<typeof accountSchemaB2B>>;
