import { match } from 'ts-pattern';

import { transformAnswer } from '@monorepo/shared/lib/utils';
import { ApiResponse, CartProduct, NonTransformedAnswer, Price, Question } from '@monorepo/types';

import { adjustPrice } from '@common/utils/transformers/adjustPrice';

export interface GetRidersRequest {
	cart_guid: string;
}

export interface GetRawGetProductResponse extends Omit<CartProduct, 'questions'> {
	questions: Array<Question<NonTransformedAnswer>>;
}

export type GetRidersResponse = Array<CartProduct>;
export type GetRawRidersResponse = Array<CartProduct<NonTransformedAnswer>>;

export function transformRider(response: ApiResponse<GetRawRidersResponse>): ApiResponse<GetRidersResponse> {
	return {
		...response,
		response: response.response.map((product) => {
			const newProduct: CartProduct = {
				...product,
				object:
					Object.keys(product.object ?? {}).length > 0
						? {
								...product.object,
								specs:
									product?.object?.specs?.flatMap((spec) => ({ ...spec, answer: transformAnswer(spec.answer) })) ?? [],
							}
						: null,

				add_ons: product.add_ons.map((addon) => ({
					...addon,
					price: adjustPrice<Price>(addon.price),
					answer: transformAnswer(addon.answer),
					children: addon.children.map((child) => {
						if (child.type === 'question') {
							return { ...child, answer: transformAnswer(child.answer) };
						}
						return {
							...child,
							answer: {
								input_type: -1,
								value: null,
							},
						};
					}),
				})),
				price: adjustPrice(product.price),
				old_price: adjustPrice(product.old_price),
				questions: product.questions.flatMap((question) =>
					match(question)
						.with({ type: 'question' }, (q) => ({
							...q,
							answer: transformAnswer(q.answer),
						}))
						.with({ type: 'text' }, (q) => ({
							...q,
							answer: {
								input_type: -1,
								value: null,
							},
						}))
						.otherwise(() => [])
				),
			};

			return newProduct;
		}),
	};
}
