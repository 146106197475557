import { PossibleClassification } from '@monorepo/types';

export function getAllClassifications<R>(items: Array<PossibleClassification>): Array<R> {
	let result: Array<R> = [];

	for (const item of items) {
		if (item?.children && item?.children?.length > 0) {
			result = result.concat(getAllClassifications(item.children));
		} else {
			result.push(item as unknown as R);
		}
	}

	return result;
}

export const countSuggestedChildren = (children: Array<PossibleClassification>): number => {
	let count = 0;
	children.forEach((child) => {
		if (child.suggested) {
			count++;
		}
		if (child.children) {
			count += countSuggestedChildren(child.children);
		}
	});
	return count;
};

export const sortClassificationsBasedOnSuggestions = (
	unsortedClassifications: Array<PossibleClassification>
): Array<PossibleClassification> => {
	return [...unsortedClassifications].sort((a, b) => {
		const aSuggestedCount = countSuggestedChildren(a.children);
		const bSuggestedCount = countSuggestedChildren(b.children);
		return bSuggestedCount - aSuggestedCount; // Descending order
	});
};
