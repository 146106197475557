import cn from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { Text } from '../Text/Text';

type PageTitleAndIntroProps = {
	title: string | ReactNode;
	intro?: string | ReactNode;
	className?: string;
};

export const PageTitleAndIntro: FC<PropsWithChildren<PageTitleAndIntroProps>> = ({
	title,
	intro,
	className = '',
	children,
}) => {
	return (
		<div className={cn(className, 'flex w-full flex-row items-center justify-between')}>
			<div className='flex w-full flex-col space-y-8'>
				<Text as='h1' variant='display-5' color='grayscale600' weight='semibold' data-testid='page-title'>
					{title}
				</Text>
				{typeof intro !== 'undefined' && (
					<Text as='p' variant='body-l' color='grayscale400' data-testid='page-description'>
						{intro}
					</Text>
				)}
			</div>

			{children}
		</div>
	);
};
