import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ChoiceCard } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { AgMembershipSchema } from '@monorepo/shared/lib/schemas';
import { slugify } from '@monorepo/shared/lib/utils';
import { QuestionOption, YesNo } from '@monorepo/types';

type AgMembershipFormProps = {
	options: Array<QuestionOption>;
	isLoading?: boolean;
	onSubmit: ({ membership }: AgMembershipSchema) => void;
};

export const AgMembershipForm: FC<AgMembershipFormProps> = ({ options, isLoading, onSubmit }) => {
	const { watch, setValue } = useFormContext<AgMembershipSchema>();
	const chosenMembership = watch('membership');

	const submit = (key: YesNo) => {
		setValue('membership', key, { shouldValidate: true });
		onSubmit({ membership: key });
	};

	return (
		<Form.Context<AgMembershipFormProps>>
			{({}) => {
				return (
					<Form id='ag-membership-form'>
						<Form.Group className='space-y-24'>
							{options?.map(({ value, label }) => {
								const isPicked = value === chosenMembership;
								return (
									<ChoiceCard
										title={label}
										iconName={value === 'J' ? 'check' : 'times'}
										iconColor={value === 'J' ? 'success300' : 'error300'}
										isLoading={isLoading && isPicked}
										disabled={isLoading}
										onClick={() => submit(value as YesNo)}
										checked={isPicked}
										explicitSuccess={isPicked}
										key={`ag-membership-${slugify(value)}`}
										text={label}
									/>
								);
							})}
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};
