import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

export const illnessConsumerSchema = (t: TranslationType) =>
	z.object({
		guid: z.string().optional(),
		explanation: z
			.string({ required_error: t('validation.required') })
			.min(1, { message: t('validation.required') })
			.max(200),
	});

export type IllnessConsumerSchema = z.infer<ReturnType<typeof illnessConsumerSchema>>;
