import cn from 'classnames';
import { ButtonHTMLAttributes, FC, useId } from 'react';
import { Button, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';

import { ButtonSize } from '@monorepo/types';

import { Icon } from '..';
import { buttonClassNames, buttonSizes } from './../../DataEntry';
import { Text } from './../../TextComponents';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
	label: string;
	actions: Array<{
		label: string;
		onPress: () => void;
		kind?: 'default' | 'destructive';
		isLoading?: boolean;
		isDisabled?: boolean;
	}>;
	size?: ButtonSize;
	isLoading?: boolean;
};

export const DropdownButton: FC<Props> = ({ label, className, size = 'md', isLoading, actions }) => {
	const id = useId();

	return (
		<MenuTrigger>
			<Button
				className={cn(
					className,
					buttonClassNames['tertiary'],
					!className?.includes('h-') && !className?.includes('p-') && buttonSizes[size],
					'rounded-6 flex flex-row items-center gap-x-4 whitespace-nowrap border-2 outline-none disabled:cursor-not-allowed'
				)}
				isDisabled={isLoading || actions.every((action) => action.isDisabled)}>
				<Text variant='body-s' weight='semibold' color='primaryMain'>
					{label}
				</Text>

				<Icon name='angle-down' size='md' />
			</Button>
			<Popover
				UNSTABLE_portalContainer={document.querySelector('#root-portal') ?? undefined}
				className='bg-grayscale0 shadow-2 rounded-6 outline-none'>
				<Menu className='py-8 outline-none'>
					{actions.map((action, key) => (
						<MenuItem
							key={`dropdown-button-${id}-${key}`}
							onAction={action.onPress}
							isDisabled={action.isLoading || action.isDisabled}
							className='cursor-pointer px-12 py-8 outline-none data-[disabled]:cursor-not-allowed'>
							<Text
								variant='body-s'
								weight='medium'
								color={action.isDisabled ? 'grayscale300' : action.kind === 'destructive' ? 'error300' : 'primaryMain'}>
								{action.label}
							</Text>
						</MenuItem>
					))}
				</Menu>
			</Popover>
		</MenuTrigger>
	);
};
