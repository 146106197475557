import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'react-use';

import { StaticNotification } from '@monorepo/components/DataDisplay';
import { StandardButton } from '@monorepo/components/DataEntry';
import { Text } from '@monorepo/components/TextComponents';
import {
	depositPerMonthRange,
	oneTimeDepositRange,
	pensionPlanSchema,
	PensionPlanSchema,
} from '@monorepo/shared/lib/schemas';
import { ProductQuestion } from '@monorepo/types';

import { closeQuote } from '@funnel/store';
import { useCalculatePensionForecastMutation, useCalculatePensionMutation } from '@funnel/store/api/vive.api';
import { mapResponseToChartData } from '@funnel/utils';
import { PensionExpectedResultsBox } from '../PensionExpectedResultsBox/PensionExpectedResultsBox';
import { PensionGraph, PensionGraphData } from '../PensionGraph/PensionGraph';
import { PensionSpecificationsBox } from '../PensionSpecificationsBox/PensionSpecificationsBox';

type PensionFormProps = {
	questions: Array<ProductQuestion>;
	productGuid: string;
	isLoading?: boolean;
	className?: string;
	showCancel?: boolean;
	onSubmit: (fields: Array<{ guid: string; value: string }>) => void;
};

export const PensionForm: FC<PensionFormProps> = ({
	questions,
	productGuid,
	className,
	showCancel = true,
	isLoading = false,
	onSubmit,
}) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const [cachedPensionDetails, setCachedPensionDetails] = useState<PensionPlanSchema>();
	const [cachedGraphData, setCachedGraphData] = useState<Array<PensionGraphData>>([]);
	const form = useForm<PensionPlanSchema>({
		mode: 'onBlur',
		resolver: zodResolver(pensionPlanSchema(t)),
		defaultValues: {
			age: 18,
			retirementAge: 65,
			oneTimeDeposit: 0,
			depositPerMonth: 50,
		},
	});

	const [calculatePension, calculatePensionResponse] = useCalculatePensionMutation();
	const [calculatePensionForecast, calculatePensionForecastResponse] = useCalculatePensionForecastMutation();

	const fields = form.watch();

	const handlePensionChange = useCallback(
		async ({ age, depositPerMonth, oneTimeDeposit, retirementAge }: PensionPlanSchema) => {
			const payload = {
				annuityDuration: 20,
				currentAge: age,
				monthlyDeposit: depositPerMonth,
				oneoffDeposit: oneTimeDeposit,
				pensionAge: retirementAge,
				maxYearlyvar: 0.2,
			};

			await calculatePension(payload).unwrap();

			const graphData = await calculatePensionForecast(payload).unwrap();
			setCachedGraphData(mapResponseToChartData(graphData ?? []));
		},
		[calculatePension, calculatePensionForecast]
	);

	const [_] = useDebounce(
		() => {
			const isAtleastOneValueChanged = Object.entries(fields).some(([key, value]) => {
				if (typeof cachedPensionDetails === 'undefined') return true;

				return (
					Object.entries(cachedPensionDetails).find((cachedPensionDetail) => cachedPensionDetail[0] === key)?.[1] !==
					value
				);
			});

			if (pensionPlanSchema(t).safeParse(fields).success && isAtleastOneValueChanged) {
				setCachedPensionDetails(fields);
				handlePensionChange(fields);
			}
		},
		450,
		[fields]
	);

	async function handleSubmit(fields: PensionPlanSchema) {
		const answers: Array<{ guid: string; value: string }> = [];

		const ageQuestion = questions.find((question) => question.type === 'question' && question.reference === 'age');
		if (ageQuestion && ageQuestion.type === 'question') {
			answers.push({ guid: ageQuestion.guid, value: fields.age.toString() });
		}
		const retirementAgeQuestion = questions.find(
			(question) => question.type === 'question' && question.reference === 'retirement-age'
		);
		if (retirementAgeQuestion && retirementAgeQuestion.type === 'question') {
			answers.push({ guid: retirementAgeQuestion.guid, value: fields.retirementAge.toString() });
		}

		const oneTimeDepositQuestion = questions.find(
			(question) => question.type === 'question' && question.reference === 'one-time-deposit'
		);
		if (oneTimeDepositQuestion && oneTimeDepositQuestion.type === 'question') {
			answers.push({ guid: oneTimeDepositQuestion.guid, value: fields.oneTimeDeposit.toString() });
		}

		const monthlyDepositQuestion = questions.find(
			(question) => question.type === 'question' && question.reference === 'monthly-deposit'
		);

		if (monthlyDepositQuestion && monthlyDepositQuestion.type === 'question') {
			answers.push({ guid: monthlyDepositQuestion.guid, value: fields.depositPerMonth.toString() });
		}

		onSubmit(answers);
	}

	useEffect(() => {
		if (questions.length > 0) {
			questions.forEach((question) => {
				if (question.type === 'question') {
					if (question.reference === 'age' && typeof question.answer.value === 'number') {
						form.setValue('age', question.answer.value, { shouldValidate: true });
					}

					if (question.reference === 'retirement-age' && typeof question.answer.value === 'number') {
						form.setValue('retirementAge', question.answer.value, { shouldValidate: true });
					}

					if (question.reference === 'one-time-deposit' && typeof question.answer.value === 'number') {
						form.setValue('oneTimeDeposit', question.answer.value, { shouldValidate: true });
					}

					if (question.reference === 'monthly-deposit' && typeof question.answer.value === 'number') {
						form.setValue('depositPerMonth', question.answer.value, { shouldValidate: true });
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questions]);

	return (
		<form onSubmit={form.handleSubmit(handleSubmit)} className={classNames(className)}>
			<FormProvider {...form}>
				<div className='grid grid-cols-2 gap-24'>
					<div className='col-span-full sm:col-span-1'>
						<PensionSpecificationsBox
							oneTimeDepositRange={oneTimeDepositRange}
							depositPerMonthRange={depositPerMonthRange}
							isLoading={isLoading}
						/>
					</div>
					<div className='col-span-full sm:col-span-1'>
						<PensionExpectedResultsBox
							expectedResult={calculatePensionResponse.data?.expectedValueOnTargetDate ?? 0}
							totalWorth={calculatePensionResponse.data?.monthlyAnnuity ?? 0}
							retirementDate={calculatePensionResponse.data?.targetDate ?? ''}
							totalInlay={calculatePensionResponse.data?.cumulativeDeposits ?? 0}
						/>
					</div>
					<div className='col-span-full'>
						<PensionGraph
							data={cachedGraphData}
							isLoading={calculatePensionResponse.isLoading || calculatePensionForecastResponse.isLoading}
						/>
					</div>
					<div className='col-span-full'>
						<StaticNotification status='info'>
							<Text variant='body-s' color='grayscale500' weight='regular'>
								<Trans
									i18nKey={'page.funnel.compose.products.pensioenplan.informationField'}
									components={{
										bold: <span className='inline-block font-semibold' />,
									}}
								/>
							</Text>
						</StaticNotification>
					</div>
				</div>

				<div
					className={classNames(
						'mt-24 flex w-full flex-col items-center gap-12 sm:flex-row',
						showCancel ? 'justify-between' : 'justify-end'
					)}>
					{showCancel && (
						<StandardButton
							type='button'
							variant='secondary'
							label={t('common.cancel')}
							className='order-2 w-full sm:order-1 sm:w-fit'
							disabled={isLoading || calculatePensionResponse.isLoading || calculatePensionForecastResponse.isLoading}
							onClick={() => dispatch(closeQuote(productGuid))}
						/>
					)}

					<StandardButton
						type='submit'
						variant='cta'
						iconRight='angle-right'
						label={t('common.requestWithoutObligation')}
						className='order-1 w-full sm:order-2 sm:w-fit'
						isLoading={isLoading}
						disabled={
							isLoading ||
							!form.formState.isValid ||
							calculatePensionResponse.isLoading ||
							calculatePensionForecastResponse.isLoading
						}
						data-testid='confirm-quote-Pensioenplan via Vive'
					/>
				</div>
			</FormProvider>
		</form>
	);
};
