import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { DetailHeroLogoSection } from '@marketing/components';

export const handleSectionTrustpilotLogoList = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle = elements?.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const subtext = getProperty('title', titleSubtextEle?.custom_fields ?? []);

	const selectedLogosElement = elements?.find(({ identifier }) => identifier === 'selected-logos') as CmsElement;
	const logosEle: CmsElement[] = selectedLogosElement?.child_elements.filter(({ identifier }) => identifier === 'logo');
	const logos = logosEle?.filter(({ identifier }) => identifier === 'logo').map(({ media }) => media[0]);

	return (props) => <DetailHeroLogoSection {...props} subtext={`${subtext}`} logos={logos} />;
};
