import { useState } from 'react';

import { SendMailSchema } from '@monorepo/shared/lib/schemas';
import { trackEcommerceEvent } from '@monorepo/shared/lib/utils';

import { useGetCartCustomerQuery, usePostCartToEmailMutation } from '@funnel/store';

export const useSendPremium = (cartGuid?: string) => {
	const [email, setEmail] = useState<string>();
	const [isSend, setIsSend] = useState<boolean>(false);
	const { data: customer } = useGetCartCustomerQuery(
		{ cart_guid: cartGuid ?? '' },
		{ skip: typeof cartGuid !== 'string' }
	);

	const [sendCalculation, response] = usePostCartToEmailMutation();

	const handleSend = async (sendDetails: SendMailSchema) => {
		await sendCalculation({ ...sendDetails, cart_guid: cartGuid, visit_url: window.location.href })
			.unwrap()
			.then(() => {
				trackEcommerceEvent('generate_lead', {
					contact: {
						...(customer?.response?.organization?.name &&
							customer.response.organization.name && {
								organizationName: customer.response.organization.name,
							}),
						email: sendDetails?.email,
						firstName: sendDetails?.first_name,
						lastName: sendDetails?.last_name,
					},
				});
				setEmail(sendDetails.email);
				setIsSend(true);
			});
	};

	return {
		email,
		isSend,
		isLoading: response.isLoading,
		onSend: handleSend,
	};
};
