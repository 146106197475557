import { QuestionPageData } from '@monorepo/types';

export const pageMap = new Map<string, QuestionPageData>();
pageMap
	.set('current-insurance', {
		slug: 'current-insurance',
		i18nKey: 'currentInsurance',
	})
	.set('acceptance-statement', {
		slug: 'acceptance-statement',
		i18nKey: 'acceptanceStatement',
	})
	.set('cyber-acceptance-statement', {
		slug: 'cyber-acceptance-statement',
		i18nKey: 'cyberAcceptanceStatement',
	})
	.set('historical-claims', {
		slug: 'historical-claims',
		i18nKey: 'historicalClaims',
	})
	.set('historical-illnesses', {
		slug: 'historical-illnesses',
		i18nKey: 'historicalClaims',
	})
	.set('claims-overview', {
		slug: 'claims-overview',
		i18nKey: 'claimsOverview',
	})
	.set('illnesses-overview', {
		slug: 'illnesses-overview',
		i18nKey: 'claimsOverview',
	})
	.set('closing-statement', {
		slug: 'closing-statement',
		i18nKey: 'closingStatement',
	})
	.set('ubos', {
		slug: 'ubos',
		i18nKey: 'ubos',
	})
	.set('vakgebieden', {
		slug: 'vakgebieden',
		i18nKey: 'workfields',
	})
	.set('activiteiten', {
		slug: 'activiteiten',
		i18nKey: 'activities',
	})
	.set('affinity-group', {
		slug: 'affinity-group',
		i18nKey: 'affinityGroup',
	})
	.set('fte', {
		slug: 'fte',
		i18nKey: 'fte',
	})
	.set('revenue', {
		slug: 'revenue',
		i18nKey: 'revenue',
	})
	.set('employees', {
		slug: 'employees',
		i18nKey: 'employees',
	})
	.set('property-ownership', {
		slug: 'property-ownership',
		i18nKey: 'propertyOwnership',
	})
	.set('ag-membership', {
		slug: 'ag-membership',
		i18nKey: 'agMembership',
	})
	.set('driver-motor-details', {
		slug: 'driver-motor-details',
		i18nKey: 'objectDetails',
	})
	.set('driver-details', {
		slug: 'driver-details',
		i18nKey: 'driverDetails',
	})
	.set('acquiry', {
		slug: 'acquiry',
		i18nKey: 'acquiry',
	})
	.set('ownership', {
		slug: 'ownership',
		i18nKey: 'ownership',
	})
	.set('driver-experience', {
		slug: 'driver-experience',
		i18nKey: 'driverExperience',
	})
	.set('consumer-affinitygroup', {
		slug: 'consumer-affinitygroup',
		i18nKey: 'consumerAffinityGroup',
	})
	.set('pet-characteristics', {
		slug: 'pet-characteristics',
		i18nKey: 'petCharacteristics',
	})
	.set('pet-name', {
		slug: 'pet-name',
		i18nKey: 'petName',
	})
	.set('pet-indoor-outdoor', {
		slug: 'pet-indoor-outdoor',
		i18nKey: 'petIndoorOutdoor',
	})
	.set('pet-location', {
		slug: 'pet-location',
		i18nKey: 'petLocation',
	})
	.set('pet-breed', {
		slug: 'pet-breed',
		i18nKey: 'petBreed',
	})
	.set('pet-size', {
		slug: 'pet-size',
		i18nKey: 'petSize',
	})
	.set('pet-neutered', {
		slug: 'pet-neutered',
		i18nKey: 'petNeutered',
	})
	.set('pet-sterilized', {
		slug: 'pet-sterilized',
		i18nKey: 'petSterilized',
	})
	.set('pet-chipped', {
		slug: 'pet-chipped',
		i18nKey: 'petChipped',
	})
	.set('transfer-service', {
		slug: 'transfer-service',
		i18nKey: 'transferService',
	})
	.set('cancel-current-insurance', {
		slug: 'cancel-current-insurance',
		i18nKey: 'cancelCurrentInsurance',
	})
	.set('current-insurance', {
		slug: 'current-insurance',
		i18nKey: 'currentInsurance',
	})
	.set('closing-statement', {
		slug: 'closing-statement',
		i18nKey: 'closingStatement',
	})
	.set('cancellation-reasons', {
		slug: 'cancellation-reasons',
		i18nKey: 'cancellationReasons',
	})
	.set('expert', {
		slug: 'expert',
		i18nKey: 'expert',
	})
	.set('uitloopdekking', {
		slug: 'uitloopdekking',
		i18nKey: 'uitloopdekking',
	})
	.set('cancellation_effective_date', {
		slug: 'cancellation_effective_date',
		i18nKey: 'cancellationEffectiveDate',
	})
	.set('object-verkocht', {
		slug: 'object-verkocht',
		i18nKey: 'objectVerkocht',
	})
	.set('vrijwaringsbewijs', {
		slug: 'vrijwaringsbewijs',
		i18nKey: 'vrijwaringsbewijs',
	})
	.set('object-ownership', {
		slug: 'object-ownership',
		i18nKey: 'objectOwnership',
	})
	.set('camper-ownership', {
		slug: 'camper-ownership',
		i18nKey: 'camperOwnership',
	})
	.set('camper-object-details', {
		slug: 'camper-object-details',
		i18nKey: 'camperObjectDetails',
	})
	.set('camper-damage-free-year-km-year', {
		slug: 'camper-damage-free-year-km-year',
		i18nKey: 'camperDamageFreeYearKmYear',
	})
	.set('camper-main-driver', {
		slug: 'camper-main-driver',
		i18nKey: 'camperMainDriver',
	})
	.set('no-claim-statement', {
		slug: 'no-claim-statement',
		i18nKey: 'camperMainDriver',
	});
