import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiResponse, Cart } from '@monorepo/types';

import { AppState } from '@common/store';
import { funnelApi } from '@funnel/store/api/funnel.api';

export type SelectedCartState = {
	cart: Cart;
	isCartDisabled: boolean;
};

// Initial state
const initialState: SelectedCartState = {
	cart: {} as Cart,
	isCartDisabled: false,
};

// Actual Slice
export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		// Action to set the cart
		setCart(state, action: PayloadAction<Cart>) {
			state.cart = {
				...state,
				...action.payload,
			};
		},
		updateExpiredCart(state, action: PayloadAction<boolean>) {
			state.cart = {
				...state.cart,
				is_expired: action.payload,
			};
		},
		updateIsCartDisabled(state, action: PayloadAction<boolean>) {
			state.isCartDisabled = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			funnelApi.endpoints.getCart.matchFulfilled,
			(state, action: PayloadAction<ApiResponse<Cart>>) => {
				state.cart = {
					...state.cart,
					...action.payload.response,
				};
			}
		);
	},
});

export const { setCart, updateExpiredCart, updateIsCartDisabled } = cartSlice.actions;

export const selectBillingUnit = (state: AppState) => state.cart.cart.billing_unit;
export const selectCartState = (state: AppState) => state.cart.cart;

export const selectIsCartConverted = (state: AppState) => state.cart.cart.converted;
export const selectIsHighRoller = (state: AppState) => state.cart.cart['high_roller'];
export const selectIsCartDisabled = (state: AppState) => state.cart.isCartDisabled;
export const selectCartIsExpired = (state: AppState) => state.cart.cart.is_expired;
export const selectCartContactType = (state: AppState) => state.cart.cart.contact_type;
export const selectIsExistingUser = (state: AppState) => state.cart.cart.existing_customer;
export const selectCartCalculationTypes = (state: AppState) => state.cart.cart.calculation_types;
