export type CmsMedia = {
	path: string;
	alt: string;
	device_variant?: string | null;
	media_type?: 'video' | 'image' | 'document' | null;
};

export type CmsWebpage = {
	author: string;
	guid: string;
	identifier: string;
	publish_date: string;
	title: string;
	description: string;
	containers: CmsContainer[];
	path?: string;
};

export type CmsContainer = {
	elements: CmsElement[];
	guid?: string;
	identifier: string;
	sequence?: number;
};

export type CmsElement = {
	child_elements: CmsElement[];
	custom_fields: CmsProperty[];
	guid?: string;
	id?: number;
	identifier: string;
	markdown: null | string;
	links: CmsLink[];
	sequence?: number;
	media: CmsMedia[];
};

export type CmsLink = {
	content: string | null;
	href: string;
	target?: string;
	title: string;
};

export type CmsProperty = {
	key: string;
	value: string;
};

export type CmsContentTypes =
	| 'bundle'
	| 'product'
	| 'composition'
	| 'insured_activity'
	| 'webpage'
	| 'container'
	| 'element'
	| 'addon';

const cmsBundleContainers = ['bundle_details'] as const;
type CmsBundleContainers = (typeof cmsBundleContainers)[number];

// All available product containers to fetch from Novulo CMS
const cmsProductContainers = ['product_intro', 'product_details', 'product'] as const;
type CmsProductContainers = (typeof cmsProductContainers)[number];

const cmsArticleContainers = ['article_header', 'article_content'] as const;
type CmsArticleContainers = (typeof cmsArticleContainers)[number];

const cmsStandaloneComponents = ['webpage', 'container', 'element'] as const;
type CmsStandaloneComponents = (typeof cmsStandaloneComponents)[number];

const cmsDynamicData = ['dynamic_bundles', 'dynamic_products'] as const;
type CmsDynamicData = (typeof cmsDynamicData)[number];

const cmsFaqContainers = ['cms_faqs', 'cms_faq_categories'] as const;
type CmsFaqContainers = (typeof cmsFaqContainers)[number];

const cmsWebContainers = ['containers', 'sitemap', 'globals'] as const;
type CmsWebContainers = (typeof cmsWebContainers)[number];

const cmsCompositionContainers = ['composition_intro', 'composition_details', 'insured_activity_details'] as const;
type CmsCompositionContainers = (typeof cmsCompositionContainers)[number];

// All available containers to fetch from Novulo CMS
export type CmsContainers =
	| CmsBundleContainers
	| CmsProductContainers
	| CmsArticleContainers
	| CmsStandaloneComponents
	| CmsDynamicData
	| CmsFaqContainers
	| CmsWebContainers
	| CmsCompositionContainers;

export const cmsContainers: CmsContainers[] = [
	...cmsBundleContainers,
	...cmsProductContainers,
	...cmsArticleContainers,
	...cmsStandaloneComponents,
	...cmsDynamicData,
	...cmsFaqContainers,
	...cmsWebContainers,
	...cmsCompositionContainers,
];

export type CmsListItem = {
	guid: string;
	identifier: string;
};

export type CmsPreviewComponent = {
	state: Extract<CmsContentTypes, 'webpage' | 'container' | 'element'>;
	identifier: string;
};

export type CmsDynamicProduct = {
	guid: string;
	name: string;
	detailed_description: string;
	product_card_details_guid: string;
	product_card_intro_guid: string;
	icon_name: string;
	minimum_price: number;
	link: CmsLink;
	tag?: string;
};

export type CmsDynamicProductResponse = {
	workfield_guid: string;
	description: string;
	link: CmsLink;
	icon_name: string;
	products: CmsDynamicProduct[];
};

export type CmsDynamicBundle = {
	icon_name: string;
	is_optional: boolean;
	link_guid: string;
	link_text: string;
	name: string;
};

export type CmsDynamicBundleResponse = {
	name: string;
	minimum_price: number;
	workfield_guid: string;
	bundle_info_container_guid: string;
	products: CmsDynamicBundle[];
};

export type CmsFaqItem = {
	category_guid: string;
	content: string;
	icon_name: string;
	title: string;
	type: string;
};

export type CmsFaqCategory = {
	description: string;
	guid: string;
	icon_name: string;
};
