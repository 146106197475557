import { FC } from 'react';

import { DeductibleTag, PriceSticker, TagProps } from '@monorepo/components/DataDisplay';
import { ProductIcon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { findElementByIdentifier } from '@monorepo/shared/lib/cms';
import { BillingUnits, CmsElement } from '@monorepo/types';

import { mapContentToComponent } from '@cms/componentMapper/componentMapper';

type Props = {
	productName: string;
	productIcon: string;
	productIsActive: boolean;
	content?: CmsElement[];
	runningPromotion?: string | boolean | null;
	price: number | null;
	deductible?: number;
	billingUnit?: BillingUnits;
	isQuote?: boolean;
};

export const ProductCardHeader: FC<Props> = ({
	productName,
	productIcon,
	productIsActive,
	content,
	runningPromotion,
	price,
	billingUnit = 'monthly',
	isQuote = false,
	deductible,
}) => {
	const taglineContent = findElementByIdentifier(content || [], 'product_tagline') || {};
	const Tagline = mapContentToComponent(taglineContent as CmsElement);
	const tagContent = findElementByIdentifier(content || [], 'tags_tag') || {};
	const Tag = mapContentToComponent<TagProps>(tagContent as CmsElement);

	return (
		<div className='flex flex-col space-y-12'>
			<div className='flex flex-col justify-between max-sm:space-y-16 sm:flex-row sm:space-x-12'>
				<div className='flex grow items-center space-x-16'>
					<ProductIcon name={productIcon} />

					<div className='space-y-4 overflow-auto'>
						<Text variant='display-6' weight='semibold' color='grayscale600' className='hyphens-auto'>
							{productName}
						</Text>

						{taglineContent && <Tagline />}
					</div>
				</div>

				{typeof price === 'number' && (
					<div className='hidden flex-wrap items-stretch gap-12 sm:flex sm:flex-col sm:items-end'>
						<PriceStickerWrapper
							price={price}
							productIsActive={productIsActive}
							runningPromotion={runningPromotion}
							billingUnit={billingUnit}
							isQuote={isQuote}
						/>
					</div>
				)}
			</div>

			<div className='flex flex-row gap-8'>
				{typeof price === 'number' && (
					<div className='sm:hidden'>
						<PriceStickerWrapper
							price={price}
							productIsActive={productIsActive}
							runningPromotion={runningPromotion}
							billingUnit={billingUnit}
							isQuote={isQuote}
						/>
					</div>
				)}
				{tagContent && <Tag />}
				{deductible && <DeductibleTag deductible={deductible} className='max-sm:hidden' />}
			</div>
		</div>
	);
};

type PriceStickerWrapperProps = {
	price: number;
	productIsActive: boolean;
	runningPromotion?: string | boolean | null;
	billingUnit?: BillingUnits;
	isQuote?: boolean;
};

const PriceStickerWrapper: FC<PriceStickerWrapperProps> = ({
	price,
	productIsActive,
	runningPromotion,
	billingUnit,
	isQuote,
}) => {
	return runningPromotion === 'first_year' ? (
		<PriceSticker billingUnit={billingUnit} variant='free' size='large' price={price} />
	) : !isQuote || (isQuote && price > 0) ? (
		<div>
			<PriceSticker
				className='sm:hidden'
				billingUnit={billingUnit}
				size={'small'}
				variant='default'
				excludeFrom={productIsActive}
				price={price}
			/>
			<PriceSticker
				className='hidden sm:block'
				billingUnit={billingUnit}
				size={'large'}
				variant='default'
				excludeFrom={productIsActive}
				price={price}
			/>
		</div>
	) : null;
};
