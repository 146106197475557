import { skipToken } from '@reduxjs/toolkit/dist/query';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState, type FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Form, FormBaseInputProps } from '@monorepo/components/DataEntry';
import { LookupSuggestion } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { AddressSchema } from '@monorepo/shared/lib/schemas';
import { PostalCodeLookupRequest } from '@monorepo/types';

import { useLookupQuery } from '@common/store';

type AddressLookupProps = {
	fieldSize?: FormBaseInputProps['fieldSize'];
	labelFontWeight?: 'medium' | 'light';
	isDisabled?: boolean;
};

export const AddressLookup: FC<AddressLookupProps> = ({
	fieldSize = 'md',
	labelFontWeight = 'light',
	isDisabled = false,
}) => {
	const { t } = useTranslation(['common']);
	const {
		register,
		getValues,
		setValue,
		formState: { errors },
		trigger,
	} = useFormContext<AddressSchema>();

	const [isEdit, setIsEdit] = useState<boolean>(false);

	const [request, setRequest] = useState<PostalCodeLookupRequest>();
	const { data, isFetching, isError, isSuccess, isUninitialized } = useLookupQuery(request ?? skipToken);

	const handleOnBlur = () => {
		const { postal_code, house_number } = getValues();

		if (postal_code && house_number) {
			setRequest({ postal_code: postal_code.replaceAll(' ', ''), house_number });
		}
	};

	useEffect(() => {
		if (data?.street) {
			setValue('street', `${data?.street}`);
		}

		if (data?.city) {
			setValue('city', `${data?.city}`);
		}

		if (isError) {
			setValue('street', '');
			setValue('city', '');
		}

		setIsEdit(false);

		if (!isError && !isUninitialized) {
			trigger(['city', 'street', 'house_number', 'house_number_appendix', 'postal_code']);
		}
	}, [data, isError, isUninitialized, setValue, trigger]);

	return (
		<Form.Group className='col-span-full grid grid-cols-4 gap-x-24 gap-y-32 lg:gap-x-20'>
			<Form.Group className='col-span-full md:col-span-2' isRendered={false}>
				<Form.HiddenInput name='guid' errors={errors.postal_code} register={register} disabled={isDisabled} />
			</Form.Group>

			<Form.Group className='col-span-full md:col-span-2'>
				<Form.Label className={cn(labelFontWeight === 'medium' && 'font-medium')}>{t('common.postalCode')}</Form.Label>
				<Form.TextInput
					fieldSize={fieldSize}
					autoComplete='postal-code'
					name='postal_code'
					placeholder={t('common.postalCode')}
					errors={errors.postal_code}
					register={register}
					rules={{ onBlur: handleOnBlur }}
					data-testid='address-lookup-form-postal_code'
					disabled={isDisabled}
				/>
			</Form.Group>

			<Form.Group className='col-span-2 md:col-span-1'>
				<Form.Label className={cn(labelFontWeight === 'medium' && 'font-medium')}>{t('common.houseNumber')}</Form.Label>
				<Form.NumberInput
					fieldSize={fieldSize}
					name='house_number'
					autoComplete='off'
					placeholder={t('common.houseNumber')}
					errors={errors.house_number}
					register={register}
					rules={{ onBlur: handleOnBlur }}
					data-testid='address-lookup-form-house_number'
					disabled={isDisabled}
				/>
			</Form.Group>

			<Form.Group className='col-span-2 md:col-span-1'>
				<Form.Label className={cn(labelFontWeight === 'medium' && 'font-medium')}>
					{t('common.houseNumberAddition')}
				</Form.Label>
				<Form.TextInput
					fieldSize={fieldSize}
					name='house_number_appendix'
					autoComplete='off'
					placeholder={t('common.houseNumberAddition')}
					errors={errors.house_number_appendix}
					register={register}
					rules={{ onBlur: handleOnBlur }}
					data-testid='address-lookup-form-house_number_appendix'
					disabled={isDisabled}
				/>
			</Form.Group>

			{(isError || isEdit) && !isDisabled && (
				<>
					{isError && (
						<div className='col-span-full'>
							<Text>{t('page.funnel.company.change.suggestionNotFound')}</Text>
						</div>
					)}
					<Form.Group className='col-span-full'>
						<Form.Label className={cn(labelFontWeight === 'medium' && 'font-medium')}>{t('common.street')}</Form.Label>
						<Form.TextInput
							fieldSize={fieldSize}
							autoComplete='street-address'
							name='street'
							placeholder={t('common.street')}
							errors={errors.street}
							register={register}
							disabled={isDisabled}
							autoFocus
						/>
					</Form.Group>

					<Form.Group className='col-span-full'>
						<Form.Label className={cn(labelFontWeight === 'medium' && 'font-medium')}>{t('common.city')}</Form.Label>
						<Form.TextInput
							fieldSize={fieldSize}
							autoComplete='address-level2'
							name='city'
							placeholder={t('common.city')}
							errors={errors.city}
							register={register}
							disabled={isDisabled}
						/>
					</Form.Group>
				</>
			)}
			{isSuccess && data && !isEdit && (
				<div className='col-span-full'>
					<LookupSuggestion
						city={data.city}
						number={data.number}
						postcode={data.postcode}
						street={data.street}
						onEdit={() => setIsEdit(true)}
						addition={getValues().house_number_appendix}
						isLoading={isFetching}
					/>
				</div>
			)}
		</Form.Group>
	);
};
