import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'ts-pattern';

import { CancelCurrentInsuranceSchema } from '@monorepo/shared/lib/schemas';
import { FunnelOrigin } from '@monorepo/types';

import { hideModal, showModal } from '@common/store';
import {
	useDeleteCurrentInsuranceByGuidMutation,
	useGetCurrentInsurancesQuery,
	useGetTransferableProductsQuery,
	usePostCurrentInsurancesMutation,
	usePutCurrentInsuranceByGuidMutation,
} from '@funnel/store';
import {
	useDeletePortalCurrentInsurancesByGuidMutation,
	useDeletePortalCurrentInsurancesMutation,
	useGetPortalCurrentInsurancesQuery,
	useGetUpsellTransferableProductsQuery,
	usePostPortalCurrentInsurancesMutation,
	usePutPortalCurrentInsurancesByGuidMutation,
} from '@portal/store';

export const useCancelInsurance = (cart_guid: string, origin?: FunnelOrigin) => {
	const [selectedInsurance, setSelectedInsurance] = useState<CancelCurrentInsuranceSchema>();

	const dispatch = useDispatch();

	const postCurrentInsurancesMutation = match(origin)
		.with('upsell', () => usePostPortalCurrentInsurancesMutation)
		.otherwise(() => usePostCurrentInsurancesMutation);

	const [saveCurrentInsurances, saveCurrentInsurancesResponse] = postCurrentInsurancesMutation();

	const deleteCurrentInsuranceByGuid = match(origin)
		.with('upsell', () => useDeletePortalCurrentInsurancesByGuidMutation)
		.otherwise(() => useDeleteCurrentInsuranceByGuidMutation);

	const [deleteInsurance, saveDeleteInsuranceResponse] = deleteCurrentInsuranceByGuid();

	const putCurrentInsuranceByGuid = match(origin)
		.with('upsell', () => usePutPortalCurrentInsurancesByGuidMutation)
		.otherwise(() => usePutCurrentInsuranceByGuidMutation);
	const [updateInsurance, saveUpdateInsuranceResponse] = putCurrentInsuranceByGuid();

	const getCurrentInsurances = match(origin)
		.with('upsell', () => useGetPortalCurrentInsurancesQuery)
		.otherwise(() => useGetCurrentInsurancesQuery);

	const { data: insurances } = getCurrentInsurances({ cart_guid });

	const getTransferableProducts = match(origin)
		.with('upsell', () => useGetUpsellTransferableProductsQuery)
		.otherwise(() => useGetTransferableProductsQuery);

	const { data: transferableProducts } = getTransferableProducts({ cart_guid });

	const isLoading =
		saveCurrentInsurancesResponse.isLoading ||
		saveDeleteInsuranceResponse.isLoading ||
		saveUpdateInsuranceResponse.isLoading;

	/**
	 * Open the modal to add a new insurance
	 */
	const handleAdd = () => {
		setSelectedInsurance(undefined);
		dispatch(showModal('add-or-edit-insurance-modal'));
	};

	/**
	 * Open the edit modal that is prefilled with the clicked insurance fields
	 */
	const handleEdit = (insurance: CancelCurrentInsuranceSchema) => {
		setSelectedInsurance(insurance);
		dispatch(showModal('add-or-edit-insurance-modal'));
	};

	/**
	 * Cancels the changes made to an insurance
	 */
	const handleCancelEdit = () => {
		setSelectedInsurance(undefined);
		dispatch(hideModal('add-or-edit-insurance-modal'));
	};

	/**
	 * Open the prompt to delete the insurance
	 */
	const handleDeleteRequest = (insurance: CancelCurrentInsuranceSchema) => {
		setSelectedInsurance(insurance);
		dispatch(showModal('delete-insurance-prompt'));
	};

	/**
	 * Close the prompt to delete the insurance
	 */
	const handleCancelDelete = () => {
		setSelectedInsurance(undefined);
		dispatch(hideModal('delete-insurance-prompt'));
	};

	/**
	 * Deletes an insurance
	 */
	const handleDelete = async () => {
		await deleteInsurance({ cart_guid, guid: selectedInsurance?.guid ?? '' }).unwrap();
		dispatch(hideModal('delete-insurance-prompt'));
	};

	/**
	 * Saves a new insurance or updates an existing insurance
	 */
	const handleSubmit = async (insurance: CancelCurrentInsuranceSchema) => {
		if (insurance.guid) {
			await updateInsurance({
				cart_guid,
				policy_identifier: insurance.policy_identifier,
				itc_guid: insurance.guid,
				insurer_code: insurance.insurer_code,
				product_guid:
					transferableProducts?.response.find(
						(transferableProduct) => transferableProduct.guid === insurance.product_guid
					)?.guid ?? '',
			}).unwrap();
		} else {
			await saveCurrentInsurances({
				cart_guid,
				insurances: [
					{
						product_guid: insurance.product_guid,
						insurer_code: insurance.insurer_code,
						policy_identifier: insurance.policy_identifier,
					},
				],
			}).unwrap();
		}
		dispatch(hideModal('add-or-edit-insurance-modal'));
	};

	return {
		insurances: insurances?.response ?? [],
		isLoading,
		selectedInsurance,
		handleAdd,
		handleEdit,
		handleCancelEdit,
		handleDelete,
		handleCancelDelete,
		handleDeleteRequest,
		handleSubmit,
	};
};
