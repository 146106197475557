import { Answer, NonTransformedAnswer, QuestionGroup } from '@monorepo/types';

import { readableAddress } from '../helpers/helpers';
import { transformToCurrency } from '../valuta/valuta';

export function transformAnswer(answer: NonTransformedAnswer): Answer {
	const { input_type, ...fields } = answer;

	// Iterate through all fields except input_type
	for (const key in fields) {
		const value = fields[key];
		if (value !== null && value !== undefined) {
			if (typeof value === 'object') {
				if (!Object.keys(value).length) {
					return { input_type, value: null, readable: null };
				}

				if (Object.entries(value).length > 0) {
					return { input_type, value, readable: mapAnswerValueToHumanReadable(answer, value)?.toString() };
				}
			}

			return { input_type, value, readable: mapAnswerValueToHumanReadable(answer, value)?.toString() };
		}
	}

	return {
		input_type,
		value: null,
		readable: null,
	};
}

function mapAnswerValueToHumanReadable(answer: NonTransformedAnswer, value: Answer['value']): string | null {
	if (answer.input_type === 4 && typeof value !== 'object') {
		return transformToCurrency(value);
	}

	if (answer.input_type === 5 && typeof value !== 'object') {
		if (value === 'J' || value === 'N') {
			return value === 'J' ? 'Ja' : 'Nee';
		}

		return value.toString();
	}

	if (answer.input_type === 9 && typeof value === 'object' && value !== null) {
		return readableAddress(value);
	}

	if (typeof value === 'number' || typeof value === 'string') {
		return value.toString();
	}

	return null;
}

export function transformQuestionGroupAnswers(
	questionGroups: Array<QuestionGroup<NonTransformedAnswer>>
): Array<QuestionGroup<Answer>> {
	return questionGroups.map((questionGroup) => ({
		...questionGroup,
		questions: questionGroup.questions.map((question) => ({
			...question,
			answer: transformAnswer(question.answer),
		})),
	}));
}
