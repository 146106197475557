import { skipToken } from '@reduxjs/toolkit/dist/query';
import cn from 'classnames';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { slugify } from '@monorepo/shared/lib/utils';
import { CmsLink } from '@monorepo/types';

import { useListCmsBundlesQuery, useListCmsProductsQuery } from '@cms/store';
import { DynamicOverviewBundleCard } from '@marketing/components/DynamicOverview/DynamicOverviewBundleCard/DynamicOverviewBundleCard';
import { DynamicOverviewCompositionCard } from '@marketing/components/DynamicOverview/DynamicOverviewCompositionCard/DynamicOverviewCompositionCard';
import { DynamicOverviewProductCard } from '@marketing/components/DynamicOverview/DynamicOverviewProductCard/DynamicOverviewProductCard';

type DynamicOverviewProps = {
	title: string;
	subtext: string | ReactNode;
	link: CmsLink;
	variant: 'bundles' | 'products' | 'compositions';
	path?: string;
	className?: string;
};

export const DynamicOverview: FC<DynamicOverviewProps> = ({ title, subtext, variant, path, link, className }) => {
	const { data: productsData } = useListCmsProductsQuery(variant === 'bundles' ? skipToken : { path });
	const { data: bundlesData } = useListCmsBundlesQuery(variant === 'products' ? skipToken : { path });
	const overviewVariant = path?.includes('landing') ? 'landing' : 'standard';

	// TODO: This should be removed and come from the CMS
	const { pathname } = useRouter();
	// TODO: This should be removed and come from the CMS
	const isConsumerOverview = pathname === '/landing/particulier/motorverzekering';
	const isWa = pathname?.includes('/wa-verzekering');
	const isWaPlus = pathname?.includes('/wa-plus-verzekering');
	const isAllRisk = pathname?.includes('/all-risk-verzekering');

	if (variant === 'products' && (!productsData || productsData.response.length === 0)) {
		return null;
	}

	if (variant === 'bundles' && (!bundlesData || bundlesData.response.length === 0)) {
		return null;
	}

	return (
		<section className={cn(className, 'py-48 md:pt-96')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2 space-y-48'>
					<div
						className={cn(
							overviewVariant === 'landing' ? 'items-start' : 'md:items-center md:text-center',
							'flex flex-col space-y-12 '
						)}>
						<Text as='h2' variant='display-4' color='primaryMain' weight='semibold'>
							{title}
						</Text>
						<Text as='div' variant='body-l' color='grayscale400'>
							{typeof subtext === 'string' ? parse(subtext) : subtext}
						</Text>
					</div>

					{variant === 'compositions' && (
						<div
							className={classNames(
								'gap-48',
								// isConsumerOverview ? 'grid grid-cols-1 md:grid-cols-3' : 'grid grid-cols-1 md:grid-cols-3'
								'flex flex-col md:flex-row md:justify-center'
							)}>
							{(isConsumerOverview || !isWa) && (
								<DynamicOverviewCompositionCard
									className='h-fit w-full md:max-w-[370px]'
									title='WA'
									coverages={[
										{
											icon: 'motor',
											title: 'Schade aan anderen',
										},
									]}
									link={{
										title: 'Meer informatie',
										content: 'Meer informatie',
										href: '/landing/particulier/motorverzekering/wa-verzekering',
									}}
								/>
							)}
							{(isConsumerOverview || !isWaPlus) && (
								<DynamicOverviewCompositionCard
									className='h-fit w-full md:max-w-[370px]'
									title='WA+'
									coverages={[
										{
											icon: 'motor',
											title: 'Schade aan anderen',
										},
										{
											icon: 'archive',
											title: 'Schade door diefstal',
										},
										{
											icon: 'apps',
											title: 'Ruitschade',
										},
										{
											icon: 'fire',
											title: 'Brand- en explosieschade',
										},
									]}
									link={{
										title: 'Meer informatie',
										content: 'Meer informatie',
										href: '/landing/particulier/motorverzekering/wa-plus-verzekering',
									}}
								/>
							)}
							{(isConsumerOverview || !isAllRisk) && (
								<DynamicOverviewCompositionCard
									className='h-fit w-full md:max-w-[370px]'
									title='All risk'
									coverages={[
										{
											icon: 'motor',
											title: 'Schade aan anderen',
										},
										{
											icon: 'archive',
											title: 'Schade door diefstal',
										},
										{
											icon: 'apps',
											title: 'Ruitschade',
										},
										{
											icon: 'fire',
											title: 'Brand- en explosieschade',
										},
										{
											icon: 'band-aid',
											title: 'Schade door eigen toedoen',
										},
										{
											icon: 'ninja',
											title: 'Vandalisme',
										},
									]}
									link={{
										title: 'Meer informatie',
										content: 'Meer informatie',
										href: '/landing/particulier/motorverzekering/all-risk-verzekering',
									}}
								/>
							)}
						</div>
					)}

					{variant === 'bundles' && (
						<div className='grid grid-cols-1 gap-48 md:grid-cols-3'>
							{bundlesData?.response?.map((bundle) => (
								<DynamicOverviewBundleCard link={link} bundle={bundle} key={`dynamic-bundle-${slugify(bundle.name)}`} />
							))}
						</div>
					)}

					{variant === 'products' && productsData && productsData?.response?.length === 1 && (
						<div className='grid grid-cols-1 items-center gap-40 md:grid-cols-2'>
							{productsData?.response?.[0].products?.map((product) => (
								<DynamicOverviewProductCard
									className='h-full'
									key={`dynamic-overview-product-${product.guid}`}
									link={link}
									product={product}
								/>
							))}
						</div>
					)}
				</div>
			</LayoutGrid>
		</section>
	);
};
