import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BaseCard, PriceSticker, TagProps } from '@monorepo/components/DataDisplay';
import { MarketingCtaButton, TextButton } from '@monorepo/components/DataEntry';
import { ProductIcon } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { findElementByIdentifier } from '@monorepo/shared/lib/cms';
import { CmsContainer, CmsDynamicProduct, CmsElement, CmsLink } from '@monorepo/types';

import { mapContentToComponent } from '@cms/componentMapper/componentMapper';
import { useLazyGetContainersQuery } from '@cms/store';
import { showDynamicModal } from '@common/store';
import { formatError, logger } from '@common/utils';

type Props = {
	product: CmsDynamicProduct;
	link: CmsLink;
	className?: string;
};

export const DynamicOverviewProductCard: FC<Props> = ({ product, link, className }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['common']);
	const [content, setContent] = useState<CmsContainer | null>();
	const [getContainer] = useLazyGetContainersQuery();

	const taglineContent = findElementByIdentifier(content?.elements ? content.elements : [], 'product_tagline') || {};
	const Tagline = mapContentToComponent(taglineContent as CmsElement);
	const tagContent = findElementByIdentifier(content?.elements ? content.elements : [], 'tags_tag') || {};
	const Tag = mapContentToComponent<TagProps>(tagContent as CmsElement);

	const fetchContainer = async () => {
		try {
			const response = await getContainer(
				{
					guid: product.product_card_intro_guid,
				},
				true // preferCacheValue
			).unwrap();
			setContent(response.response);
		} catch (error) {
			logger.error('Failed to fetch product container', {
				error: formatError(error),
				modalState: product.product_card_intro_guid,
			});
		}
	};

	useEffect(() => {
		fetchContainer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleMoreInfoClick = (guid: string) => {
		dispatch(
			showDynamicModal({
				id: 'dynamic-content-modal',
				payload: {
					guid,
					type: 'container',
				},
			})
		);
	};

	return (
		<BaseCard className={cn(className, 'pointer-events-none col-span-1 flex-col gap-20 p-24')}>
			<div className='flex flex-row items-center gap-16'>
				<ProductIcon name={product.icon_name} />
				<div className='flex flex-col gap-4'>
					<Text variant='body-l' weight='semibold' color='grayscale600' className='hyphens-auto'>
						{product.name}
					</Text>
					<Tagline />
				</div>
			</div>
			<div className='flex flex-row flex-wrap gap-8'>
				{tagContent && <Tag />}
				<PriceSticker variant='default' size='xs' price={product.minimum_price} />
			</div>

			<div className='flex flex-col items-start gap-4'>
				<Text variant='body-s'>{product.detailed_description}</Text>
				<TextButton
					size='sm'
					className='pointer-events-auto'
					onClick={() => handleMoreInfoClick(product.product_card_details_guid)}
					label={t('common.moreInformation')}
				/>
			</div>

			<MarketingCtaButton className='pointer-events-auto mt-auto' link={link} icon='angle-right' size='sm' />
		</BaseCard>
	);
};
