import { cva, VariantProps } from 'class-variance-authority';

export const button = cva(['flex', 'items-center'], {
	variants: {
		variant: {
			cta: ['bg-success300', 'hover:bg-success200', 'active:bg-success400', 'disabled:bg-grayscale300'],
			primary: ['bg-primaryMain', 'hover:bg-primary400', 'active:bg-primary600', 'disabled:bg-grayscale300'],
			text: ['text-info300', 'hover:text-info400', 'active:text-info500', 'disabled:text-grayscale500'],
			critical: [
				'text-error300',
				'md:text-info300',
				'md:hover:text-error300',
				'active:text-error400',
				'disabled:text-grayscale500',
			],
			error: ['bg-error300', 'hover:bg-error200', 'active:bg-error400', 'disabled:bg-grayscale300'],
			info: ['bg-info300', 'hover:bg-info200', 'active:bg-info400', 'disabled:bg-grayscale300'],
			succes: ['bg-success300', 'hover:bg-success200', 'active:bg-success400', 'disabled:bg-grayscale300'],
			warning: ['bg-warning300', 'hover:bg-warning200', 'active:bg-warning400', 'disabled:bg-grayscale300'],
			secondary: [
				'bg-grayscale0',
				'hover:bg-primary50',
				'active:bg-primary100',
				'border-2',
				'border-primary600',
				'rounded-6',
				'text-primaryMain',
				'hover:text-primary400',
				'active:text-primary600',
				'disabled:text-grayscale300',
				'disabled:border-grayscale300',
				'disabled:bg-grayscale0',
			],
			tertiary: [
				'bg-primary50',
				'hover:bg-primary100',
				'active:bg-primary200',
				'disabled:bg-grayscale50',
				'text-primaryMain',
				'disabled:text-grayscale300',
			],
			action: [
				'bg-primary50',
				'hover:bg-primary100',
				'active:bg-primary200',
				'disabled:bg-grayscale50',
				'text-primaryMain',
				'disabled:text-grayscale300',
			],
		},
		size: {
			small: [],
			default: [],
			large: [],
		},
	},
	defaultVariants: {
		size: 'default',
		variant: 'cta',
	},
	compoundVariants: [
		{
			size: 'small',
			variant: ['cta', 'primary', 'error', 'info', 'succes', 'warning', 'tertiary'],
			class: ['p-8', 'rounded-6', 'text-grayscale0'],
		},
		{
			size: 'default',
			variant: ['cta', 'primary', 'error', 'info', 'succes', 'warning', 'tertiary'],
			class: ['p-12', 'rounded-6', 'text-grayscale0'],
		},
		{
			size: 'large',
			variant: ['cta', 'primary', 'error', 'info', 'succes', 'warning', 'tertiary'],
			class: ['p-12', 'rounded-6', 'text-grayscale0'],
		},
		{
			size: 'small',
			variant: 'secondary',
			class: ['p-8'],
		},
		{
			size: 'default',
			variant: 'secondary',
			class: ['p-12'],
		},
		{
			size: 'large',
			variant: 'secondary',
			class: ['p-12'],
		},
		{
			size: 'small',
			variant: 'text',
			class: 'p-4',
		},
		{
			size: 'default',
			variant: 'text',
			class: 'p-6',
		},
		{
			size: 'large',
			variant: 'text',
			class: 'p-8',
		},
		{
			size: 'small',
			variant: 'action',
			class: ['rounded-6', 'text-[12px]'],
		},
		{
			size: 'default',
			variant: 'action',
			class: ['rounded-12', 'text-[24px]', 'p-4'],
		},
		{
			size: 'large',
			variant: 'action',
			class: ['rounded-12', 'text-[24px]', 'p-8'],
		},
	],
});

export const iconVariants = cva(['text-grayscale0'], {
	variants: {
		variant: {
			cta: ['text-currentColor'],
			text: ['text-currentColor'],
			primary: ['text-currentColor'],
			error: ['text-currentColor'],
			critical: ['text-currentColor'],
			info: ['text-currentColor'],
			succes: ['text-currentColor'],
			warning: ['text-currentColor'],
			secondary: ['text-currentColor'],
			tertiary: ['text-currentColor'],
			action: ['text-currentColor'],
		},
		size: {
			small: ['text-[20px]'],
			default: ['text-[24px]'],
			large: ['text-[24px]'],
		},
	},
	defaultVariants: {
		size: 'default',
	},
	compoundVariants: [
		{
			size: 'small',
			variant: ['cta', 'primary', 'tertiary'],
			class: 'text-[20px]',
		},
		{
			size: 'default',
			variant: ['cta', 'primary', 'tertiary'],
			class: 'text-[24px]',
		},
		{
			size: 'large',
			variant: ['cta', 'primary', 'tertiary'],
			class: 'text-[24px]',
		},
		{
			size: 'small',
			variant: 'text',
			class: 'text-[16px]',
		},
		{
			size: 'default',
			variant: 'text',
			class: 'text-[20px]',
		},
		{
			size: 'large',
			variant: 'text',
			class: 'text-[24px]',
		},
	],
});

export type ButtonVariantVariants = VariantProps<typeof button>['variant'];
export type ButtonSizeVariants = VariantProps<typeof button>['size'];
