import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@common/store';

export type LoadingProductsState = {
	products: Record<string, boolean>;
};

// Initial state
const initialState: LoadingProductsState = {
	products: {},
};

// Actual Slice
export const loadingProductsSlice = createSlice({
	name: 'loadingProducts',
	initialState,
	reducers: {
		updateIsProductLoading(state, action: PayloadAction<{ guid: string; isLoading: boolean }>) {
			state.products[action.payload.guid] = action.payload.isLoading;
		},
	},
});

export const { updateIsProductLoading } = loadingProductsSlice.actions;

export const selectProductIsLoading = (state: AppState) => state.loadingProducts.products;
export const selectIsAnyProductLoading = (state: AppState) => {
	return Object.values(state.loadingProducts.products).some((value) => value);
};
