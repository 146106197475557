import { FC } from 'react';

import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsElement, DetailInfoItem } from '@monorepo/types';

import { HandlerPropsV2 } from '@cms/componentMapper/componentMapper';
import { DetailInfoSection } from '@marketing/components';

export const handleSectionInsuranceCoverages = <P extends object>({ elements }: HandlerPropsV2): FC<P> => {
	const titleSubtextEle = elements.find(({ identifier }) => identifier === 'title-subtext') as CmsElement;
	const coveragesEle = elements.find(({ identifier }) => identifier === 'coverages') as CmsElement;

	const title = getProperty('title', titleSubtextEle.custom_fields);
	const subtext = getProperty('subtext', titleSubtextEle.custom_fields);
	const items: DetailInfoItem[] = coveragesEle.child_elements.map((ele) => {
		const title = getProperty('title', ele.custom_fields);
		const content = getProperty('content', ele.custom_fields);

		let link = null;
		const [image] = ele.media;

		if (ele.links.length > 0) {
			link = ele.links[0];
		}

		return {
			title: `${title}`,
			description: `${content}`,
			image,
			link,
		};
	});

	return (props) => <DetailInfoSection {...props} title={`${title}`} subtext={`${subtext}`} items={items} />;
};
