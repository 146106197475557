import { QuestionGroup, QuestionPosition } from '@monorepo/types';

export function sortQuestionGroups(groups: Array<QuestionGroup>): Array<QuestionGroup> {
	// Determine the dynamic order of positions based on the lowest sequence
	const positionOrderMap: Record<QuestionPosition, number> = groups.reduce(
		(map, group) => {
			if (!map[group.position]) {
				map[group.position] = group.sequence;
			} else {
				map[group.position] = Math.min(map[group.position], group.sequence);
			}
			return map;
		},
		{} as Record<QuestionPosition, number>
	);

	// Create a sorted array of positions based on the lowest sequence
	const dynamicPositionOrder: Array<string> = Object.entries(positionOrderMap)
		.sort(([, seqA], [, seqB]) => seqA - seqB)
		.map(([position]) => position);

	// Helper function to get the priority of a position dynamically
	const getPositionPriority = (position: QuestionPosition) => dynamicPositionOrder.indexOf(position);

	// Sort the groups using the dynamic position priority and sequence
	return groups
		.slice() // Clone the array to avoid mutating the input
		.sort((a, b) => {
			// Sort by position priority first
			const positionPriorityDiff = getPositionPriority(a.position) - getPositionPriority(b.position);
			if (positionPriorityDiff !== 0) return positionPriorityDiff;

			// If positions are the same, sort by sequence
			return a.sequence - b.sequence;
		});
}
