import { CookieValueTypes, getCookie } from 'cookies-next';
import Script from 'next/script';
import { useEffect, type FC } from 'react';

export const GoogleTagManager: FC = () => {
	const gtmId: string | undefined = process?.env?.NEXT_PUBLIC_GTM_ID;
	const gtmServerUrl: string | undefined = process?.env?.NEXT_PUBLIC_GTM_SERVER_URL;
	const consent: CookieValueTypes = getCookie('localConsent');

	useEffect(() => {
		if (consent && typeof gtag !== 'undefined') {
			gtag('consent', 'update', {
				ad_user_data: 'granted',
				ad_personalization: 'granted',
				ad_storage: 'granted',
				analytics_storage: 'granted',
			});
		}
	}, [consent]);

	if (!gtmId || !gtmServerUrl) {
		return null;
	}

	return (
		<Script id='gtag' data-test-id='gtm_script' strategy='afterInteractive'>
			{`
				window.dataLayer = window.dataLayer || [];
				function gtag() { dataLayer.push(arguments); }

				gtag('consent', 'default', {
					'ad_user_data': 'denied',
					'ad_personalization': 'denied',
					'ad_storage': 'denied',
					'analytics_storage': 'denied'
				});

				(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'${gtmServerUrl}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${gtmId}');
      `}
		</Script>
	);
};
