import cn from 'classnames';
import { FC } from 'react';

import { LayoutGrid } from '@monorepo/components/Misc';
import { Text } from '@monorepo/components/TextComponents';
import { slugify } from '@monorepo/shared/lib/utils';
import { CmsLink, IDetailInfoSection } from '@monorepo/types';

import { DetailInfoCard } from './DetailInfoCard/DetailInfoCard';
import { DetailInfoLinks } from './DetailInfoLinks/DetailInfoLinks';

type DetailInfoSectionProps = IDetailInfoSection & {
	className?: string;
};

export const DetailInfoSection: FC<DetailInfoSectionProps> = ({ title, links, subtext, items, className }) => {
	const hasDeepLinks = links && links.length > 0;

	return (
		<section className={cn(className, 'bg-primary50 py-48 md:py-64')}>
			<LayoutGrid layout='marketing'>
				<div className='col-start-2'>
					<div className='flex flex-col space-y-48'>
						<div className={cn(hasDeepLinks ? 'space-y-24' : 'space-y-12', 'flex flex-col')}>
							<Text as='h2' variant='display-4' weight='semibold' color='primaryMain'>
								{title}
							</Text>

							{hasDeepLinks && <DetailInfoLinks links={links} />}

							<Text
								as='p'
								variant={hasDeepLinks ? 'body-l' : 'body-m'}
								color={hasDeepLinks ? 'primaryMain' : 'grayscale400'}>
								{subtext}
							</Text>
						</div>
						<div className='grid grid-cols-1 gap-32 md:grid-cols-3 md:gap-48'>
							{items
								.filter((item) => item.link !== null)
								.map((item, index) => (
									<DetailInfoCard
										key={`insurance-detail-coverage-${slugify(item.title)}-${index}`}
										className='col-span-full md:col-span-1'
										{...item}
										link={item.link as CmsLink}
									/>
								))}
						</div>
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};
