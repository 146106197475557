import cn from 'classnames';
import { FC } from 'react';

import { Text } from '@monorepo/components/TextComponents';
import { ISplashHeaderProps } from '@monorepo/types';

type Props = ISplashHeaderProps & {
	className?: string;
};

export const SplashHeader: FC<Props> = ({ tag, title, subtext, className }) => {
	return (
		<section className={cn(className, 'flex flex-col space-y-12 lg:space-y-20')}>
			<Text as='p' variant='body-l' className='bg-secondary200 rounded-4 w-fit px-16 py-8' weight='semibold'>
				{tag}
			</Text>
			<Text as='h1' variant='display-3' weight='semibold' data-testid='page-title'>
				{title}
			</Text>
			<Text as='p' variant='body-l'>
				{subtext}
			</Text>
		</section>
	);
};
