import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { getCurrentYear } from '../utils';

export const claimsOverviewSchema = (t: TranslationType, minYears: number) =>
	z.object({
		historical_claims: z
			.array(
				z.object({
					guid: z.string().optional(),
					product_guid: z
						.string({ required_error: t('validation.required') })
						.min(1, { message: t('validation.required') }),
					year: z
						.number()
						.min(getCurrentYear() - minYears, t('validation.min', { min: getCurrentYear() - minYears }))
						.max(getCurrentYear(), t('validation.max', { max: getCurrentYear() })),
					amount: z
						.string({ required_error: t('validation.required') })
						.min(0.1, t('validation.required'))
						.transform((value) => parseFloat(value)),
					explanation: z
						.string({ required_error: t('validation.required') })
						.min(1, { message: t('validation.required') })
						.max(200),
				})
			)
			.nonempty(),
	});

export type ClaimsOverviewSchema = z.infer<ReturnType<typeof claimsOverviewSchema>>;

export const claimSchema = (t: TranslationType, minYears: number) =>
	z.object({
		guid: z.string().optional(),
		product_guid: z.string({ required_error: t('validation.required') }).min(1, { message: t('validation.required') }),
		year: z
			.number()
			.min(getCurrentYear() - minYears, t('validation.min', { min: getCurrentYear() - minYears }))
			.max(getCurrentYear(), t('validation.max', { max: getCurrentYear() })),
		amount: z.coerce
			.string({ required_error: t('validation.required') })
			.min(0.1, t('validation.required'))
			.transform((value) => parseFloat(value)),
		explanation: z
			.string({ required_error: t('validation.required') })
			.min(1, { message: t('validation.required') })
			.max(200),
	});

export type ClaimSchema = z.infer<ReturnType<typeof claimSchema>>;
