import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { match, P } from 'ts-pattern';

import { sortAscending } from '@monorepo/shared/lib/utils';
import { FunnelOrigin } from '@monorepo/types';

import { paths, ROOTS } from '@common/utils';
import { selectObjectType } from '@consumer/store';
import { useProduct } from '@funnel/hooks/useProductsV2/useProducts';
import { useGetQuestionsQuery } from '@funnel/store';
import { useGetUpsellQuestionsQuery } from '@portal/store';

export function usePostConfigureNavigation({
	cartGuid,
	origin = 'funnel',
}: {
	cartGuid?: string;
	origin?: FunnelOrigin;
}) {
	const { cart, products } = useProduct({ cartGuid, origin });
	const getQuestionsQuery = match(origin)
		.with('upsell', () => useGetUpsellQuestionsQuery)
		.otherwise(() => useGetQuestionsQuery);
	const { data } = getQuestionsQuery(
		{ cart_guid: cartGuid as string, filterByPosition: 'postconfigure' },
		{ skip: typeof cartGuid !== 'string' }
	);
	const router = useRouter();

	const currentPath = router.pathname;

	const FunnelRoot = router.asPath.includes(ROOTS.PORTAL)
		? `${paths.portal.insurances.new.root}`
		: router.asPath.includes(ROOTS.CONSUMER)
			? `${ROOTS.CONSUMER}${ROOTS.FUNNEL}`
			: `${ROOTS.FUNNEL}`;

	const addonPaths: Array<string> = [`${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_ADDONS}`];
	const postconfigurePaths: Array<string> = [
		...addonPaths,
		`${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_CONFIGURE}`,
	];
	const riderPaths: Array<string> = [
		...postconfigurePaths,
		`${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_RIDER_PRODUCTS}`,
	];
	const bumpOfferPaths: Array<string> = [
		...riderPaths,
		`${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_EXTRA_PRODUCTS}`,
	];
	const hasAddOns = products?.some((product) => !!product.add_ons.length);
	const objectType = useSelector(selectObjectType);
	const hasPostconfigureQuestions = !data?.response.questions.length && !postconfigurePaths.includes(currentPath);
	const hasRiders = (cart?.riders ?? false) && !riderPaths.includes(currentPath);
	const hasBumpOffers = (cart?.['bump-offers'] ?? false) && !bumpOfferPaths.includes(currentPath);
	const hasCompositions = cart?.calculation_types.compositions === true;

	const isOnConfigurePage = currentPath.endsWith(ROOTS.FUNNEL_COMPOSE_CONFIGURE);
	const isOnComposePage = currentPath.endsWith(ROOTS.FUNNEL_COMPOSE);
	const isOnAddonPage = currentPath.endsWith(ROOTS.FUNNEL_COMPOSE_ADDONS);
	const firstPostconfigureQuestion = data?.response.questions
		.filter((q) => q.position === 'postconfigure')
		.sort((a, b) => sortAscending(a.sequence, b.sequence))?.[0];

	const nextRoute = match({
		hasAddOns,
		hasPostconfigureQuestions,
		objectType,
		firstPostconfigureQuestion,
		hasRiders,
		isOnComposePage,
		hasBumpOffers,
		isOnAddonPage,
		isOnConfigurePage,
		hasCompositions,
		origin,
	})
		.with(
			{
				hasAddOns: true,
				isOnAddonPage: true,
			},
			() => `${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_OVERVIEW}`
		)
		.with(
			{
				hasAddOns: true,
				isOnComposePage: true,
				objectType: 'camper',
			},
			() => `${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_OVERVIEW}`
		)
		.with(
			{ hasAddOns: true, origin: 'funnel' },
			() => `${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_ADDONS}`
		)

		.with(
			{ hasPostconfigureQuestions: true },
			() => `${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_CONFIGURE}`
		)
		.with({ hasRiders: true }, () => `${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_RIDER_PRODUCTS}`)
		.with({ hasBumpOffers: true }, () => `${FunnelRoot}${ROOTS.FUNNEL_COMPOSE}${ROOTS.FUNNEL_COMPOSE_EXTRA_PRODUCTS}`)
		.with(
			{
				firstPostconfigureQuestion: P.not(undefined),
			},
			() => `${FunnelRoot}${ROOTS.FUNNEL_PROFILE}/${firstPostconfigureQuestion?.reference}`
		)
		.with({ origin: 'upsell' }, () => paths.portal.insurances.new.acceptance.root)
		.otherwise(() => `${FunnelRoot}${ROOTS.PORTAL_COMPANY_ACCEPTANCE_QUESTIONS}`);

	const navigate = useCallback(() => {
		router.push({
			pathname: nextRoute,
			query: { cartGuid },
		});
	}, [cartGuid, nextRoute, router]);

	return { navigate, path: `${nextRoute}` };
}
