import cn from 'classnames';
import Image from 'next/image';
import { FC } from 'react';

import { MarketingCtaButton } from '@monorepo/components/DataEntry';
import { LayoutGrid } from '@monorepo/components/Misc';
import { CheckList, Text } from '@monorepo/components/TextComponents';
import { generateSizesString } from '@monorepo/shared/lib/utils';
import { CmsMedia, ICtaImageList } from '@monorepo/types';

type CtaImageListSectionProps = (Omit<ICtaImageList, 'image'> & { image: CmsMedia | null }) & { className?: string };

export const CtaImageListSection: FC<CtaImageListSectionProps> = ({ title, items, image, link, className }) => {
	return (
		<section className={cn(className, 'py-48')}>
			<LayoutGrid layout='marketing'>
				<div className={cn('bg-primaryMain rounded-16 col-start-2 flex flex-col md:flex-row')}>
					<div className='rounded-16 relative w-full overflow-hidden max-md:h-[300px] md:h-full md:w-1/2'>
						{image && (
							<Image
								sizes={generateSizesString()}
								src={image.path}
								alt={image.alt}
								fill
								className='rounded-16 object-cover object-top'
							/>
						)}
					</div>
					<div className='flex flex-col space-y-24 p-24 md:space-y-48 md:p-80'>
						<div className='flex flex-col space-y-24'>
							<Text variant='display-4' color='grayscale0' weight='semibold'>
								{title}
							</Text>
							<CheckList color='grayscale0' items={items.map((item) => ({ ...item, included: true }))} />
						</div>
						<MarketingCtaButton link={link} className='md:w-fit' />
					</div>
				</div>
			</LayoutGrid>
		</section>
	);
};
