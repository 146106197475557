export const insuranceOrder: Record<string, number> = {
	'Alles-in-één aansprakelijkheidsverzekering': 0,
	Cyberverzekering: 1,
	Werknemersschadeverzekering: 2,
	Aansprakelijkheidsverzekering: 3,
	'Spullen-in-je-opslagverzekering': 4,
	'Spullen-in-je-busverzekering': 5,
	Ongevallenverzekering: 6,
	'Vangnet voor verweer': 7,
	'Beroeps- en bedrijfsaansprakelijkheid': 8,
	Beroepsaansprakelijkheid: 9,
	Bedrijfsaansprakelijkheid: 10,
	'Inventaris en goederen': 11,
	'Spullen onderweg': 12,
	Werknemersschade: 13,
	Elektronica: 14,
	Omzetverlies: 15,
	'Glas en lichtreclame': 16,
	Geld: 17,
	Gebouw: 18,
	'Rechtsbijstand voor Ondernemers': 19,
	'Motorrijtuig verzekering': 20,
};
