import { useTranslation } from 'next-i18next';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StandardButton } from '@monorepo/components/DataEntry';
import { InsuranceDocuments, Skeleton } from '@monorepo/components/Misc';
import { getProperty } from '@monorepo/shared/lib/cms';
import { CmsContainer, CmsContainers } from '@monorepo/types';

import { componentMapper } from '@cms/componentMapper/componentMapper';
import {
	useLazyAddonContainerQuery,
	useLazyBundleContainerQuery,
	useLazyCompositionContainerQuery,
	useLazyGetContainersQuery,
	useLazyInsuredActivityContainerQuery,
	useLazyProductContainerQuery,
} from '@cms/store';
import { Modal, ModalBodySkeleton } from '@common/components';
import { useNavigation } from '@common/hooks';
import { hideModal, selectModalIdState } from '@common/store';
import { formatError, logger } from '@common/utils';
import { useGetCartDocumentsQuery } from '@funnel/store';

type DynamicContentModalProps = {
	id: string;
};

export const DynamicContentModal: FC<DynamicContentModalProps> = ({ id }) => {
	const { t } = useTranslation(['common']);
	const dispatch = useDispatch();
	const modalState = useSelector(selectModalIdState(id));
	const { isB2BFunnel } = useNavigation();
	const { data: documents } = useGetCartDocumentsQuery(
		{ cart_guid: modalState?.payload?.cartGuid as string },
		{ skip: typeof modalState?.payload?.cartGuid !== 'string' }
	);

	const productDocument = useMemo(() => {
		if (typeof modalState?.payload?.compositionGuid === 'string') {
			return documents?.response.products.find(
				(product) => product.composition?.guid === modalState?.payload?.compositionGuid
			);
		}

		return undefined;
	}, [documents?.response.products, modalState?.payload?.compositionGuid]);

	const [content, setContent] = useState<CmsContainer | null>();
	const [title, setTitle] = useState<string>('');
	const [subTitle, setSubTitle] = useState<string>('');
	const [getProductContainer, productResponse] = useLazyProductContainerQuery();
	const [getBundleContainer, bundleResponse] = useLazyBundleContainerQuery();
	const [getCompositionContainer, compositionResponse] = useLazyCompositionContainerQuery();
	const [getInsuredActivityContainer, insuredActivityResponse] = useLazyInsuredActivityContainerQuery();
	const [getContainer, containerResponse] = useLazyGetContainersQuery();
	const [getAddonContainer, addonResponse] = useLazyAddonContainerQuery();

	const isLoading =
		productResponse.isLoading ||
		bundleResponse.isLoading ||
		containerResponse.isLoading ||
		addonResponse.isLoading ||
		compositionResponse.isLoading ||
		insuredActivityResponse.isLoading;

	const setAllContent = (response: CmsContainer) => {
		setContent(response);

		const detailsTitle = response.elements.find(({ identifier }) => identifier === 'details_title');
		const title = getProperty('title', detailsTitle?.custom_fields || []);
		const subTitle = getProperty('subTitle', detailsTitle?.custom_fields || []);

		setTitle(`${title}`);
		setSubTitle(`${subTitle}`);
	};

	const resetAllContent = () => {
		setContent(null);
		setTitle('');
		setSubTitle('');
	};

	const fetchBundleContainer = async () => {
		try {
			const response = await getBundleContainer(
				{
					bundleGuid: `${modalState?.payload?.guid}`,
					container: `${modalState?.payload?.container}` as CmsContainers,
					cartGuid: `${modalState?.payload?.cartGuid}`,
				},
				true // preferCacheValue
			).unwrap();
			setAllContent(response.response);
		} catch (error) {
			logger.error('Failed to fetch bundle container', {
				error: formatError(error),
				modalState: modalState,
			});
		}
	};

	const fetchProductContainer = async () => {
		try {
			const response = await getProductContainer(
				{
					productGuid: `${modalState?.payload?.guid}`,
					container: `${modalState?.payload?.container}` as CmsContainers,
				},
				true // preferCacheValue
			).unwrap();
			setAllContent(response.response);
		} catch (error) {
			logger.error('Failed to fetch product container', {
				error: formatError(error),
				modalState,
			});
		}
	};

	const fetchCompositionContainer = async () => {
		try {
			const response = await getCompositionContainer(
				{
					compositionGuid: `${modalState?.payload?.guid}`,
					container: `${modalState?.payload?.container}` as CmsContainers,
				},
				true // preferCacheValue
			).unwrap();
			setAllContent(response.response);
		} catch (error) {
			logger.error('Failed to fetch product container', {
				error: formatError(error),
				modalState,
			});
		}
	};

	const fetchAddonContainer = async () => {
		try {
			const response = await getAddonContainer(
				{
					addonGuid: `${modalState?.payload?.guid}`,
					container: `${modalState?.payload?.container}` as CmsContainers,
				},
				true // preferCacheValue
			).unwrap();
			setAllContent(response.response);
		} catch (error) {
			logger.error('Failed to fetch product container', {
				error: formatError(error),
				modalState,
			});
		}
	};

	const fetchInsuredActivityContainer = async () => {
		try {
			const response = await getInsuredActivityContainer(
				{
					compositionGuid: `${modalState?.payload?.parentGuid}`,
					insuredActivityGuid: `${modalState?.payload?.guid}`,
					container: `${modalState?.payload?.container}` as CmsContainers,
				},
				true // preferCacheValue
			).unwrap();
			setAllContent(response);
		} catch (error) {
			logger.error('Failed to fetch product container', {
				error: formatError(error),
				modalState,
			});
		}
	};

	const fetchContainer = async () => {
		try {
			const response = await getContainer(
				{
					guid: `${modalState?.payload?.guid}`,
				},
				true // preferCacheValue
			).unwrap();
			setAllContent(response.response);
		} catch (error) {
			logger.error('Failed to fetch product container', {
				error: formatError(error),
				modalState: modalState,
			});
		}
	};

	useEffect(() => {
		if (modalState?.payload) {
			if (modalState?.payload.type === 'container') {
				fetchContainer();
			} else if (modalState?.payload.type === 'bundle') {
				fetchBundleContainer();
			} else if (modalState?.payload.type === 'product') {
				fetchProductContainer();
			} else if (modalState?.payload.type === 'composition') {
				fetchCompositionContainer();
			} else if (modalState?.payload.type === 'insured_activity') {
				fetchInsuredActivityContainer();
			} else if (modalState?.payload.type === 'addon') {
				fetchAddonContainer();
			}
		}

		return () => resetAllContent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalState?.payload]);

	return (
		<Modal
			id={id}
			icon='comment-info-alt'
			title={title}
			subtitle={subTitle}
			submitButton={
				<StandardButton
					label={t('common.close')}
					variant='secondary'
					iconLeft='times'
					onClick={() => dispatch(hideModal(id))}
				/>
			}>
			<Skeleton containerClassName='space-y-32' variant='custom' skeleton={<ModalBodySkeleton />} isLoading={isLoading}>
				{productDocument && (
					<div className='space-y-16'>
						<InsuranceDocuments productDocument={productDocument} showDocumentName={isB2BFunnel} />
					</div>
				)}
				{content &&
					content?.elements?.length > 0 &&
					componentMapper(content).map((Component, index) => <Component key={`${Component.name}-${index}`} />)}
			</Skeleton>
		</Modal>
	);
};
