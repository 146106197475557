import { z } from 'zod';

import { TranslationType } from '@monorepo/types';

import { D_DATE_FORMAT, formatDate } from '../utils';

export const cancellationEffectiveDateSchema = (t: TranslationType, minDate: Date) => {
	return z.object({
		answer: z
			.date()
			.min(minDate, t('validation.dateMin', { date: formatDate({ date: minDate, dateFormat: D_DATE_FORMAT }) })),
	});
};

export type CancellationEffectiveDateSchema = z.infer<ReturnType<typeof cancellationEffectiveDateSchema>>;
