import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ChoiceCard } from '@monorepo/components/DataDisplay';
import { Form } from '@monorepo/components/DataEntry';
import { AffinityGroupSchema } from '@monorepo/shared/lib/schemas';
import { slugify } from '@monorepo/shared/lib/utils';
import { AffinityGroupOptions, QuestionOption } from '@monorepo/types';

type AffinityGroupFormProps = {
	options: Array<QuestionOption>;
	isLoading?: boolean;
	onSubmit: ({ affinityGroup }: AffinityGroupSchema) => void;
};

export const AffinityGroupForm: FC<AffinityGroupFormProps> = ({ options, isLoading, onSubmit }) => {
	const { watch, setValue } = useFormContext<AffinityGroupSchema>();
	const chosenAffinityGroup = watch('affinityGroup');

	const submit = (key: AffinityGroupOptions) => {
		setValue('affinityGroup', key, { shouldValidate: true });
		onSubmit({ affinityGroup: key });
	};

	return (
		<Form.Context<AffinityGroupSchema>>
			{({}) => {
				return (
					<Form id='property-ownership-form'>
						<Form.Group className='space-y-24'>
							{options?.map(({ value, label }) => (
								<ChoiceCard
									title={label}
									disabled={isLoading}
									isLoading={isLoading && chosenAffinityGroup === value}
									onClick={() => submit(value as AffinityGroupOptions)}
									checked={chosenAffinityGroup === value}
									explicitSuccess={chosenAffinityGroup === value}
									key={`property-ownership-${slugify(value)}`}
									image={value !== 'N' ? `/affinitygroups/${value}/logo/logo-selectbox.svg` : undefined}
									text={label}
									data-testid={`affinity-group-form-${value}`}
								/>
							))}
						</Form.Group>
					</Form>
				);
			}}
		</Form.Context>
	);
};
