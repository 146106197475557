import { AnimatePresence, MotionConfig } from 'framer-motion';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { Lexend } from 'next/font/google';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';

import type { NextPageWithLayout } from '@monorepo/types';

import {
	FirebaseAuthProvider,
	FirebasePerformanceProvider,
	FirebaseProvider,
	FirebaseRemoteConfigProvider,
	NavigationProvider,
} from '@common/providers';
import { wrapper } from '@common/store';
import { isHostnameNKC, seoDefaultConfig } from '@common/utils';

import '../styles/globals.css';

import { Analytics } from '@vercel/analytics/react';
import Head from 'next/head';

import { isMocking } from '@monorepo/shared/lib/utils';

import { ErrorBoundaryFallback, GoogleTagManager } from '@common/components';
import { withDeveloperTools, withTheme } from '@common/hoc';
import { ChatScript } from '@support/components';
import { initMocks } from '@webapp/mocks';

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const lexend = Lexend({
	weight: ['300', '500', '600', '700'],
	preload: true,
	display: 'swap',
	variable: '--font-lexend',
	subsets: ['latin'],
});

const ThemedMaiaApp = withDeveloperTools(
	withTheme(({ Component, ...rest }: AppPropsWithLayout) => {
		// Use the Layouts defined at the page level, if available
		const { props } = wrapper.useWrappedStore(rest);
		const getLayout = Component.getLayout || ((page) => page);

		let MotionComponent = Fragment;
		if (typeof window !== 'undefined') {
			// @ts-ignore - Typescript type issue
			MotionComponent = MotionConfig;
			// @ts-ignore - Typescript type issue
			MotionConfig.reducedMotion = 'user';
		}

		useEffect(() => {
			if (window.location.hostname === 'verzekering.nkc.nl') {
				// Inject GTM <noscript> manually into the body
				const noscript = document.createElement('noscript');
				noscript.id = 'gtag-noscript-nkc';
				noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M4K5BC9F" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
				document.body.insertBefore(noscript, document.body.firstChild);
			}
		}, []);

		return (
			<NavigationProvider>
				<FirebaseProvider>
					<FirebaseAuthProvider>
						<FirebaseRemoteConfigProvider>
							<FirebasePerformanceProvider>
								<MotionComponent>
									<Toaster />
									<DefaultSeo {...seoDefaultConfig} />
									<GoogleTagManager />
									{!isHostnameNKC() && <ChatScript />}
									<Head>
										{/* Google Tag Manager for nkc */}
										{isHostnameNKC() && (
											//  eslint-disable-next-line @next/next/next-script-for-ga
											<script
												dangerouslySetInnerHTML={{
													__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
															new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
															j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
															'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
															})(window,document,'script','dataLayer','GTM-M4K5BC9F');
														`,
												}}
											/>
										)}
										{/* End Google Tag Manager */}
									</Head>

									<AnimatePresence mode='wait'>
										<>{getLayout(<Component {...props.pageProps} />)}</>
									</AnimatePresence>
								</MotionComponent>
							</FirebasePerformanceProvider>
						</FirebaseRemoteConfigProvider>
					</FirebaseAuthProvider>
				</FirebaseProvider>
			</NavigationProvider>
		);
	})
);

const MaiaApp = ({ Component, ...rest }: AppPropsWithLayout) => {
	// Use the Layouts defined at the page level, if available
	const [shouldRender, setShouldRender] = useState(!isMocking);
	const [mocksInitialized, setMocksInitialized] = useState(false);

	const { store } = wrapper.useWrappedStore(rest);
	const { events } = useRouter();

	// Fix for non-scrollable pages in some cases when modal is not closed properly
	useEffect(() => {
		if (events) {
			events?.on('routeChangeComplete', () =>
				document?.querySelector('body.overflow-hidden')?.classList.remove('overflow-hidden')
			);
		}
	}, [events]);

	if (isMocking && !mocksInitialized) {
		initMocks().then(() => {
			setShouldRender(true);
			setMocksInitialized(true);
		});
	}

	if (!shouldRender) {
		return null;
	}

	return (
		<div className={`${lexend.variable} font-sans`} id='root-portal'>
			<ErrorBoundary fallback={<ErrorBoundaryFallback />}>
				<Provider store={store}>
					<Analytics />
					<ThemedMaiaApp Component={Component} {...rest} />
				</Provider>
			</ErrorBoundary>
			{/* Designated location for our modals
				 @see: Modal
			*/}
			<div id='modals' />
		</div>
	);
};

export default appWithTranslation(MaiaApp);
