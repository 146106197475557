import { ReactNode, useEffect, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Address, IInvolvedPerson, IntakeRequest, IPortalAttachment, Products, TranslationType } from '@monorepo/types';

import { Text } from '../../../components/TextComponents';
import { getMessageByQuestionGuid, updateQuestionValuesByGuid } from './utils';

export const INPUT_TYPES = {
	1: 'text',
	2: 'int',
	3: 'float',
	4: 'money',
	5: 'multiple_choice',
	6: 'date',
	7: 'attachments',
	8: 'attachments_optional',
	9: 'addresses',
	10: 'stakeholders',
	11: 'confirmation',
	12: 'attachment-chat',
} as const;

export type IntakeInputTypes = (typeof INPUT_TYPES)[keyof typeof INPUT_TYPES];

export const QUESTIONS: (t: TranslationType) => Array<Question> = (t) => [
	{
		guid: 'claim_description',
		question: (
			<Trans
				i18nKey={'page.portal.claims.intakeQuestions.claim_description'}
				components={{
					bold: <Text as='strong' weight='semibold' />,
					ul: <ul className='pl-24' />,
					li: <li className='list-disc' />,
				}}
			/>
		),
		inputType: INPUT_TYPES[1],
		answer: undefined,
		sequence: -3,
	},
	{
		guid: 'claim_date',
		question: (
			<Trans
				i18nKey={'page.portal.claims.intakeQuestions.claim_date'}
				components={{
					bold: <Text as='strong' weight='semibold' />,
				}}
			/>
		),
		inputType: INPUT_TYPES[6],
		answer: undefined,
		sequence: -2,
	},
	{
		guid: 'claimed_amount',
		question: t('page.portal.claims.intakeQuestions.claimed_amount'),
		inputType: INPUT_TYPES[4],
		answer: undefined,
		sequence: -1,
	},
];

export const CONFIRM_PRODUCT_QUETION = (intake: Partial<IntakeRequest>): Array<Question> => {
	return [
		{
			guid: 'claimed_confirm_product',
			question: !!intake.product_guid ? (
				<Trans
					i18nKey='page.portal.claims.intakeQuestions.claimed_confirm_product'
					components={{
						bold: <Text as='strong' weight='semibold' />,
					}}
					tOptions={{
						insurance: intake.subject,
					}}
				/>
			) : (
				<Trans
					i18nKey='page.portal.claims.intakeQuestions.claimed_confirm_product_not_found'
					components={{
						bold: <Text weight='semibold' />,
					}}
				/>
			),
			inputType: INPUT_TYPES[11],
			inputOptions: !!intake.product_guid
				? [
						{
							key: intake.product_guid,
							value: 'Ja',
						},
						{
							key: 'N',
							value: 'Nee',
						},
					]
				: undefined,
			answer: undefined,
			sequence: 0,
		},
	];
};

export type Question = {
	guid: string;
	question: string | ReactNode;
	sequence: number;
} & QuestionAnswer;

export type QuestionAnswer =
	| {
			inputType: 'multiple_choice';
			answer: string | undefined;
			inputOptions?: Array<{ key: string; value: string }>;
	  }
	| {
			inputType: 'text';
			answer: string | undefined;
	  }
	| {
			inputType: 'int' | 'float' | 'money';
			answer: number | undefined;
	  }
	| {
			inputType: 'date';
			answer: Date | undefined;
	  }
	| {
			inputType: 'attachments' | 'attachments_optional';
			answer:
				| Array<{
						filename: string;
						mimetype: string;
						encoded_document: string;
				  }>
				| undefined;
	  }
	| {
			inputType: 'attachment-chat';
			answer: Array<IPortalAttachment> | undefined;
	  }
	| {
			inputType: 'stakeholders';
			answer: IInvolvedPerson[] | undefined;
	  }
	| {
			inputType: 'addresses';
			answer: Address | undefined;
	  }
	| {
			inputType: 'confirmation';
			answer: string | undefined;
			inputOptions?: Array<{ key: string; value: string }>;
	  };

export type IntakeReducerState = {
	questions: Array<Question>;
	answeredQuestions: Array<Question | undefined>;
	activeQuestion: Question | undefined;
	products: Products | undefined;
	shouldInitiateIntake: boolean;
	initialIntakeQuestionsDone: boolean;
	intakeDone: boolean;
};

export type IntakeReducerAction =
	| {
			type: 'updateQuestions';
			payload: IntakeReducerState['questions'];
	  }
	| {
			type: 'setProducts';
			payload: IntakeReducerState['products'];
	  }
	| {
			type: 'setInitialIntakeQuestionsDone';
			payload: IntakeReducerState['initialIntakeQuestionsDone'];
	  };

export const intakeReducerInitialState: IntakeReducerState = {
	questions: [],
	answeredQuestions: [],
	products: undefined,
	activeQuestion: undefined,
	shouldInitiateIntake: false,
	initialIntakeQuestionsDone: false,
	intakeDone: false,
};

export const intakeReducer = (state: IntakeReducerState, action: IntakeReducerAction): IntakeReducerState => {
	const { type, payload } = action;

	switch (type) {
		case 'updateQuestions': {
			const mergedQuestions = updateQuestionValuesByGuid(state.questions, payload).sort(
				(a, b) => a.sequence - b.sequence
			);
			const shouldInitiateIntake = !!getMessageByQuestionGuid(mergedQuestions, 'claimed_confirm_product')?.answer;
			const intakeDone = state.initialIntakeQuestionsDone && mergedQuestions.every((question) => !!question.answer);

			return {
				...state,
				questions: mergedQuestions,
				answeredQuestions: mergedQuestions.filter(isAnswered),
				activeQuestion: mergedQuestions.find((question) => !isAnswered(question)),
				shouldInitiateIntake,
				intakeDone,
			};
		}

		case 'setInitialIntakeQuestionsDone':
			return {
				...state,
				initialIntakeQuestionsDone: payload,
			};

		case 'setProducts':
			return {
				...state,
				products: payload,
			};
	}
};

type useClaimIntakeProps = {
	skipInitialQuestions?: boolean;
};

export function useClaimIntake({ skipInitialQuestions = false }: useClaimIntakeProps) {
	const { t } = useTranslation(['common']);
	const [state, dispatch] = useReducer(intakeReducer, intakeReducerInitialState);

	useEffect(() => {
		if (skipInitialQuestions) {
			dispatch({
				type: 'setInitialIntakeQuestionsDone',
				payload: true,
			});
		}
	}, [skipInitialQuestions]);

	useEffect(() => {
		if (!state.questions.length && !skipInitialQuestions) {
			dispatch({
				type: 'updateQuestions',
				payload: QUESTIONS(t),
			});
		}
	}, [state.questions, skipInitialQuestions]);

	return { state, dispatch };
}

export function isAnswered(question: Question) {
	return !!question.answer;
}
