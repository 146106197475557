import { createListenerMiddleware, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { setCookie } from 'cookies-next';

import { COOKIE_KEYS } from '@monorepo/shared/lib/utils';
import type { IUboConnection, IUboResponse, IUboStatus } from '@monorepo/types';

import { AppState, hydrate } from '@common/store';

export type UboState = {
	organization_name: string | null;
	status: IUboStatus | null;
	ubo_connections: IUboConnection[];
	activeStep: number;
	totalSteps: number;
};

// Initial state
const initialState: UboState = {
	organization_name: null,
	status: null,
	ubo_connections: [],
	activeStep: 1,
	totalSteps: 3,
};

// Actual Slice
export const uboSlice = createSlice({
	name: 'ubo',
	initialState,
	reducers: {
		setUbo(state, action: PayloadAction<IUboResponse>) {
			return { ...state, ...action.payload };
		},
		nextUboStep(state) {
			state.activeStep = state.activeStep + 1;
		},
		setUboConnections(state, action: PayloadAction<IUboConnection[]>) {
			state.ubo_connections = action.payload;
		},
		updateUboConnection(state, action: PayloadAction<{ index: number; ubo: IUboConnection }>) {
			state.ubo_connections = [...state.ubo_connections].map((ubo, index) =>
				index === action.payload.index ? { ...action.payload.ubo } : { ...ubo }
			);
		},
		previousUboStep(state) {
			state.activeStep = state.activeStep - 1;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(hydrate, (state, action: PayloadAction<{ ubo: UboState }>) => {
			return {
				...state,
				...action.payload.ubo,
			};
		});
		// TODO:
		// builder.addMatcher(uboApi.endpoints.getUbo.matchFulfilled, (state, action) => {
		// 	Object.assign(state, action.payload);
		// });
	},
});

export const { nextUboStep, previousUboStep, setUbo, updateUboConnection, setUboConnections } = uboSlice.actions;

export const selectUboState = (state: AppState) => state.ubo;
export const selectUboCompanyName = (state: AppState) => state.ubo.organization_name;
export const selectIUboStatus = (state: AppState) => state.ubo.status;
export const selectActiveUboStep = (state: AppState) => state.ubo.activeStep;
export const selectTotalUboSteps = (state: AppState) => state.ubo.totalSteps;

export const uboListenerMiddleware = createListenerMiddleware();
uboListenerMiddleware.startListening({
	matcher: isAnyOf(setUbo, nextUboStep, setUboConnections, updateUboConnection, previousUboStep),
	effect: (action, listenerApi) => {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);

		setCookie(COOKIE_KEYS.PERSIST_UBO, JSON.stringify((listenerApi.getState() as AppState).ubo), {
			expires: tomorrow,
		});
	},
});
