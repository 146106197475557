import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useId } from 'react';

import { Text } from '@monorepo/shared/typography';
import { CartDocument, ProductSpec } from '@monorepo/types';

import { getDocName } from './../../../lib/utils';
import { DownloadButton } from './../../DataEntry';

export function SpecList({ spec, docs }: { spec: ProductSpec; docs?: Array<CartDocument> }) {
	const { t } = useTranslation(['common']);
	const id = useId();

	if (spec.children.length > 0) {
		return (
			<>
				<Text as='p' variant='body-xs' weight='medium'>
					{spec.value}
				</Text>
				{spec.children.map((child, index) => (
					<SpecList key={`spec-list-${id}-child-${index}`} spec={child} />
				))}

				{docs && docs.length > 0 && (
					<div className='mt-4 flex flex-row flex-wrap gap-12'>
						{docs?.map(({ filename, name, path, type }, index) => {
							const displayName = name ? name : getDocName(type, t, filename);

							return (
								<Link
									target='_blank'
									className='pointer-events-auto max-sm:col-span-2 sm:w-fit'
									key={`${filename}-${index}`}
									title={displayName}
									href={`/${path}`}>
									<DownloadButton label={displayName} iconLeft='file-info-alt' className='w-full' />
								</Link>
							);
						})}
					</div>
				)}
			</>
		);
	}

	return (
		<Text as='p' variant='body-xs' color='grayscale400'>
			{spec.value}
		</Text>
	);
}
